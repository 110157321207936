import React, { useState, useEffect } from 'react'
import { connect, useDispatch } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import { Modal } from "react-bootstrap";
import { loadingSubscribtionAction } from '../../store/actions/SubscribtionActions';
import { getSubscribtion } from '../../services/SubscribtionService';
import * as utility from '../../utility/Utility';
import logoIcon from '../../images/logo-icon.png';

let subcription_data = [
    {
        id: 1,
        plan: 'Basic',
        title: 'Administration',
        price: 3,
        color: '#3D4853',
        plan_list: [
            { id: 1, title: 'Monthly', sub_title: 'Month', active: false, price: 4.99 },
            { id: 2, title: 'Quarterly', sub_title: 'Quarter', active: false, price: 15.99 },
            { id: 3, title: 'Annualy', sub_title: 'Annual', active: false, price: 69.99 }
        ],
        features_type: 'FREE',
        features_list: [
            'Add time for others',
            'Hide time & pages',
            'Required fields',
            'Bulk edit',
            'Decimal format',
            'Time audit',
            'Customize export',
            'Project templates',
            'Historic rates',
            'Import time',
            'PIN & Break'
        ]

    },
    {
        id: 2,
        plan: 'Professional',
        title: 'Productivity & Profit',
        price: 7,
        color: '#EB5757',
        plan_list: [
            { id: 1, title: 'Monthly', sub_title: 'Month', active: false, price: 4.99 },
            { id: 2, title: 'Quarterly', sub_title: 'Quarter', active: false, price: 15.99 },
            { id: 3, title: 'Annualy', sub_title: 'Annual', active: false, price: 69.99 }
        ],
        features_type: 'STANDARD',
        features_list: [
            'Scheduling',
            ' Expenses',
            'Budget & estimates',
            'Custom fields',
            'User fields',
            'Labor cost & profit',
            'Scheduled reports',
            'Alerts',
            'Force timer',
            ' GPS tracking',
            'Screenshots'
        ]
    },
    {
        id: 3,
        plan: 'Enterprise',
        title: 'Control & Security',
        price: 11,
        color: '#9B51E0',
        plan_list: [
            { id: 1, title: 'Monthly', sub_title: 'Month', active: false, price: 4.99 },
            { id: 2, title: 'Quarterly', sub_title: 'Quarter', active: false, price: 15.99 },
            { id: 3, title: 'Annualy', sub_title: 'Annual', active: false, price: 69.99 }
        ],
        features_type: 'PRO',
        features_list: [
            'Single sign-on (SSO)',
            'Custom subdomain',
            'Control accounts',
            'Audit log',
            'Request DEMO',
        ]
    }
];

function Subscribtions(props) {

    const history = useHistory();
    const dispatch = useDispatch();
    const { state } = useLocation();

    const [subscriptionPlan, setSubscriptionPlan] = useState([]);
    const [comparePlan, setComparePlan] = useState([]);
    const [subscriptionDetails, setSubscriptionDetails] = useState({});
    const [subscription, setSubscription] = useState(1);
    const [activePlan, setActivePlan] = useState(1);
    const [addModal, setAddModal] = useState(false);
    const [viewModal, setViewModal] = useState(false);
    const [compareModal, setCompareModal] = useState(false);

    useEffect(() => {
        let arry = [...subcription_data];
        arry.forEach((item, index) => {
            if (item.id == props.planId) {
                item.plan_list.forEach((plan, j) => {
                    if (plan.id === props.subscriptionId) {
                        arry[index].plan_list[j].active = true;
                    }
                });
            }
        });
        setSubscriptionPlan(arry);
    }, []);


    const onSubscribtionHandler = (id) => {
        setSubscription(id);
        dispatch(loadingSubscribtionAction(true));
        getSubscribtion(state._id, state.token, id, activePlan)
            .then((response) => {
                if (response.data.status == 200) {
                    history.push('/dashboard');
                } else {
                    utility.showError(response.data.msg);
                    dispatch(loadingSubscribtionAction(false));
                }
            })
            .catch((error) => {
                //console.log(error);
                dispatch(loadingSubscribtionAction(false));
            });
    }

    const onSubscribeHandler = (val, val2) => {

        setAddModal(true);
        setSubscription(val);
        setActivePlan(val2);

    }

    const onConfirmHandler = () => {
        let arry = [...subscriptionPlan];
        arry.forEach((item, index) => {
            if (item.id === subscription) {
                item.plan_list.forEach((plan, j) => {
                    if (plan.id === activePlan) {
                        arry[index].plan_list[j].active = true;
                    } else {
                        arry[index].plan_list[j].active = false;
                    }
                });
            } else {
                item.plan_list.forEach((plan, j) => {
                    arry[index].plan_list[j].active = false;
                });
            }
        });
        setSubscriptionPlan(arry);
        setAddModal(false);
    }

    const onViewDetailsHandler = (item) => {
        setViewModal(true);
        setSubscriptionDetails(item);
    }

    const chackboxFun = (element, item) => {
        let array = [...comparePlan];
        if (element.target.checked == true) {
            array.push(item);
        } else {
            const index = array.findIndex((val) => val.id === item.id);
            array.splice(index, 1);

        }
        setComparePlan(array);
    }

    const getActiveColor = (data) => {
        let status = false;
        for (let i = 0; data.length > i; i++) {
            if (data[i].active) {
                status = true;
                break
            }
        }
        return status

    }

    return (
        <div className="subscription-maincontainer">
            <div className="login-logo-header">
                <img src={logoIcon} alt="ClockMeNow" />
                <h2>ClockMeNow</h2>
            </div>
            <div className="cancel-subscription" onClick={() => history.goBack()}><i class="fa fa-times" aria-hidden="true"></i></div>
            <div className="container">

                <div className="subscription-container">

                    <div className='subscription-title-container'>
                        <h2>Subscription plans</h2>
                        <p>Review features and select a plan</p>
                    </div>
                    <div className='row subscription-item-container'>
                        {
                            subscriptionPlan.map((item, index) => {
                                return (
                                    <div key={index} className="col-sm-6 col-md-4">
                                        <div class="purchash-section" style={{ borderColor: getActiveColor(item.plan_list) ? '#ee7b22' : '#3D4853' }}>
                                            <div class="purchash-title-view">
                                                <h3>{item.plan}</h3>
                                                <h4>{item.title}</h4>
                                                {item.id == 3 ?
                                                    <h6 style={{ paddingTop: 30, paddingBottom: 55 }}>Contact for Price details</h6> :
                                                    <h6>$ <span>{item.price}</span>.99 USD</h6>
                                                }
                                                {item.id == 3 ? null :
                                                    <p>per user/month billed annually (or $4.99 if billed monthly)</p>}
                                                <h5><span className="color-free">✓</span> All {item.features_type} features</h5>

                                            </div>
                                            <div class="purchash-item-view">
                                                {
                                                    item.plan_list.map((data, i) => {
                                                        return (
                                                            <button
                                                                key={i}
                                                                className={data.active ? "active-btn" : null}
                                                                onClick={() => onSubscribeHandler(item.id, data.id)}>
                                                                <span>{data.title}</span>
                                                                <span>{item.id == 3 ? null :
                                                                    "$" + data.price}
                                                                    /{data.sub_title}</span>

                                                            </button>
                                                        );
                                                    })
                                                }

                                            </div>
                                         
                                                <div class="purchash-btn-view">
                                                    <button onClick={() => onViewDetailsHandler(item)} class="puch-dls-btn">Details</button>
                                                    {item.id == 3 ? null :
                                                    <button onClick={() => onSubscribtionHandler(item.id)} class="purchash-btn" disabled={props.subScriptionLoading}>
                                                        Next
                                                        {(props.subScriptionLoading) && (item.id == subscription) ?
                                                            <Spinner
                                                                as="span"
                                                                animation="border"
                                                                size="sm"
                                                                role="status"
                                                                aria-hidden="true"
                                                            />
                                                            : null}
                                                    </button>
                                            }
                                                </div>

                                        </div>
                                        <div className='plan-checkbox'>
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                id={`check${index}`}
                                                onClick={(e) => chackboxFun(e, item)}
                                            />
                                        </div>
                                    </div>
                                );
                            })
                        }

                    </div>
                    <div className='mt-5'>
                        <button disabled={comparePlan.length > 1 ? false : true} onClick={() => setCompareModal(true)} className="compare-plan-btn">Compare plans</button>
                    </div>

                </div>
            </div>
            <div class="login-footer">
                <p className="m-t">&copy; Copyright 2021 ClockMeNow. All Right Reserved. <a href="#">Privacy
                    Policy</a> & <a href="#">Terms and Conditions</a></p>
            </div>


            <Modal
                className="fade bd-example-modal-sm"
                size="sm"
                show={addModal}
            >
                <div className='add-subcription-plan'>
                    <h4>Are you sure ? <br />You want to accept this plan.</h4>
                    <div className='subcription-plan-btn-group'>
                        <button type="button" onClick={() => setAddModal(false)} className="btn">
                            Cancel
                        </button>
                        <button
                            type="button"
                            onClick={() => onConfirmHandler()}
                            className="btn confirm-btn">
                            Confirm
                        </button>
                    </div>

                </div>
            </Modal>

            <Modal
                className="fade bd-example-modal-sm"
                size="md"
                onHide={() => setViewModal(false)}
                show={viewModal}
            >
                <div className='subcription-plan-details'>
                    <div className='subcription-plan-details-title'>
                        <button onClick={() => setViewModal(false)}><i class="fa fa-times" aria-hidden="true"></i></button>
                        <h2>{subscriptionDetails ? subscriptionDetails.plan : null}</h2>
                        <h5>{subscriptionDetails ? subscriptionDetails.title : null}</h5>
                        {/* <h4>$ <span>3</span>.99 USD</h4> */}
                        <p>per user/month billed annually(or $4.99 if billed monthly)</p>
                        <h6><span style={{ color: subscriptionDetails ? subscriptionDetails.color : null }} class="color-free">✓</span>All {subscriptionDetails ? subscriptionDetails.features_type : null} features</h6>
                    </div>
                    <div className='subcription-plan-details-view'>
                        {subscriptionDetails.features_list ?
                            <ul>
                                {
                                    subscriptionDetails.features_list.map((item, index) => {
                                        return (
                                            <li><span style={{ color: subscriptionDetails ? subscriptionDetails.color : null }} class="color-free">✓</span> {item}</li>
                                        );
                                    })
                                }

                            </ul>
                            : null}
                    </div>

                </div>
            </Modal>

            <Modal
                className="fade bd-example-modal-sm"
                size="lg"
                onHide={() => setCompareModal(false)}
                show={compareModal}
            >
                <div className='p-4'>

                    <div className="compare-details">
                        <button onClick={() => setCompareModal(false)}><i class="fa fa-times" aria-hidden="true"></i></button>
                    </div>
                    <h3 className='text-center mt-3 mb-5'>Compare Plan</h3>
                    <div className='row'>
                        {
                            comparePlan.map((item, index) => {
                                return (
                                    <div className='col-md-4 p-0'>
                                        <div className='ps-2 pe-2'>
                                            <div className='subcription-plan-details-title'>
                                                <h4 className='text-center'>{item.plan}</h4>
                                                <p className='text-center'>{item.title}</p>
                                                {/* <p>per user/month billed annually(or ${item.price}.99 if billed monthly)</p> */}
                                                <h6 className='text-center'><span class="color-free">✓</span>All {item.features_type} features</h6>
                                            </div>
                                            <div className='subcription-plan-details-view'>
                                                {item.features_list ?
                                                    <ul>
                                                        {
                                                            item.features_list.map((feature, index) => {
                                                                return (
                                                                    <li><span class="color-free">✓</span>{feature}</li>
                                                                );
                                                            })
                                                        }

                                                    </ul>
                                                    : null}
                                            </div>

                                        </div>
                                    </div>
                                );
                            })
                        }
                    </div>
                    <div className='text-center mt-2'>
                        {/* <button onClick={() => setCompareModal(false)} class="puch-dls-btn">Cancel</button> */}
                    </div>
                </div>

            </Modal>

        </div>

    )
}

const mapStateToProps = (state) => {
    return {
        showLoading: state.auth.showLoading,
        subScriptionLoading: state.subscribtion.showLoading,
        subscriptionId: state.subscribtion.subscriptionId,
        planId: state.subscribtion.planId,
    };
};
export default connect(mapStateToProps)(Subscribtions);