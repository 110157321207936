import React, { useContext } from "react";

/// React router dom
import { BrowserRouter, Switch, Route } from "react-router-dom";

/// Css
import "./index.css";
import "./chart.css";
import "./step.css";

/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";
import ScrollToTop from "./layouts/ScrollToTop";

/// Dashboard
import Home from "./components/Dashboard/Home";

/// Account
import ManagePassword from "./components/Account/ManagePassword";

//Profile
import UserProfile from "./components/Profile/UserProfile";
import CompanyProfile from "./components/Profile/CompanyProfile";

/// Time capture
import TimeCapture from "./components/TimeCapture/TimeCapture";
import TaskList from "./components/TimeCapture/TaskList";
import RealTimeCapture from "./components/Reporting/RealTimeCapture";
import UserTimeCart from "./components/Reporting/UserTimeCart";
import SupervisorTimeCard from "./components/Reporting/SupervisorTimeCard";
import ManualEntry from "./components/Reporting/ManualEntry";
import TaskDetails from "./components/TimeCapture/TaskDetails";
import ApproverTaskList from "./components/TimeCapture/ApproverTaskList";
import ApproverTimeCard from "./components/TimeCapture/ApproverTimeCard";

//Settings
import ManageUsers from "./components/Settings/ManageUsers";
import Settings from "./components/Settings/Settings";
import RoleManagement from "./components/Settings/RoleManagement";
import AddRole from "./components/Settings/AddRole";
import UpdateRole from "./components/Settings/UpdateRole";
import CopyRole from "./components/Settings/CopyRole";
import Classifications from "./components/Settings/Classifications";
import ProjectActivities from "./components/Settings/ProjectActivities";
import ProjectTask from "./components/Settings/ProjectTask";
import Activities from "./components/Settings/Activities";
import Task from "./components/Settings/Task";
import Clients from "./components/Settings/Clients";
import Group from "./components/Settings/Group";
import Location from "./components/Settings/Location";
import SystemDefault from "./components/Settings/SystemDefault";

//Subscribtion
import Subscribtions from "./pages/Subscribtions";

/// Reporting
import TimeCart from "./components/Reporting/TimeCart";
import CartDetails from "./components/Reporting/CartDetails";
import OwnCart from "./components/Reporting/OwnCart";
import ManualTimeEntry from "./components/Reporting/ManualTimeEntry";
import ReviewerTimeCard from "./components/TimeCapture/ReviewerTimeCard";

//Redux
import { ThemeContext } from "../context/ThemeContext";

const Markup = () => {
  const { menuToggle } = useContext(ThemeContext);
  const routes = [

    /// Dashboard
    { url: "", component: Home },
    { url: "dashboard", component: Home },
    { url: "manage-password", component: ManagePassword },

    /// Profile
    { url: "user-profile", component: UserProfile },
    { url: "company-profile", component: CompanyProfile },

    /// Time Capture
    { url: "time-capture", component: TimeCapture },
    { url: "task-list", component: TaskList },
    { url: "real-time-capture", component: RealTimeCapture },
    { url: "manual-entry", component: ManualEntry },
    { url: "user-time-cart", component: UserTimeCart },
    { url: "supervisor-time-card", component: SupervisorTimeCard },
    { url: "task-details", component: TaskDetails },
    { url: "approver-task", component: ApproverTaskList },
    { url: "approver-time-card", component: ApproverTimeCard },
    { url: "reviewer-time-card", component: ReviewerTimeCard },

    /// system setting
    { url: "manage-users", component: ManageUsers },
    { url: "settings", component: Settings },
    { url: "role-management", component: RoleManagement },
    { url: "add-role", component: AddRole },
    { url: "update-role", component: UpdateRole },
    { url: "copy-role", component: CopyRole },
    { url: "clients", component: Clients },
    { url: "group", component: Group },
    { url: "classifications", component: Classifications },
    { url: "project-activities", component: ProjectActivities },
    { url: "project-task", component: ProjectTask },
    { url: "activities", component: Activities },
    { url: "task", component: Task },
    { url: "location", component: Location },
    { url: "system-default", component: SystemDefault },

    /// Subscribtions
    { url: "page-subscribtions", component: Subscribtions },

    /// Reporing
    { url: "time-cart", component: TimeCart },
    { url: "cart-details", component: CartDetails },
    { url: "own-cart", component: OwnCart },
    { url: "manual-time-entry", component: ManualTimeEntry },


  ];

  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];

  let pagePath = path.split("-").includes("page");
  return (
    <>
      <div
        id={`${!pagePath ? "main-wrapper" : ""}`}
        className={`${!pagePath ? "show" : "mh100vh"}  ${menuToggle ? "menu-toggle" : ""
          }`}
      >
        {!pagePath && <Nav />}

        <div className={`${!pagePath ? "content-body" : ""}`}
          style={{ minHeight: window.screen.height - 45 }}
        >
          <div
            className={`${!pagePath ? "container-fluid" : ""}`}
          >
            <Switch>
              {routes.map((data, i) => (
                <Route
                  key={i}
                  exact
                  path={`/${data.url}`}
                  component={data.component}
                />
              ))}
            </Switch>
          </div>
        </div>
        {!pagePath && <Footer />}
      </div>
      <ScrollToTop />
    </>
  );
};

export default Markup;
