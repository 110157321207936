import React, { useContext, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { Modal } from "react-bootstrap";
import swal from "sweetalert";
import { loadingToggleAction, getProfileDetails, expiredSubscription } from '../../../store/actions/AuthActions';
import { checkSubscribtionDetails, cancelSubscribtionDetails } from '../../../store/actions/SubscribtionActions';
import { getRolePermissionsData } from '../../../store/actions/RoleActions';
import { globalLoadingAction } from '../../../store/actions/GlobalAction';


const Home = (props) => {

	const history = useHistory();
	const dispatch = useDispatch();
	const [addModal, setAddModal] = useState(false);
	const [soonModal, setSoonModal] = useState(false);
	const [isChange, setIsChange] = useState(false);

	useEffect(() => {
		dispatch(globalLoadingAction(true));
		dispatch(getRolePermissionsData(history));
		dispatch(getProfileDetails(props.history));
		dispatch(checkSubscribtionDetails(props.history));
	}, []);

	useEffect(() => {
		setAddModal(props.subscriptionStaus);
	}, [props.subscriptionStaus]);

	const onConfirmHandler = () => {
		let getData = localStorage.getItem('userDetails');
		let obj = {
			"_id": JSON.parse(getData)._id,
			"token": JSON.parse(getData).token,
			"company_id": JSON.parse(getData).company_id,
			"country": JSON.parse(getData).country,
			"country_code": JSON.parse(getData).country_code,
			"email": JSON.parse(getData).email,
			"status": JSON.parse(getData).status,
			"user_type": JSON.parse(getData).user_type,
			"system_admin": JSON.parse(getData).system_admin,
			"expired": 0,
		}
		history.push({
			pathname: '/page-subscribtions',
			state: obj
		});
		//dispatch(expiredSubscription(obj, props.history));
	}

	const onLinkStatus = (item) => {
		let status = null;
		let arry = [...props.quickLinks];
		for (let x = 0; arry.length > x; x++) {
			if (arry[x] == item.menu_id) {
				status = true;
				break;
			} else {
				status = false;
			}
		}
		return status;
	}

	const onCancelSubscriptionHandler = () => {
		swal({
			title: "Are you sure?",
			text:
				"You want to Cancel this Plan!",
			icon: "warning",
			buttons: true,
			dangerMode: true,
		}).then((willDelete) => {
			if (willDelete) {
				dispatch(globalLoadingAction(true));
				dispatch(cancelSubscribtionDetails(props.history));
			}
		})
	}

	return (
		<>
			<div className="row">
				<div className="breadcrumb-container">
					<ul>
						<li>
							<Link to={"#"}>My Dashboard</Link>
						</li>
						{/* <li><i className="fa fa-angle-right"></i></li> */}
					</ul>
				</div>
				<div className="title-body-container">
					<div className="title-body">
						<h3>Quick Links</h3>
					</div>
				</div>
				<div className="user-content-view">
					<div className="row">
						<div className="col-xl-11 col-md-12">
							<div className="row">

								{parseInt(props.userType) == 1 ?
									<>
										<div className="col-xl-3 col-md-6">
											<Link to="/time-capture" className="user-card">
												<div className="add-user-card">
													<i class="fa fa-clock" aria-hidden="true"></i>
												</div>
												<div className="add-user-content">
													<h2>Record time</h2>
												</div>
											</Link>
										</div>
										<div className="col-xl-3 col-md-6">
											<Link to="#" onClick={() => setSoonModal(true)} className="user-card">
												<div className="add-user-card">
													<i class="fa fa-clock" aria-hidden="true"></i>
												</div>
												<div className="add-user-content">
													<h2>Report time</h2>
												</div>
											</Link>
										</div>
										<div className="col-xl-3 col-md-6">
											<Link to="/manage-users" className="user-card">
												<div className="add-user-card">
													<i class="fa fa-user-plus" aria-hidden="true"></i>
												</div>
												<div className="add-user-content">
													<h2>Setup user</h2>
												</div>
											</Link>
										</div>
										<div className="col-xl-3 col-md-6">
											<Link to="/clients" className="user-card">
												<div className="add-user-card">
													<i class="fa fa-cogs" aria-hidden="true"></i>
												</div>
												<div className="add-user-content">
													<h2>Setup clients</h2>
												</div>
											</Link>
										</div>
									</>
									:
									null}
								{parseInt(props.userType) == 2 ?
									null :

									props.permissionsList.map((item, index) => {
										if (onLinkStatus(item) && item.link != "" && item.menu_id != 3) {
											return (
												<div key={index} className="col-xl-3 col-md-6">
													<Link to={"/" + item.link} className="user-card">
														<div className="add-user-card">
															<i class={`bi bi-${item.icon}`}></i>
														</div>
														<div className="add-user-content">
															<h2>{item.title}</h2>
														</div>
													</Link>
												</div>
											);
										}
									})
								}

								{(() => {
									switch (parseInt(props.empType)) {
										case 1:
											return (<div className="col-xl-3 col-md-6">
												<Link to="/real-time-capture" className="user-card">
													<div className="add-user-card">
														<i class="fa fa-clock" aria-hidden="true"></i>
													</div>
													<div className="add-user-content">
														<h2>Record time</h2>
													</div>
												</Link>
											</div>);
										case 2:
											return (<div className="col-xl-3 col-md-6">
												<Link to="/task-details" className="user-card">
													<div className="add-user-card">
														<i class="fa fa-clock" aria-hidden="true"></i>
													</div>
													<div className="add-user-content">
														<h2>Time Tracking</h2>
													</div>
												</Link>
											</div>);
										case 3:
											return (<div className="col-xl-3 col-md-6">
												<Link to="/approver-task" className="user-card">
													<div className="add-user-card">
														<i class="fa fa-clock" aria-hidden="true"></i>
													</div>
													<div className="add-user-content">
														<h2>Time Tracking</h2>
													</div>
												</Link>
											</div>);
										default:
											return null
									}
								})()}

								{parseInt(props.empType) !== 1 ?
									<div className="col-xl-3 col-md-6">
										<Link to="/classifications" className="user-card">
											<div className="add-user-card">
												<i class="fa fa-tasks" aria-hidden="true"></i>
											</div>
											<div className="add-user-content">
												<h2>Classification</h2>
											</div>
										</Link>
									</div>
									: null}

								<div className="col-xl-3 col-md-6">
									<div className="user-card">
										<div className="add-user-card">
											<i class="fa fa-link" aria-hidden="true"></i>
										</div>
										<div className="add-user-content">
											<h2>More</h2>
										</div>
									</div>
								</div>

							</div>
						</div>
						<div className="col-xl-1 col-md-12">
						</div>
					</div>
				</div>

				<div className="subscription-content-view">
					<div className="row">
						<div className="col-xl-11 col-md-12">
							<div className="row">
								<div className="col-xl-4 col-md-6">
									<div className="organization-info-card">
										<div className="organization-info-title-view">
											<div>
												<h3>organization info</h3>
											</div>
											<Link to="/company-profile">
												<i class="fa fa-pencil" aria-hidden="true"></i>
											</Link>
										</div>

										<div className="organization-info-form-view">
											<div className="form-group organization-info-form-group">
												<label>Company/Organization</label>
												<input
													type="text"
													className="form-control"
													placeholder="company"
													value={props.companyDetails.company_name}
												/>
											</div>
											{/* <div className="form-group organization-info-form-group">
												<label>Entity type</label>
												<input
													type="text"
													className="form-control"
													placeholder="type"
													value={props.companyDetails.industry}
												/>
											</div>
											<div className="form-group organization-info-form-group">
												<label>Main Location</label>
												<textarea className="form-control" placeholder="location" value={props.companyDetails.address1}>
												</textarea>
											</div> */}
										</div>
									</div>
								</div>
								{parseInt(props.userType) == 1 ?
									<div className="col-xl-8 col-md-6">
										<div className="subscribtion-card">
											<div className="subscribtion-card-title-view">
												<h3>Subscription</h3>
											</div>
											<div className="subscribtion-content">
												<div className="subscribtion-plane-view">
													<div className="subscribtion-plane-left">
														{/* <button className="subscribtion-plane-btn">Active</button> */}

														<button className="subscribtion-plane-btn">
															{(() => {
																switch (props.planId) {
																	case 1:
																		return "Basic"
																	case 2:
																		return "Professional"
																	case 3:
																		return "Enterprise"
																	default:
																		return null
																}
															})()}

															{(() => {
																switch (props.subscriptionId) {
																	case 1:
																		return " (Monthly)"
																	case 2:
																		return " (Quarterly)"
																	case 3:
																		return " (Annualy)"
																	case 4:
																		return " (Free Trail)"
																	default:
																		return null
																}
															})()}
														</button>
														{/* <h3>Clock Me Now Premier Plane</h3> */}
														<h3>{props.subscribtionStatus ? "Remaining subscription days" : "Free subsciption expired"}</h3>
														{/* <p>Subscribed since Jun 15,2022</p> */}
													</div>
													<div className="subscribtion-plane-right">
														{/* <h3>$10.00/Mth</h3> */}
														{props.subscribtionStatus ? <h3>{props.subscribtionStatus} Days</h3> : <button className="subscribtion-expired-btn">Subscribe Now</button>}
													</div>
												</div>
												<div className="subscribtion-plane-dis">
													<p>Manage subscription plans, payment and billing history.</p>
												</div>
												<div className="subscribtion-plane-btn-content">
													{/* {!isChange ?
														<button onClick={() => setIsChange(!isChange)}>Change Plan</button>
														: null} */}
													{isChange ?
														<>
															{(() => {
																switch (props.planId) {
																	case 1:
																		return <button onClick={() => onConfirmHandler()}>Upgrade Plan</button>
																	case 2:
																		return <><button onClick={() => onConfirmHandler()}>Upgrade Plan</button>
																			<button onClick={() => onConfirmHandler()}>Downgrade Plan</button></>
																	case 3:
																		return <button onClick={() => onConfirmHandler()}>Downgrade Plan</button>
																	default:
																		return null
																}
															})()}

														</> : <button onClick={() => setIsChange(!isChange)}>Change Plan</button>}
													<button>Cancel Plan</button>
													<button>Billing</button>
													{/* <button onClick={() => onCancelSubscriptionHandler()}>Cancel Plan</button> */}
													<button>Payment History</button>
													<button>Payment Method</button>

												</div>
											</div>
										</div>
									</div>
									: null}
							</div>
						</div>
						<div className="col-xl-1 col-md-12">
						</div>
					</div>
				</div>

				<div className="account-setting-content mt-4">
					<div className="row">
						<div className="col-xl-11 col-md-12">
							<div className="row">
								<div className="col-xl-4 col-md-6">
									<div className="account-setting-card">
										<h4>Application Settings</h4>
										<div className="account-setting-option">
											<div className="account-setting-item">
												<div><p>Time Reporting</p></div>
												<div className="switch-btn-view">
													<label class="switch">
														<input type="checkbox" checked />
														<span class="slider round"></span>
													</label>
												</div>
											</div>
											<div className="account-setting-item">
												<div><p>Biling</p></div>
												<div className="switch-btn-view">
													<label class="switch">
														<input type="checkbox" />
														<span class="slider round"></span>
													</label>
												</div>
											</div>
											<div className="account-setting-item">
												<div><p>Expenses</p></div>
												<div className="switch-btn-view">
													<label class="switch">
														<input type="checkbox" checked />
														<span class="slider round"></span>
													</label>
												</div>
											</div>
											<div className="account-setting-item">
												<div><p>Performance</p></div>
												<div className="switch-btn-view">
													<label class="switch">
														<input type="checkbox" />
														<span class="slider round"></span>
													</label>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="col-xl-4 col-md-6">
									<div className="account-setting-card">
										<h4>Features</h4>
										<div className="account-setting-option">
											<div className="account-setting-item">
												<div><p>Work Shifts</p></div>
												<div className="switch-btn-view">
													<label class="switch">
														<input type="checkbox" checked />
														<span class="slider round"></span>
													</label>
												</div>
											</div>
											<div className="account-setting-item">
												<div><p>Groups & Trees</p></div>
												<div className="switch-btn-view">
													<label class="switch">
														<input type="checkbox" />
														<span class="slider round"></span>
													</label>
												</div>
											</div>
											<div className="account-setting-item">
												<div><p>Classification</p></div>
												<div className="switch-btn-view">
													<label class="switch">
														<input type="checkbox" checked />
														<span class="slider round"></span>
													</label>
												</div>
											</div>
											<div className="account-setting-item">
												<div><p>Location Logging</p></div>
												<div className="switch-btn-view">
													<label class="switch">
														<input type="checkbox" />
														<span class="slider round"></span>
													</label>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="col-xl-4 col-md-6">
									<div className="account-setting-card">
										<h4>Security</h4>
										<div className="account-setting-option">
											<div className="account-setting-item">
												<div><p>Security Roles</p></div>
												<div></div>
											</div>
											<div className="account-setting-item">
												<div><p>Manage User</p></div>
												<div></div>
											</div>
											<div className="account-setting-item">
												<div><p>Import & Intergration</p></div>
												<div></div>
											</div>
											<div className="account-setting-item">
												<div><p>Security Reports</p></div>
												<div></div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-xl-1 col-md-12">
						</div>
					</div>
				</div>

				<Modal
					className="fade bd-example-modal-sm"
					size="md"
					show={addModal}
				>
					<div className='expired-subcription-plan'>
						<h2>Select a plan to continue.</h2>
						<p>Please take a plan to continue using Clockmenow</p>
						{parseInt(props.userType) == 1 ?
							<button type="button"
								onClick={() => onConfirmHandler()}
								className="subscription-plan-btn">
								Go to Subscription Plan
							</button>
							: null}
					</div>
				</Modal>
				<Modal
					className="fade bd-example-modal-sm"
					size="md"
					show={soonModal}
				>
					<div className='expired-subcription-plan'>
						<h2>Coming Soon</h2>
						<p>This Page is Coming Soon</p>
						<button type="button"
							onClick={() => setSoonModal(false)}
							className="subscription-plan-btn">
							Ok
						</button>
					</div>
				</Modal>
			</div>
		</>
	)
}
const mapStateToProps = (state) => {
	return {
		showLoading: state.auth.showLoading,
		companyDetails: state.auth.company_details,
		subscribtionStatus: state.subscribtion.remainingDay,
		subscriptionId: state.subscribtion.subscriptionId,
		planId: state.subscribtion.planId,
		subscriptionStaus: state.subscribtion.subscriptionStaus,
		userType: state.auth.userType,
		empType: state.auth.empType,
		permissionsList: state.role.permissionsList,
		quickLinks: state.auth.quickLinks,
	};
};
export default connect(mapStateToProps)(Home);