import React, { useContext, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import Select from "react-select";
import { getCountryList, getStateList, getCityList, countryLoadingAction } from '../../../store/actions/CountryAction';
import { updateCompanyDetails } from '../../../store/actions/AuthActions';
import { globalLoadingAction } from '../../../store/actions/GlobalAction';


const CompanyProfile = (props) => {

    const dispatch = useDispatch();
    const history = useHistory();

    let errorsObj = { cName: '', add1: '', country: '', state: '', phone: '', pincode: '', industry: '' };
    const [errors, setErrors] = useState(errorsObj);

    const [companyId, setCompanyId] = useState(props.companyDetails._id);
    const [companyName, setCompanyName] = useState(props.companyDetails.company_name);
    const [address1, setAddress1] = useState(props.companyDetails.address1);
    const [address2, setAddress2] = useState(props.companyDetails.address2);
    const [country, setCountry] = useState(props.companyDetails.country);
    const [countryCode, setCountryCode] = useState(props.companyDetails.country_code);
    const [state, setState] = useState(props.companyDetails.state);
    const [city, setCity] = useState(props.companyDetails.city);
    const [phone, setPhone] = useState(props.companyDetails.company_phone);
    const [pincode, setPincode] = useState(props.companyDetails.pincode);
    const [industry, setIndustry] = useState(props.companyDetails.industry);
    const [url, setUrl] = useState(props.companyDetails.website_url);


    useEffect(() => {
        //console.log('props.companyDetails._id',props.companyDetails);
        dispatch(getCountryList(props.history));
        onStateHandler();
    }, []);

    function onSubmit(e) {

        e.preventDefault();

        let error = false;
        const errorObj = { ...errorsObj };

        if (companyName === '') {
            errorObj.cName = 'Company name is Required';
            error = true;
        }
        if (address1 === '') {
            errorObj.add1 = 'Address1 is Required';
            error = true;
        }
        if (country === '') {
            errorObj.country = 'Country is Required';
            error = true;
        }
        if (state === '') {
            errorObj.state = 'State is Required';
            error = true;
        }
        // if (phone === '') {
        //     errorObj.phone = 'phone is Required';
        //     error = true;
        // }
        if (industry === '') {
            errorObj.industry = 'Industry is Required';
            error = true;
        }
        setErrors(errorObj);
        if (error) {
            return;
        }

        dispatch(globalLoadingAction(true));
        dispatch(updateCompanyDetails(companyId, companyName, address1, address2, country, countryCode, state, city, phone, pincode, industry, url, props.history));
    }

    const onStateHandler = () => {

        // props.companyDetails.forEach((item, index) => {
        //    console.log('hello',item);
        //   });

    }

    const updatedCountries = props.countryList.map((country) => ({
        label: country.name,
        value: country.name,
        ...country
    }));

    const updatedStates = props.stateList.map((state) => ({
        label: state.name,
        value: state.name,
        ...state
    }));

    const updatedCitys = props.cityList.map((state) => ({
        label: state.name,
        value: state.name,
        ...state
    }));

    const onChangecountry = (option) => {
        setCountry(option.name);
        setCountryCode(option.phone_code)
        dispatch(getStateList(parseInt(option.id), props.history));
    }
    const onChangeState = (option) => {
        setState(option.name);
        dispatch(getCityList(parseInt(option.id), props.history));
    }
    const onChangeCity = (option) => {
        setCity(option.name)
    }

    const onGoBackHandler = () => {
        history.goBack();
    }

    return (
        <>
            <div className="row">
                <div className="title-body mb-3">
                    <h3>Company Details</h3>
                    <p>Manage Company/Organization Information such as name, type, location address and contacts.</p>
                </div>

                <div className="col-xl-6 col-xxl-12">
                    <div className="row">

                        <div className="col-xl-12">
                            <div className="card pt-5 pb-5">
                                <div className="row">
                                    <div className="col-xl-3"></div>
                                    <div className="col-xl-6">

                                        <div className="card-body">
                                            <div className="basic-form">

                                                <form onSubmit={onSubmit}>
                                                    <div className="row">
                                                        <div className="form-group form-group-modal-custom mb-3 col-12">
                                                            <label className="mb-1">
                                                                Company Name
                                                            </label>
                                                            <input type="text"
                                                                className="form-control"
                                                                placeholder="Enter the company name"
                                                                value={companyName}
                                                                readOnly={parseInt(props.userType) == 1 ? false : true}
                                                                onChange={(e) => setCompanyName(e.target.value)} />
                                                            {errors.cName && <div className="text-danger fs-12">{errors.cName}</div>}
                                                        </div>
                                                        <div className="form-group form-group-modal-custom mb-3 col-12">
                                                            <label className="mb-1">
                                                                Address 1
                                                            </label>
                                                            <input type="text"
                                                                className="form-control"
                                                                placeholder="Enter the address 1"
                                                                value={address1}
                                                                readOnly={parseInt(props.userType) == 1 ? false : true}
                                                                onChange={(e) => setAddress1(e.target.value)} />
                                                            {errors.add1 && <div className="text-danger fs-12">{errors.add1}</div>}
                                                        </div>
                                                        <div className="form-group form-group-modal-custom mb-3 col-12">
                                                            <label className="mb-1">
                                                                Address 2
                                                            </label>
                                                            <input type="text"
                                                                className="form-control"
                                                                placeholder="Enter the address 2"
                                                                value={address2}
                                                                readOnly={parseInt(props.userType) == 1 ? false : true}
                                                                onChange={(e) => setAddress2(e.target.value)} />
                                                        </div>

                                                        <div className="form-group mb-3 col-6">
                                                            <label className="mb-1">
                                                                Country
                                                            </label>
                                                            <Select
                                                                id="editCountry"
                                                                name="editCountry"
                                                                label="country"
                                                                classNames="select-custom-form"
                                                                options={updatedCountries}
                                                                value={updatedCountries.filter(obj => obj.name === country)}
                                                                placeholder="Country"
                                                                isDisabled={parseInt(props.userType) == 1 ? false : true}
                                                                onChange={onChangecountry}
                                                            />
                                                            {errors.country && <div className="text-danger fs-12">{errors.country}</div>}
                                                        </div>


                                                        <div className="form-group mb-3 col-6">
                                                            <label className="mb-1">
                                                                State
                                                            </label>
                                                            <Select
                                                                id="editState"
                                                                name="editState"
                                                                label="state"
                                                                classNames="select-custom-form"
                                                                placeholder="State*"
                                                                isDisabled={parseInt(props.userType) == 1 ? false : true}
                                                                options={updatedStates}
                                                                value={updatedStates.filter(obj => obj.name === state)}
                                                                onChange={onChangeState}
                                                            />
                                                            {errors.state && <div className="text-danger fs-12">{errors.state}</div>}
                                                        </div>

                                                        <div className="form-group mb-3 col-6">
                                                            <label className="mb-1">
                                                                City
                                                            </label>
                                                            <Select
                                                                id="editCity"
                                                                name="editCity"
                                                                label="city"
                                                                placeholder="City"
                                                                isDisabled={parseInt(props.userType) == 1 ? false : true}
                                                                options={updatedCitys}
                                                                value={updatedCitys.filter(obj => obj.name === city)}
                                                                onChange={onChangeCity}
                                                            />
                                                        </div>

                                                        <div className="form-group form-group-modal-custom mb-3 col-6">
                                                            <label className="mb-1">
                                                                Postal Code
                                                            </label>
                                                            <input type="text"
                                                                className="form-control"
                                                                placeholder="Enter the postal code"
                                                                value={pincode}
                                                                readOnly={parseInt(props.userType) == 1 ? false : true}
                                                                onChange={(e) => setPincode(e.target.value)} />
                                                        </div>

                                                        {/* <div className="form-group col-12 mb-3">
                                                            <label className="mb-1">
                                                                Phone
                                                            </label>
                                                            <div className="phone-number-form-group">
                                                                <input type="text" class="country-code-control" disabled value={countryCode ? "+" + countryCode : ""} />
                                                                <input type="text" class="phone-control" placeholder="Company Phone No.*" value={phone} onChange={(e) => setPhone(e.target.value)}
                                                                />
                                                                <div className="tooltip-info"><i class="fa fa-info"></i>
                                                                    <span className="tooltip-text">Tooltip text</span>
                                                                </div>
                                                            </div>
                                                        </div> */}

                                                        <div className="form-group form-group-modal-custom col-12 mb-3">
                                                            <label className="mb-1">
                                                                Industry
                                                            </label>
                                                            <div className="select-drop-down">
                                                                <span><i class="fa fa-angle-down"></i></span>
                                                                <select
                                                                    defaultValue={"option"}
                                                                    class="form-control select-control"
                                                                    disabled={parseInt(props.userType) == 1 ? false : true}
                                                                    value={industry}
                                                                    onChange={(e) => setIndustry(e.target.value)}>
                                                                    <option value="">Select industry</option>
                                                                    <option value="Transport">Transport</option>
                                                                    <option value="Aerospace">Aerospace</option>
                                                                    <option value="Agriculture">Agriculture</option>
                                                                    <option value="Education">Education</option>
                                                                    <option value="Telecommunication">Telecommunication</option>
                                                                    <option value="IT">IT</option>
                                                                    <option value="Construction">Construction</option>
                                                                    <option value="Electronics">Electronics</option>
                                                                </select>
                                                            </div>
                                                            {errors.industry && <div className="text-danger fs-12">{errors.industry}</div>}
                                                        </div>

                                                        <div className="form-group form-group-modal-custom mb-3 col-12">
                                                            <label className="mb-1">
                                                                Company Website URL
                                                            </label>
                                                            <input type="text"
                                                                className="form-control"
                                                                placeholder="Enter the URL"
                                                                value={url}
                                                                readOnly={parseInt(props.userType) == 1 ? false : true}
                                                                onChange={(e) => setUrl(e.target.value)} />
                                                        </div>
                                                        {parseInt(props.userType) == 1 ?
                                                            <div className="modal-button-group mt-5">
                                                                <button onClick={onGoBackHandler} type="button" className="btn form-cencel-btn">
                                                                    Cancel
                                                                </button>
                                                                <button type="submit" className="btn btn-primary role-form-btn" disabled={props.showLoading}>
                                                                    Save
                                                                </button>
                                                            </div>
                                                            : null}
                                                    </div>
                                                </form>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="col-xl-3"></div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>


            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        showLoading: state.role.showLoading,
        countryList: state.country.countryList,
        stateList: state.country.stateList,
        cityList: state.country.cityList,
        companyDetails: state.auth.company_details,
        userType: state.auth.userType,
    };
};
export default connect(mapStateToProps)(CompanyProfile);