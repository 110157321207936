import swal from "sweetalert";
import {
    addProject,
    getProject,
    getProjectDetails,
    getProjectByClientId,
    checkActivity,
    updateProject,
    updateProjectStatus,
    removeProject,
    addActivities,
    getProjectActivitiesList,
    updateActivities,
    updateProjectActiviesStatus,
    removeProjectActivities,
    addTask,
    updateTask,
    taskList,
    getAccessableTask,
    updateTaskStatus,
    removeTask,
    downloadProjectRecord,
    downloadProjectActivitiesRecord,
    downloadProjectTaskRecord,
    removeMultipleProject,
    removeMultipleActivies,
    removeMultipleTaska,
    checkUsedTask,
    getTaskDetailsById,
    getProjectDetailsById
} from '../../services/ProjectService';

import {
    LOADING_PROJECT,
    ADD_PROJECT,
    GET_PROJECT,
    GET_PROJECT_DETAILS,
    UPDATE_PROJECT_STATUS,
    REMOVE_PROJECT_DETAILS,
    GET_PROJECT_ACTIVITIES,
    GET_TASK_LIST,
    UPDATE_PROJECT_ACTIVITIES_STATUS,
    REMOVE_PROJECT_ACTIVITIES_DETAILS,
    UPDATE_TASK_STATUS,
    REMOVE_TASK_LIST,
    GET_TASK_DETAILS_BY_ID,
    GET_PROJECT_ACTIVITIES_CHECK_EXISTANCE,
    GET_PROJECT_DETAILS_BY_ID
} from '../constants';

import * as utility from '../../utility/Utility';
import { globalLoadingAction } from './GlobalAction';


export function addProjectData(title, description, client, group, startDate, endDate, projectType, rate,billable, perPage, system_admin, history) {
    return (dispatch) => {
        addProject(title, description, client, group, startDate, endDate, projectType, rate,billable)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(getProjectList('', '', '', 1, perPage, system_admin, history));
                    utility.showSuccess(response.data.msg);
                } else {
                    utility.showError(response.data.msg);
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function updateProjectData(id, title, description, client, group, startDate, endDate, status, projectType, rate,billable, searchString, orderByField, orderByFieldValue, page_no, per_page, system_admin, history) {
    return (dispatch) => {
        updateProject(id, title, description, client, group, startDate, endDate, status, projectType, rate,billable)
            .then((response) => {
                // console.log('response....', response.data);
                if (response.data.status == 200) {
                    dispatch(getProjectList(searchString, orderByField, orderByFieldValue, page_no, per_page, system_admin, history));
                    utility.showSuccess(response.data.msg);
                } else {
                    utility.showError(response.data.msg);
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function getProjectList(searchString, orderByField, orderByFieldValue, page_no, per_page, system_admin, history) {
    return (dispatch) => {
        getProject(searchString, orderByField, orderByFieldValue, page_no, per_page, system_admin)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(getProjectAction(response.data));
                } else {
                    utility.showError(response.data.msg);
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                //utility.showError(response.data.msg);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function getProjectByClientIdList(id, history) {
    return (dispatch) => {
        getProjectByClientId(id)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(getProjectAction(response.data));
                } else {
                    utility.showError(response.data.msg);
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                //utility.showError(response.data.msg);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function getMultipleProjectByClientIdList(id, index, callBackProject, history) {
    return (dispatch) => {
        getProjectByClientId(id)
            .then((response) => {
                if (response.data.status == 200) {
                    callBackProject(response.data.data, index);
                } else {
                    utility.showError(response.data.msg);
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                //utility.showError(response.data.msg);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function getProjectDetailsData(id, history) {
    return (dispatch) => {
        getProjectDetails(id)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(getProjectDetailsAction(response.data.data));
                } else {
                    utility.showError(response.data.msg);
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function updateProjectStatusData(item, history) {
    return (dispatch) => {
        updateProjectStatus(item._id, item.status === 1 ? 0 : 1)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(updateProjectStatusAction(item));
                    utility.showSuccess(response.data.msg);
                } else {
                    utility.showError(response.data.msg);
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                //utility.showError(response.data.msg);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function removeProjectData(id, searchString, orderByField, orderByFieldValue, page_no, per_page, system_admin, history) {
    return (dispatch) => {
        removeProject(id)
            .then((response) => {
                if (response.data.status == 200) {
                    //dispatch(removeProjectAction(id));
                    swal(response.data.msg, { icon: "success", });
                    dispatch(getProjectList(searchString, orderByField, orderByFieldValue, page_no, per_page, system_admin, history));
                } else {
                    swal(response.data.msg, "error");
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function addActivitiesData(projectId, title, description, startDate, endDate, perPage, system_admin, history) {
    return (dispatch) => {
        addActivities(projectId, title, description, startDate, endDate)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(getProjectActivitiesListData(projectId, '', '', '', 1, perPage, system_admin, history));
                    utility.showSuccess(response.data.msg);
                } else {
                    utility.showError(response.data.msg);
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function updateActivitiesData(projectId, activitiesId, title, description, startDate, endDate, status, searchString, orderByField, orderByFieldValue, page_no, per_page, system_admin, history) {
    return (dispatch) => {
        updateActivities(activitiesId, title, description, startDate, endDate, status)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(getProjectActivitiesListData(projectId, searchString, orderByField, orderByFieldValue, page_no, per_page, system_admin, history));
                    utility.showSuccess(response.data.msg);
                } else {
                    utility.showError(response.data.msg);
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function getProjectActivitiesListData(project_id, searchString, orderByField, orderByFieldValue, page_no, per_page, system_admin, history) {
    return (dispatch) => {
        getProjectActivitiesList(project_id, searchString, orderByField, orderByFieldValue, page_no, per_page, system_admin)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(getProjectActivitiesListAction(response.data));
                } else {
                    utility.showError(response.data.msg);
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                //utility.showError(response.data.msg);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function getMultipleProjectActivitiesListData(project_id, searchString, orderByField, orderByFieldValue, page_no, per_page, system_admin, index, callBackActivity, history) {
    return (dispatch) => {
        getProjectActivitiesList(project_id, searchString, orderByField, orderByFieldValue, page_no, per_page, system_admin)
            .then((response) => {
                if (response.data.status == 200) {
                    callBackActivity(response.data.data, index);
                } else {
                    utility.showError(response.data.msg);
                }
            })
            .catch((error) => {
                //console.log(error);
                // utility.showError(response.data.msg);
            });
    };
}

export function checkActivityExistance(project_id, history) {
    return (dispatch) => {
        checkActivity(project_id)
            .then((response) => {
                console.log('response..', response.data);
                if (response.data.status == 200) {
                    dispatch(getProjectActivitiesCheckExistanceAction(response.data.has_data));
                } else {
                    utility.showError(response.data.msg);
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                //utility.showError(response.data.msg);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function updateProjectActiviesStatusData(item, history) {
    return (dispatch) => {
        updateProjectActiviesStatus(item._id, item.status === 1 ? 0 : 1)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(updateProjectActiviesStatusAction(item));
                    utility.showSuccess(response.data.msg);
                } else {
                    utility.showError(response.data.msg);
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                //utility.showError(response.data.msg);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function removeProjectActivitiesData(id, project_id, searchString, orderByField, orderByFieldValue, page_no, per_page, system_admin, history) {
    return (dispatch) => {
        removeProjectActivities(id)
            .then((response) => {
                if (response.data.status == 200) {
                    // dispatch(removeProjectActivitiesAction(id));
                    swal(response.data.msg, { icon: "success", });
                    dispatch(getProjectActivitiesListData(project_id, searchString, orderByField, orderByFieldValue, page_no, per_page, system_admin, history));
                } else {
                    swal(response.data.msg, "error");
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function addTaskData(project_id, activities_id, title, description, activity, timeKeeper, status, perPage, all, system_admin, history) {
    return (dispatch) => {
        addTask(project_id, activities_id, title, description, activity, timeKeeper, status)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(getTaskList(all == 1 ? "" : activities_id, '', '', '', 1, perPage, system_admin, history));
                    utility.showSuccess(response.data.msg);
                } else {
                    utility.showError(response.data.msg);
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function updateTaskData(id, title, description, activity, timeKeeper, status, activities_id, searchString, orderByField, orderByFieldValue, page_no, per_page, system_admin, history) {
    return (dispatch) => {
        updateTask(id, title, description, activity, timeKeeper, status)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(getTaskList(activities_id, searchString, orderByField, orderByFieldValue, page_no, per_page, system_admin, history));
                    utility.showSuccess(response.data.msg);
                } else {
                    utility.showError(response.data.msg);
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function getTaskList(activity_id, searchString, orderByField, orderByFieldValue, page_no, per_page, system_admin, history) {
    return (dispatch) => {
        taskList(activity_id, searchString, orderByField, orderByFieldValue, page_no, per_page, system_admin)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(getTaskListAction(response.data));
                } else {
                    utility.showError(response.data.msg);
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function getAccessableTaskList(activity_id, user_id, history) {
    return (dispatch) => {
        getAccessableTask(activity_id, user_id)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(getTaskListAction(response.data));
                } else {
                    utility.showError(response.data.msg);
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function getMultipleAccessableTaskList(activity_id, user_id, index, callBackTask, history) {
    return (dispatch) => {
        getAccessableTask(activity_id, user_id)
            .then((response) => {
                if (response.data.status == 200) {
                    callBackTask(response.data.data, index);
                } else {
                    utility.showError(response.data.msg);
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
            });
    };
}

export function checkUsedTaskData(items, history) {
    return (dispatch) => {
        checkUsedTask(items._id)
            .then((response) => {
                if (response.data.status == 200) {
                    history.push({
                        pathname: '/time-capture',
                        state: items
                    });
                } else {
                    utility.showError(response.data.msg);
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function updateTaskStatusData(item, history) {
    return (dispatch) => {
        updateTaskStatus(item._id, item.status === 1 ? 0 : 1)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(updateTaskStatusAction(item));
                    utility.showSuccess(response.data.msg);
                } else {
                    utility.showError(response.data.msg);
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                //utility.showError(response.data.msg);
                dispatch(globalLoadingAction(false));
            });
    };
}
export function removeTaskData(id, activity_id, searchString, orderByField, orderByFieldValue, page_no, per_page, system_admin, history) {
    return (dispatch) => {
        removeTask(id)
            .then((response) => {
                if (response.data.status == 200) {
                    //dispatch(removeTaskAction(id));
                    swal(response.data.msg, { icon: "success", });
                    dispatch(getTaskList(activity_id, searchString, orderByField, orderByFieldValue, page_no, per_page, system_admin, history));
                } else {
                    swal(response.data.msg, "error");
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function downloadProjectData(userIds, history) {
    return (dispatch) => {
        downloadProjectRecord(userIds)
            .then((response) => {
                if (response.data.status == 200) {
                    const outputFilename = `${Date.now()}.xls`;
                    const link = document.createElement('a');
                    link.href = response.data.data;
                    link.setAttribute('download', outputFilename);
                    document.body.appendChild(link);
                    link.click();
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function downloadProjectActivitiesData(userIds, history) {
    return (dispatch) => {
        downloadProjectActivitiesRecord(userIds)
            .then((response) => {
                if (response.data.status == 200) {
                    const outputFilename = `${Date.now()}.xls`;
                    const link = document.createElement('a');
                    link.href = response.data.data;
                    link.setAttribute('download', outputFilename);
                    document.body.appendChild(link);
                    link.click();
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function downloadProjectTaskData(userIds, history) {
    return (dispatch) => {
        downloadProjectTaskRecord(userIds)
            .then((response) => {
                if (response.data.status == 200) {
                    const outputFilename = `${Date.now()}.xls`;
                    const link = document.createElement('a');
                    link.href = response.data.data;
                    link.setAttribute('download', outputFilename);
                    document.body.appendChild(link);
                    link.click();
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function removeMultipleProjectData(projectIds, searchString, orderByField, orderByFieldValue, page_no, per_page, system_admin, history) {
    return (dispatch) => {
        removeMultipleProject(projectIds)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(getProjectList(searchString, orderByField, orderByFieldValue, page_no, per_page, system_admin, history));
                    utility.showSuccess(response.data.msg);
                } else {
                    dispatch(globalLoadingAction(false));
                    utility.showError(response.data.msg);
                }
            })
            .catch((error) => {
                //console.log(error);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function removeMultipleActivitiesData(activityIds, project_id, searchString, orderByField, orderByFieldValue, page_no, per_page, system_admin, history) {
    return (dispatch) => {
        removeMultipleActivies(activityIds)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(getProjectActivitiesListData(project_id, searchString, orderByField, orderByFieldValue, page_no, per_page, system_admin, history));
                    utility.showSuccess(response.data.msg);
                } else {
                    dispatch(globalLoadingAction(false));
                    utility.showError(response.data.msg);
                }
            })
            .catch((error) => {
                //console.log(error);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function removeMultipleTaskaData(taskIds, activity_id, searchString, orderByField, orderByFieldValue, page_no, per_page, system_admin, history) {
    return (dispatch) => {
        removeMultipleTaska(taskIds)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(getTaskList(activity_id, searchString, orderByField, orderByFieldValue, page_no, per_page, system_admin, history));
                    utility.showSuccess(response.data.msg);
                } else {
                    dispatch(globalLoadingAction(false));
                    utility.showError(response.data.msg);
                }
            })
            .catch((error) => {
                //console.log(error);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function getTaskDetailsByIdData(task_id, history) {
    return (dispatch) => {
        getTaskDetailsById(task_id)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(getTaskDetailsByIdAction(response.data.data));
                } else {
                    utility.showError(response.data.msg);
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function getProjectDetailsByIdData(project_id, history) {
    return (dispatch) => {
        getProjectDetailsById(project_id)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(getProjectDetailsByIdAction(response.data.data));
                } else {
                    utility.showError(response.data.msg);
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function addProjectAction(data) {
    return {
        type: ADD_PROJECT,
        payload: data,
    };
}
export function getProjectAction(data) {
    return {
        type: GET_PROJECT,
        payload: data,
    };
}
export function getProjectDetailsAction(data) {
    return {
        type: GET_PROJECT_DETAILS,
        payload: data,
    };
}
export function updateProjectStatusAction(data) {
    return {
        type: UPDATE_PROJECT_STATUS,
        payload: data,
    };
}
export function removeProjectAction(id) {
    return {
        type: REMOVE_PROJECT_DETAILS,
        payload: id,
    };
}
export function getProjectActivitiesListAction(data) {
    return {
        type: GET_PROJECT_ACTIVITIES,
        payload: data,
    };
}
export function getProjectActivitiesCheckExistanceAction(data) {
    return {
        type: GET_PROJECT_ACTIVITIES_CHECK_EXISTANCE,
        payload: data,
    };
}
export function updateProjectActiviesStatusAction(data) {
    return {
        type: UPDATE_PROJECT_ACTIVITIES_STATUS,
        payload: data,
    };
}
export function removeProjectActivitiesAction(id) {
    return {
        type: REMOVE_PROJECT_ACTIVITIES_DETAILS,
        payload: id,
    };
}
export function getTaskListAction(data) {
    return {
        type: GET_TASK_LIST,
        payload: data,
    };
}
export function updateTaskStatusAction(data) {
    return {
        type: UPDATE_TASK_STATUS,
        payload: data,
    };
}
export function removeTaskAction(id) {
    return {
        type: REMOVE_TASK_LIST,
        payload: id,
    };
}
export function getTaskDetailsByIdAction(data) {
    return {
        type: GET_TASK_DETAILS_BY_ID,
        payload: data,
    };
}
export function getProjectDetailsByIdAction(data) {
    return {
        type: GET_PROJECT_DETAILS_BY_ID,
        payload: data,
    };
}
export function loadingProjectAction(status) {
    return {
        type: LOADING_PROJECT,
        payload: status,
    };
}