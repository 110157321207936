import {
    formatError,
    login,
    runLogoutTimer,
    saveTokenInLocalStorage,
    signUp,
    emailVerify,
    verifyOtp,
    setPassword,
    setupAccount,
    setupCompanyInformation,
    getProfile,
    forgotPassword,
    verifyForgotPasswordOtp,
    resetPassword,
    changePassword,
    updateCompany,
    checkToken,
    getPermission,
    getGoogleUserInfo,
    googleLogin
} from '../../services/AuthService';

import {
    LOGIN_FAILED_ACTION,
    LOGOUT_ACTION,
    LOGIN_CONFIRMED_ACTION,
    LOADING_TOGGLE_ACTION,
    SIGNUP_FAILED_ACTION,
    SIGNUP_CONFIRMED_ACTION,
    SET_OTP_ACTION,
    SETUP_USER_DATA,
    SETUP_COMPANY_DATA,
    UPDATE_PROFILE_DATA,
    GET_PROFILE_AND_COMPANY_DETAILS_ACTION,
    GET_PERMISSION_MENU_DATA
} from '../constants';

import * as utility from '../../utility/Utility';
import { globalLoadingAction } from '../actions/GlobalAction';

export function signupAction(email, password, history) {
    return (dispatch) => {
        signUp(email, password)
            .then((response) => {
                saveTokenInLocalStorage(response.data);
                dispatch(confirmedSignupAction(response.data));
                history.push('/dashboard');
            })
            .catch((error) => {
                const errorMessage = formatError(error.response.data);
                dispatch(signupFailedAction(errorMessage));
            });
    };
}

export function checkTokenAction(tokenDetails, history) {
    return (dispatch) => {
        checkToken()
            .then((response) => {
                if (response.data.status === 200) {
                    dispatch(loginConfirmedAction(tokenDetails));
                    history.replace('/dashboard');
                } else {
                    dispatch(logout(history));
                }
            })
            .catch((error) => {
                dispatch(logout(history));
            });
    };
}

export function getPermissionList(id, token) {
    return (dispatch) => {
        getPermission(id, token)
            .then((response) => {
                //console.log('hello world get permission...',response.data);
                if (response.data.status === 200) {
                    dispatch(getPermissionAction(response.data.data));
                }
            }).catch((error) => {
                //error
            });
    };
}

export function logout(history) {
    localStorage.removeItem('userDetails');
    history.replace('/initial');
    return {
        type: LOGOUT_ACTION,
    };
}

export function expiredSubscription(data, history) {
    localStorage.removeItem('userDetails');
    history.push({
        pathname: '/free-subscribtion',
        state: data
    });
    return {
        type: LOGOUT_ACTION,
    };
}

export function getGoogleUserInfoData(access_token, history) {
    return (dispatch) => {
        getGoogleUserInfo(access_token)
            .then((response) => {
                //console.log('google...', response.data);
                dispatch(googleLoginAction(response.data.email, "qwe@K123", history));
            })
            .catch((error) => {
                console.log(error);
                dispatch(loadingToggleAction(false));
            });
    };
}

export function googleLoginAction(email, password, history) {
    return (dispatch) => {
        googleLogin(email, password)
            .then((response) => {
                if (response.data.status == 200) {
                    console.log('response...', response.data);
                    if (response.data.login_attempt == 2) {
                        saveTokenInLocalStorage(response.data.data);
                        dispatch(loginConfirmedAction(response.data.data));
                        utility.showSuccess(response.data.msg);
                        history.replace('/dashboard');
                    } else {
                        history.push({
                            pathname: '/page-register',
                            state: response.data.data
                        });
                    }
                } else {
                    utility.showError(response.data.msg);
                }
                dispatch(loadingToggleAction(false));
            })
            .catch((error) => {
                console.log(error);
                dispatch(loadingToggleAction(false));
            });
    };
}

export function loginAction(email, password, history) {
    return (dispatch) => {
        login(email, password)
            .then((response) => {
                if (response.data.status == 200) {
                    if (response.data.login_attempt == 2) {
                        saveTokenInLocalStorage(response.data.data);
                        dispatch(loginConfirmedAction(response.data.data));
                        utility.showSuccess(response.data.msg);
                        history.replace('/dashboard');
                    } else {
                        history.push({
                            pathname: '/page-register',
                            state: response.data.data
                        });
                    }
                } else {
                    utility.showError(response.data.msg);
                }
                dispatch(loadingToggleAction(false));
            })
            .catch((error) => {
                console.log(error);
                dispatch(loadingToggleAction(false));
            });
    };
}

export function emailAction(email, country, countryCode, history) {
    return (dispatch) => {
        emailVerify(email)
            .then((response) => {
                if (response.data.status == 200) {
                    history.push({
                        pathname: '/otp',
                        state: { 'email': email, 'country': country, 'country_code': countryCode, 'otp': response.data.otp }
                    });
                    utility.showSuccess(response.data.msg);
                } else {
                    utility.showError(response.data.msg);
                }
                dispatch(loadingToggleAction(false));
            })
            .catch((error) => {
                //console.log(error);
                dispatch(loadingToggleAction(false));
            });
    };
}

export function resetOtpAction(email, country, history) {
    return (dispatch) => {
        emailVerify(email, country)
            .then((response) => {
                if (response.data.status == 200) {
                    history.push({
                        pathname: '/otp',
                        state: { 'email': email, 'country': country, 'otp': response.data.otp }
                    });
                }
                dispatch(loadingToggleAction(false));
            })
            .catch((error) => {
                //console.log(error);
                dispatch(loadingToggleAction(false));
            });
    };
}

export function verifyOtpData(email, otp, country, country_code, history) {
    return (dispatch) => {
        verifyOtp(email, otp)
            .then((response) => {
                if (response.data.status == 200) {
                    utility.showSuccess(response.data.msg);
                    history.push({
                        pathname: '/set-password',
                        state: { 'email': email, 'country': country, 'country_code': country_code }
                    });
                } else {
                    utility.showError(response.data.msg);
                }
                dispatch(loadingToggleAction(false));
            })
            .catch((error) => {
                //console.log(error);
                dispatch(loadingToggleAction(false));
            });
    };
}

export function forgotPasswordAction(email, history) {
    return (dispatch) => {
        forgotPassword(email)
            .then((response) => {
                if (response.data.status == 200) {
                    utility.showSuccess(response.data.msg);
                    history.push({
                        pathname: '/otp',
                        state: { 'email': email, 'country': '', 'otp': response.data.otp }
                    });
                } else {
                    utility.showError(response.data.msg);
                }
                dispatch(loadingToggleAction(false));
            })
            .catch((error) => {
                //console.log(error);
                dispatch(loadingToggleAction(false));
            });
    };
}

export function verifyForgotPasswordOtpAction(email, otp, history) {
    return (dispatch) => {
        verifyForgotPasswordOtp(email, otp)
            .then((response) => {
                if (response.data.status == 200) {
                    utility.showSuccess(response.data.msg);
                    history.push({
                        pathname: '/reset-password',
                        state: { 'email': email }
                    });
                } else {
                    utility.showError(response.data.msg);
                }
                dispatch(loadingToggleAction(false));
            })
            .catch((error) => {
                //console.log(error);
                dispatch(loadingToggleAction(false));
            });
    };
}

export function resetPasswordAction(email, password, history) {
    return (dispatch) => {
        resetPassword(email, password)
            .then((response) => {
                if (response.data.status == 200) {
                    utility.showSuccess(response.data.msg);
                    history.push('/login');
                } else {
                    utility.showError(response.data.msg);
                }
                dispatch(loadingToggleAction(false));
            })
            .catch((error) => {
                //console.log(error);
                utility.showError(error.response.data);
                dispatch(loadingToggleAction(false));
            });
    };
}

export function changePasswordAction(old_password, new_password, history) {
    return (dispatch) => {
        changePassword(old_password, new_password)
            .then((response) => {
                if (response.data.status == 200) {
                    utility.showSuccess(response.data.msg);
                } else {
                    utility.showError(response.data.msg);
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                utility.showError(error.response.data);
                dispatch(globalLoadingAction(false));
            });
    };
}



export function setPasswordData(email, country, country_code, password, history) {
    return (dispatch) => {
        setPassword(email, country, country_code, password)
            .then((response) => {
                if (response.data.status == 200) {
                    utility.showSuccess(response.data.msg);
                    history.push('/login');
                } else {
                    utility.showError(response.data.msg);
                }
                dispatch(loadingToggleAction(false));
            })
            .catch((error) => {
                //console.log(error);
                utility.showError(error.response.data);
                dispatch(loadingToggleAction(false));
            });
    };
}

export function setupAccountData(state, first_name, last_name, phone_no, designation, history) {
    return (dispatch) => {
        setupAccount(state, first_name, last_name, phone_no, designation)
            .then((response) => {
                if (response.data.status == 200) {
                    utility.showSuccess(response.data.msg);
                    dispatch(setUserDataAction({ "id": state._id, "first_name": first_name, "last_name": last_name, "phone_no": phone_no, "role": designation, "user_type": state.user_type }));
                    history.push({
                        pathname: '/company-information',
                        state: state
                    });
                } else {
                    utility.showError(response.data.msg);
                }
                dispatch(loadingToggleAction(false));
            })
            .catch((error) => {
                //console.log(error);
                utility.showError(error.response.data);
                dispatch(loadingToggleAction(false));
            });
    };
}

export function setupCompanyInformationData(id, company_name, company_phone, designation, country, countyCode, state, city, pincode, industry, website_url, address1, address2, data, history) {
    return (dispatch) => {
        setupCompanyInformation(id, company_name, company_phone, designation, country, countyCode, state, city, pincode, industry, website_url, address1, address2, data)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(setCompanyDataAction(response.data.data));
                    utility.showSuccess(response.data.msg);
                    let obj = {
                        "_id": data._id,
                        "token": data.token,
                        "company_id": response.data.data.company_id,
                        "country": data.country,
                        "country_code": data.country_code,
                        "email": data.email,
                        "status": data.status,
                        "system_admin": true,
                        "expired": 1,
                        "user_type": data.user_type,
                    }
                    history.push({
                        pathname: '/setup-profile',
                        state: obj
                    });
                } else {
                    utility.showError(response.data.msg);
                }
                dispatch(loadingToggleAction(false));
            })
            .catch((error) => {
                //console.log(error);
                utility.showError(error.response.data);
                dispatch(loadingToggleAction(false));
            });
    };
}

export function getProfileDetails(history) {
    return (dispatch) => {
        getProfile()
            .then((response) => {
                // console.log('hello dashboard........', response.data.data);
                if (response.data.status == 200) {
                    dispatch(getProfileDetailsAction(response.data.data));
                    // utility.showSuccess("Resister in successfully.");
                } else {
                    utility.showError(response.data.msg);
                }
                dispatch(loadingToggleAction(false));
            })
            .catch((error) => {
                console.log(error);
                //utility.showError(error.response.data);
                dispatch(loadingToggleAction(false));
            });
    };
}

export function updateCompanyDetails(companyId, companyName, address1, address2, country, countryCode, state, city, phone, pincode, industry, url, history) {
    return (dispatch) => {
        updateCompany(companyId, companyName, address1, address2, country, countryCode, state, city, phone, pincode, industry, url)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(setCompanyDataAction(response.data.data));
                    utility.showSuccess(response.data.msg);
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function loginFailedAction(data) {
    return {
        type: LOGIN_FAILED_ACTION,
        payload: data,
    };
}

export function setUserDataAction(data) {
    return {
        type: SETUP_USER_DATA,
        payload: data,
    };
}

export function setCompanyDataAction(data) {
    return {
        type: SETUP_COMPANY_DATA,
        payload: data,
    };
}

export function updateProfileAction(data) {
    return {
        type: UPDATE_PROFILE_DATA,
        payload: data,
    };
}

export function loginConfirmedAction(data) {
    return {
        type: LOGIN_CONFIRMED_ACTION,
        payload: data,
    };
}

export function confirmedSignupAction(data) {
    return {
        type: SIGNUP_CONFIRMED_ACTION,
        payload: data,
    };
}

export function signupFailedAction(message) {
    return {
        type: SIGNUP_FAILED_ACTION,
        payload: message,
    };
}
export function postOtpAction(otp) {
    return {
        type: SET_OTP_ACTION,
        payload: otp,
    };
}
export function setOTPAction(otp) {
    return {
        type: SET_OTP_ACTION,
        payload: otp,
    };
}
export function getProfileDetailsAction(data) {
    return {
        type: GET_PROFILE_AND_COMPANY_DETAILS_ACTION,
        payload: data,
    };
}
export function getPermissionAction(data) {
    return {
        type: GET_PERMISSION_MENU_DATA,
        payload: data,
    };
}
export function loadingToggleAction(status) {
    return {
        type: LOADING_TOGGLE_ACTION,
        payload: status,
    };
}
