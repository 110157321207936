import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './store/store';
import reportWebVitals from "./reportWebVitals";
import SimpleReactLightbox from "simple-react-lightbox";
import ThemeContext from "./context/ThemeContext";
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { GoogleOAuthProvider } from '@react-oauth/google';

//"405833016461-4274pmdkq4egggf14q3f1dk57unjj5j7.apps.googleusercontent.com"
// working = 222510720105-1a8ak3dccqeb6j3b88b4gsita2os2cfo.apps.googleusercontent.com

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <SimpleReactLightbox>
                {/* <BrowserRouter basename='/react/demo'> */}
                <BrowserRouter>
                    <ThemeContext>
                        <GoogleOAuthProvider clientId="767517614960-3r1avfrcrvdcpoi5asli2hl6j4t0422q.apps.googleusercontent.com">
                            <App />
                        </GoogleOAuthProvider>
                        <ToastContainer />
                    </ThemeContext>
                </BrowserRouter>
            </SimpleReactLightbox>
        </Provider>
    </React.StrictMode>,
    document.getElementById("root")
);
reportWebVitals();
