import {
    LOADING_LOCATION,
    GET_LOCATION_LIST,
    GET_LOCATION_TYPE_LIST,
    UPDATE_LOCATION_STATUS,
} from '../constants';

const initialState = {
    list: [],
    locationTypeList: [],
    totalPage: 0,
    totalRecord: 0,
    screenVisible: false,
    showLoading: false,
};

export function LocationReducer(state = initialState, action) {

    if (action.type === GET_LOCATION_LIST) {
        return {
            ...state,
            totalPage: action.payload.total_page,
            totalRecord: action.payload.total_record,
            list: action.payload.data,
            screenVisible: true,
            showLoading: false,
        };
    }

    if (action.type === GET_LOCATION_TYPE_LIST) {
        let data = action.payload.data;
        let array = [];
        for (let i = 0; data.length > i; i++) {
            array.push(data[i].type_name);
        }
        return {
            ...state,
            locationTypeList: array,
        };
    }

    if (action.type === UPDATE_LOCATION_STATUS) {
        const list = [...state.list];
        const index = list.findIndex((item) => item._id === action.payload._id);
        list[index].status = action.payload.status === 1 ? 0 : 1;
        return {
            ...state,
            list: list,
            showLoading: false,
        };
    }

    if (action.type === LOADING_LOCATION) {
        return {
            ...state,
            showLoading: action.payload,
        };
    }
    return state;
}


