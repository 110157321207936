import React, { useContext, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import {
    Row,
    Col,
    Card,
    Table,
    Modal,
    Button,
    Accordion
} from "react-bootstrap";
import Moment from 'react-moment';
import swal from "sweetalert";
import ReactPaginate from "react-paginate";
import { getTimeCardListData } from '../../../store/actions/TimeCardActions';
import { globalLoadingAction } from '../../../store/actions/GlobalAction';
import { validateEmail, validatePhone, validatePassword } from '../../../utility/Utility';
import { getCountryList, countryLoadingAction } from '../../../store/actions/CountryAction';
import { verifyEmail } from '../../../services/EmployeeService';

const TimeCart = (props) => {

    const dispatch = useDispatch();
    const history = useHistory();

    let errorsObj = { alt: '', fName: '', lName: '', email: '', mobile: '', status: '', pws: '', comPws: '', bAddress: '', appName: '', appEmail: '', appCode: '', appMobile: '', resName: '', resEmail: '', resCode: '', resMobile: '', countryCode: '' };

    const [errors, setErrors] = useState(errorsObj);

    const [action, setAction] = useState(1);
    const [addModal, setAddModal] = useState(false);
    const [details, setDetails] = useState({});

    const [isDisabled, setIsDisabled] = useState(true);
    const [checkArray, setCheckArray] = useState([]);
    const [validEmail, setValidEmail] = useState(false);

    const chackbox = document.querySelectorAll(".checkbox-select input");
    const motherChackBox = document.querySelector("#checkAll");

    //filter
    const [searchText, setSearchText] = useState('');
    const [sordBy, setSordBy] = useState('');
    const [sort, setSort] = useState(null);
    const [pageNo, setPageNo] = useState(1);
    const [perPage, setPerPage] = useState(10);

    useEffect(() => {
        dispatch(globalLoadingAction(true));
        dispatch(getTimeCardListData('', '', '', 1, perPage, props.history));
        //dispatch(getCountryList(props.history));
    }, []);


    // const addClientHanler = () => {
    //     setAction(1);
    //     setAddModal(true);
    //     setIsDisabled(true);
    //     setCheckArray([]);
    //     chackbox.forEach((val, index) => {
    //         chackbox[index].checked = false;
    //     });
    //     motherChackBox.checked = false;
    // }

    const onEditHandler = (item) => {
        return
        setDetails(item);
        setAction(2);
        setAddModal(true);
        setIsDisabled(true);
        setCheckArray([]);
        chackbox.forEach((val, index) => {
            chackbox[index].checked = false;
        });
        motherChackBox.checked = false;
    }



    const chackboxFun = (element, item) => {

        let array = [...checkArray];
        if (item === 0) {
            if (motherChackBox.checked == true) {
                props.list.forEach((val, index) => {
                    array.push(val._id);
                    document.querySelector(`#check${index}`).checked = true;
                });
                setIsDisabled(false);
            } else {
                props.list.forEach((val, index) => {
                    array = [];
                    document.querySelector(`#check${index}`).checked = false;
                });
                setIsDisabled(true);
            }

        } else {

            if (element.target.checked == true) {
                for (let x = 0; chackbox.length > x; x++) {
                    if (document.querySelector(`#check${x}`).checked == false) {
                        motherChackBox.checked = false;
                        break;
                    } else {
                        motherChackBox.checked = true;
                        setIsDisabled(false);
                    }
                }
                setIsDisabled(false);
                array.push(item._id);
            } else {
                const index = array.findIndex((val) => val === item.id);
                array.splice(index, 1);
                motherChackBox.checked = false;
                for (let x = 0; chackbox.length > x; x++) {
                    if (document.querySelector(`#check${x}`).checked == true) {
                        setIsDisabled(false);
                        break;
                    } else {
                        setIsDisabled(true);
                    }
                }
            }
        }
        setCheckArray(array);
    };

    const onDownloadHandler = () => {
        dispatch(globalLoadingAction(true));
        //dispatch(downloadClientData(checkArray, props.history));
        setIsDisabled(true);
        setCheckArray([]);
        chackbox.forEach((val, index) => {
            chackbox[index].checked = false;
        });
        motherChackBox.checked = false;
    }

    const onDeleteHandler = (id) => {
        return;
        swal({
            title: "Are you sure?",
            text:
                "You want to remove this item !",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                if (props.list.length == 1) {
                    //dispatch(removeClientData(id, searchText, sordBy, sort === null ? sort ? 'asc' : 'desc' : '', pageNo - 1, perPage, props.history));
                    setPageNo(pageNo - 1);
                } else {
                    //dispatch(removeClientData(id, searchText, sordBy, sort === null ? sort ? 'asc' : 'desc' : '', pageNo, perPage, props.history));
                }
            }
        })

    }
    const onMultipleRemoveHandler = () => {
        swal({
            title: "Are you sure?",
            text: checkArray.length > 1 ? "You want to remove items!" : "You want to remove item!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                dispatch(globalLoadingAction(true));
                if (props.list.length == checkArray.length) {
                    //dispatch(removeMultipleClientData(checkArray, searchText, sordBy, sort === null ? sort ? 'asc' : 'desc' : '', pageNo - 1, perPage, props.history));
                    setPageNo(pageNo - 1);
                } else {
                    //dispatch(removeMultipleClientData(checkArray, searchText, sordBy, sort === null ? sort ? 'asc' : 'desc' : '', pageNo, perPage, props.history));
                }
                setIsDisabled(true);
                setCheckArray([]);
                chackbox.forEach((val, index) => {
                    chackbox[index].checked = false;
                });
                motherChackBox.checked = false;
            }
        })
    }

    const onSearchHandler = (val) => {
        setSearchText(val);
        setSort(null);
        setSordBy('');
        const timerId = setTimeout(() => {
            dispatch(globalLoadingAction(true));
            dispatch(getTimeCardListData(val, '', '', 1, perPage, props.history));
        }, 1000);
        return () => clearTimeout(timerId); // Cleanup on component unmount or re-render
    }

    const onSordByHandler = (val) => {
        setSordBy(val);
        setSort(null);
    }

    const onSordHandler = () => {
        if (sordBy) {
            setSort(!sort);
            dispatch(globalLoadingAction(true));
            dispatch(getTimeCardListData(searchText, sordBy, !sort ? 'asc' : 'desc', 1, perPage, props.history));
        }
    }

    const onCleanHandler = () => {
        setSearchText('');
        setPageNo(1);
        setSort(null);
        setSordBy('');
        dispatch(globalLoadingAction(true));
        dispatch(getTimeCardListData('', '', '', 1, perPage, props.history));
    }

    const handlePageClick = (data) => {
        setPageNo(data.selected + 1);
        dispatch(globalLoadingAction(true));
        dispatch(getTimeCardListData(searchText, '', '', data.selected + 1, perPage, props.history));
        setSort(null);
        setSordBy('');
    };

    return (
        <>
            <div className="row">
                <div className="breadcrumb-container">
                    <ul>
                        <li>
                            <Link to={"#"}>Reporting</Link>
                        </li>
                        <li><i className="fa fa-angle-right"></i></li>
                        <li>
                            <Link to={"#"}>Time Entry</Link>
                        </li>
                    </ul>
                </div>
                <div className="title-body-container">
                    <div className="title-body">
                        <h3>Time Entry</h3>
                        <p>Maintain user information - Add, edit, delete and invite users</p>
                    </div>
                    <div className="title-filter-container">
                        <div className="search-filter">
                            <input placeholder="Search" value={searchText} onChange={(e) => onSearchHandler(e.target.value)} />
                            <i class="fa fa-search" aria-hidden="true"></i>
                        </div>

                        <div className="sort-filter">
                            <select
                                defaultValue={"option"}
                                className=""
                                value={sordBy}
                                onChange={(e) => onSordByHandler(e.target.value)}>
                                <option>Sort by</option>
                                <option value="first_name">Name</option>
                                <option value="email">Email</option>
                                <option value="phone_no">Mobile</option>
                            </select>
                            <button className={sort === null ? null : sort ? "sorting-asc" : "sorting-dec"} onClick={onSordHandler}>
                                <i class="fa fa-long-arrow-up" aria-hidden="true"></i>
                                <i class="fa fa-long-arrow-down" aria-hidden="true"></i>
                            </button>
                        </div>

                        <div className="button-filter">
                            <button className="add-user-btn"><i class="fa fa-plus" aria-hidden="true"></i></button>
                            <button className="add-filter-up-btn"><i class="fa fa-long-arrow-up" aria-hidden="true"></i></button>
                        </div>

                    </div>

                </div>
                <div className="account-setting-content">
                    <Col lg={12}>
                        <Card>
                            <Card.Header>
                                <div className="add-role-button-view">

                                    <div className="add-btn-group">
                                        <button disabled={isDisabled}><i class="fa fa-external-link" aria-hidden="true"></i></button>
                                        <button
                                            //onClick={() => onMultipleRemoveHandler()}
                                            disabled={isDisabled}>X</button>
                                        <button disabled={isDisabled}>S</button>
                                        <button
                                            //onClick={() => onDownloadHandler()}
                                            disabled={isDisabled}>
                                            <i class="fa fa-download" aria-hidden="true"></i></button>
                                    </div>
                                </div>
                            </Card.Header>
                            <Card.Body>
                                {props.screenVisible ?
                                    <>
                                        {props.list.length > 0 ?
                                            <Table responsive>
                                                <thead>
                                                    <tr>
                                                        {/* <th className="customer_shop">
                                                            <div className="form-check custom-checkbox mx-2">
                                                                <input
                                                                    type="checkbox"
                                                                    className="form-check-input"
                                                                    id="checkAll"
                                                                    onClick={(e) => chackboxFun('all', 0)}
                                                                />
                                                                <label
                                                                    className="form-check-label"
                                                                    htmlFor="checkAll"
                                                                ></label>
                                                            </div>
                                                        </th> */}
                                                        <th>
                                                            <strong>ID</strong>
                                                        </th>
                                                        <th>
                                                            <strong>Name</strong>
                                                        </th>
                                                        <th>
                                                            <strong>Email</strong>
                                                        </th>
                                                        <th>
                                                            <strong>Mobile</strong>
                                                        </th>
                                                        <th>
                                                            <strong>Date</strong>
                                                        </th>
                                                        <th>
                                                            <strong>Actions</strong>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        props.list.map((item, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    {/* <td>
                                                                        <div className="form-check custom-checkbox checkbox-select mx-2">
                                                                            <input
                                                                                type="checkbox"
                                                                                className="form-check-input"
                                                                                id={`check${index}`}
                                                                                onClick={(e) => chackboxFun(e, item)}
                                                                            />
                                                                            <label
                                                                                className="form-check-label"
                                                                                htmlFor={`check${index}`}
                                                                            ></label>
                                                                        </div>
                                                                    </td> */}
                                                                    <td>{item.emp_id}</td>
                                                                    <td>{item.first_name} {item.last_name}</td>
                                                                    <td>{item.email}</td>
                                                                    <td>{item.phone_no}</td>
                                                                    <td><Moment format="D MMM YYYY" withTitle>{item.date}</Moment></td>

                                                                    <td>
                                                                        <div className="d-flex">
                                                                            <Link
                                                                                to={{
                                                                                    pathname: '/cart-details',
                                                                                    state: { id: item._id }
                                                                                }}
                                                                                className="btn btn-info shadow btn-xs sharp"
                                                                            >
                                                                                <i className="fa fa-external-link-square"></i>
                                                                            </Link>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })
                                                    }
                                                </tbody>
                                            </Table>
                                            :
                                            <div className="nodata-found-view">
                                                <i class="fa fa-search" aria-hidden="true"></i>
                                                <h2>Data not found</h2>
                                                <p>Client data is empty or Try again search</p>
                                                <button type="button" onClick={onCleanHandler}>
                                                    Retry
                                                </button>
                                            </div>
                                        }
                                    </>
                                    : null}
                                <div className="pagination-container">
                                    {parseInt(props.totalRecord) > 0 && props.showLoading == false ? (
                                        <div className="page-result alignResult">
                                            <p>Showing{" "}{(pageNo - 1) * perPage + 1 + " - " + ((pageNo - 1) * perPage + props.list.length) + " of " + "( " + props.totalRecord + " items )"}</p>
                                        </div>
                                    ) : (
                                        <div></div>
                                    )}

                                    {parseInt(props.totalRecord) > 10 ? (
                                        <ReactPaginate
                                            containerClassName="pagination-gutter false pagination-circle pagination"
                                            pageClassName="page-item"
                                            pageLinkClassName="page-link"
                                            breakLabel="..."
                                            nextLabel={<i class="fa fa-angle-right" aria-hidden="true"></i>}
                                            nextClassName="page-item"
                                            nextLinkClassName="page-link"
                                            previousLabel={<i class="fa fa-angle-left" aria-hidden="true"></i>}
                                            previousClassName="page-item"
                                            previousLinkClassName="page-link"
                                            breakClassName="page-item"
                                            breakLinkClassName="page-link"
                                            renderOnZeroPageCount={null}
                                            activeClassName="active"
                                            onPageChange={handlePageClick}
                                            pageRangeDisplayed={3}
                                            pageCount={props.totalPage}
                                            //initialPage={active - 1}
                                            forcePage={pageNo - 1}
                                        />
                                    ) : null}
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </div>

                {/* <AddClient
                    show={addModal}
                    data={details}
                    action={action}
                    submitHandler={(data) => onSubmitClientHandler(data)}
                    closeHandler={() => setAddModal(false)}
                /> */}

            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        screenVisible: state.timeCard.screenVisible,
        showLoading: state.timeCard.showLoading,
        list: state.timeCard.list,
        totalPage: state.timeCard.totalPage,
        totalRecord: state.timeCard.totalRecord,
        countryList: state.country.countryList,
    };
};
export default connect(mapStateToProps)(TimeCart);