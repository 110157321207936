import React, { useContext, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import { Link, useHistory, useLocation } from 'react-router-dom';
import ReactPaginate from "react-paginate";
import {
	Row,
	Col,
	Card,
	Table,
	Modal,
	Button,
	ProgressBar,
} from "react-bootstrap";
import Moment from 'react-moment';
import swal from "sweetalert";

import { loadingTeamAction, addTeamData, updateTeamData, getTeamList, updateTeamStatusData, getTeamByIdList, getTeamByIdAction, removeTeamData, downloadTeamData, removeMultipleTeamData } from '../../../store/actions/TeamActions';
import { globalLoadingAction } from '../../../store/actions/GlobalAction';


const Group = (props) => {

	const dispatch = useDispatch();
	const history = useHistory();

	let errorsObj = { gid: '', desc: '', emp: '', note: '', status: '' };
	const [errors, setErrors] = useState(errorsObj);
	const [groupId, setGroupId] = useState('');
	const [description, setDescription] = useState('');
	const [employees, setEmployees] = useState([]);
	const [addModal, setAddModal] = useState(false);
	const [action, setAction] = useState(1);
	const [teamId, setTeamId] = useState('');
	const [status, setStatus] = useState('');
	const [note, setNote] = useState('');
	const [isDisabled, setIsDisabled] = useState(true);
	const [checkArray, setCheckArray] = useState([]);

	const chackbox = document.querySelectorAll(".checkbox-select input");
	const motherChackBox = document.querySelector("#checkAll");

	//filter
	const [searchText, setSearchText] = useState('');
	const [sordBy, setSordBy] = useState('');
	const [sort, setSort] = useState(null);
	const [pageNo, setPageNo] = useState(1);
	const [perPage, setPerPage] = useState(10);

	useEffect(() => {
		dispatch(globalLoadingAction(true));
		dispatch(getTeamList('', '', '', 1, perPage, props.history));
		//dispatch(getTimeKeeperList(props.history));
	}, []);

	function onSubmit(e) {

		e.preventDefault();

		let error = false;
		const errorObj = { ...errorsObj };

		if (groupId === '') {
			errorObj.gid = 'Group id is Required';
			error = true;
		}
		if (description === '') {
			errorObj.desc = 'Description is Required';
			error = true;
		}
		if (note === '') {
			errorObj.note = 'Note is Required';
			error = true;
		}
		if (status === '') {
			errorObj.status = 'status is Required';
			error = true;
		}

		setErrors(errorObj);
		if (error) {
			return;
		}

		dispatch(globalLoadingAction(true));
		if (action == 1) {
			dispatch(addTeamData(groupId, description, note, parseInt(status), perPage, props.history));
			setSearchText('');
			setSordBy('');
			setSort(null);
			setPageNo(1);
		} else {
			dispatch(updateTeamData(teamId,
				groupId,
				description,
				parseInt(status),
				note, searchText,
				sordBy,
				sort === null ? sort ? 'asc' : 'desc' : '',
				pageNo,
				perPage, props.history));
		}

		setGroupId('');
		setDescription('');
		setNote('');
		setStatus('');
		setAddModal(false);
	}

	const onAddHandler = () => {
		dispatch(getTeamByIdAction([]));
		const errorObj = { ...errorsObj };
		errorObj.gid = '';
		errorObj.desc = '';
		errorObj.emp = '';
		errorObj.note = '';
		setErrors(errorObj);
		setAddModal(true);
		setAction(1);
		setNote('');
		setGroupId('');
		setDescription('');
		setStatus('');
		setIsDisabled(true);
		setCheckArray([]);
		chackbox.forEach((val, index) => {
			chackbox[index].checked = false;
		});
		motherChackBox.checked = false;
	}
	const onEditHandler = (item) => {
		const errorObj = { ...errorsObj };
		errorObj.gid = '';
		errorObj.desc = '';
		errorObj.emp = '';
		errorObj.note = '';
		setErrors(errorObj);
		setAction(2);
		setGroupId(item.groupId);
		setDescription(item.description);
		setTeamId(item._id);
		setStatus(item.status);
		setNote(item.note);
		setAddModal(true);
		setIsDisabled(true);
		setCheckArray([]);
		chackbox.forEach((val, index) => {
			chackbox[index].checked = false;
		});
		motherChackBox.checked = false;
	}

	const onStatusHandler = (item) => {
		dispatch(globalLoadingAction(true));
		dispatch(updateTeamStatusData(item));
	}

	const onDeleteHandler = (id) => {
		swal({
			title: "Are you sure?",
			text:
				"You want to remove this item !",
			icon: "warning",
			buttons: true,
			dangerMode: true,
		}).then((willDelete) => {
			if (willDelete) {
				if (props.teamList.length == 1) {
					dispatch(removeTeamData(id, searchText, sordBy, sort === null ? sort ? 'asc' : 'desc' : '', pageNo - 1, perPage, props.history));
					setPageNo(pageNo - 1);
				} else {
					dispatch(removeTeamData(id, searchText, sordBy, sort === null ? sort ? 'asc' : 'desc' : '', pageNo, perPage, props.history));
				}
			}
		})
	}

	const onMultipleRemoveHandler = () => {
		swal({
			title: "Are you sure?",
			text: checkArray.length > 1 ? "You want to remove items!" : "You want to remove item!",
			icon: "warning",
			buttons: true,
			dangerMode: true,
		}).then((willDelete) => {
			if (willDelete) {
				dispatch(globalLoadingAction(true));
				if (props.teamList.length == checkArray.length) {
					dispatch(removeMultipleTeamData(checkArray, searchText, sordBy, sort === null ? sort ? 'asc' : 'desc' : '', pageNo - 1, perPage, props.history));
					setPageNo(pageNo - 1);
				} else {
					dispatch(removeMultipleTeamData(checkArray, searchText, sordBy, sort === null ? sort ? 'asc' : 'desc' : '', pageNo, perPage, props.history));
				}
				setIsDisabled(true);
				setCheckArray([]);
				chackbox.forEach((val, index) => {
					chackbox[index].checked = false;
				});
				motherChackBox.checked = false;
			}
		})
	}

	const chackboxFun = (element, item) => {

		let array = [...checkArray];
		if (item === 0) {
			if (motherChackBox.checked == true) {
				props.teamList.forEach((val, index) => {
					array.push(val._id);
					document.querySelector(`#check${index}`).checked = true;
				});
				setIsDisabled(false);
			} else {
				props.teamList.forEach((val, index) => {
					array = [];
					document.querySelector(`#check${index}`).checked = false;
				});
				setIsDisabled(true);
			}

		} else {

			if (element.target.checked == true) {
				for (let x = 0; chackbox.length > x; x++) {
					if (document.querySelector(`#check${x}`).checked == false) {
						motherChackBox.checked = false;
						break;
					} else {
						motherChackBox.checked = true;
						setIsDisabled(false);
					}
				}
				setIsDisabled(false);
				array.push(item._id);
			} else {
				const index = array.findIndex((val) => val === item.id);
				array.splice(index, 1);
				motherChackBox.checked = false;
				for (let x = 0; chackbox.length > x; x++) {
					if (document.querySelector(`#check${x}`).checked == true) {
						setIsDisabled(false);
						break;
					} else {
						setIsDisabled(true);
					}
				}
			}
		}
		setCheckArray(array);
	};


	const onDownloadHandler = () => {
		dispatch(globalLoadingAction(true));
		dispatch(downloadTeamData(checkArray, props.history));
		setIsDisabled(true);
		setCheckArray([]);
		chackbox.forEach((val, index) => {
			chackbox[index].checked = false;
		});
		motherChackBox.checked = false;
	}

	const onSearchHandler = (val) => {
		setSearchText(val);
		setSort(null);
		setSordBy('');
		const timerId = setTimeout(() => {
			dispatch(globalLoadingAction(true));
			dispatch(getTeamList(val, '', '', 1, perPage, props.history));
		}, 1000);
		return () => clearTimeout(timerId); // Cleanup on component unmount or re-render
	}

	const onSordByHandler = (val) => {
		setSordBy(val);
		setSort(null);
	}

	const onSordHandler = () => {
		if (sordBy) {
			setSort(!sort);
			dispatch(globalLoadingAction(true));
			dispatch(getTeamList(searchText, sordBy, !sort ? 'asc' : 'desc', 1, perPage, props.history));
		}
	}

	const onCleanHandler = () => {
		setSearchText('');
		setPageNo(1);
		setSort(null);
		setSordBy('');
		dispatch(globalLoadingAction(true));
		dispatch(getTeamList('', '', '', 1, perPage, props.history));
	}

	const handlePageClick = (data) => {
		setPageNo(data.selected + 1);
		dispatch(globalLoadingAction(true));
		dispatch(getTeamList(searchText, '', '', data.selected + 1, perPage, props.history));
		setSort(null);
		setSordBy('');
	};

	return (
		<>
			{/* {console.log('List.......', props.teamList)} */}
			<div className="row">
				<div className="breadcrumb-container">
					<ul>
						<li>
							<Link to={"#"}>System Setting</Link>
						</li>
						<li><i className="fa fa-angle-right"></i></li>
						<li>
							<Link to={"#"}>Group</Link>
						</li>
					</ul>
				</div>
				<div className="title-body-container">
					<div className="title-body">
						<h3>Group</h3>
						<p>Maintain user information - Add, edit, delete and invite users</p>
					</div>
					<div className="title-filter-container">

						<div className="search-filter">
							<input placeholder="Search" value={searchText} onChange={(e) => onSearchHandler(e.target.value)} />
							<i class="fa fa-search" aria-hidden="true"></i>
						</div>

						<div className="sort-filter">
							<select
								defaultValue={"option"}
								className=""
								value={sordBy}
								onChange={(e) => onSordByHandler(e.target.value)}>
								<option>Sort by</option>
								<option value="description">Name</option>
								<option value="note">DESCRP</option>
							</select>
							<button className={sort === null ? null : sort ? "sorting-asc" : "sorting-dec"} onClick={onSordHandler}>
								<i class="fa fa-long-arrow-up" aria-hidden="true"></i>
								<i class="fa fa-long-arrow-down" aria-hidden="true"></i>
							</button>
						</div>

						<div className="button-filter">
							<button className="add-user-btn" onClick={() => onAddHandler()}><i class="fa fa-plus" aria-hidden="true"></i></button>
							<button className="add-filter-up-btn"><i class="fa fa-long-arrow-up" aria-hidden="true"></i></button>
						</div>

					</div>

				</div>
				<div className="account-setting-content">
					<Col lg={12}>
						<Card>
							<Card.Header>
								<div className="add-role-button-view">
									<div className="add-btn-group">
										<button disabled={isDisabled}><i class="fa fa-external-link" aria-hidden="true"></i></button>
										<button
											onClick={() => onMultipleRemoveHandler()}
											disabled={isDisabled}>X</button>
										<button disabled={isDisabled}>S</button>
										<button
											onClick={() => onDownloadHandler()}
											disabled={isDisabled}>
											<i class="fa fa-download" aria-hidden="true"></i></button>
									</div>
								</div>
							</Card.Header>
							<Card.Body>
								{props.screenVisible ?
									<>
										{props.teamList.length > 0 ?
											<Table responsive>
												<thead>
													<tr>
														<th className="customer_shop">
															<div className="form-check custom-checkbox mx-2">
																<input
																	type="checkbox"
																	className="form-check-input"
																	id="checkAll"
																	onClick={(e) => chackboxFun('all', 0)}
																/>
																<label
																	className="form-check-label"
																	htmlFor="checkAll"
																></label>
															</div>
														</th>
														<th>
															<strong>Id</strong>
														</th>
														<th>
															<strong>Name</strong>
														</th>
														<th>
															<strong>DESCRP</strong>
														</th>
														<th>
															<strong>Status</strong>
														</th>
														<th>
															<strong>Action</strong>
														</th>
													</tr>
												</thead>
												<tbody>
													{
														props.teamList.map((item, index) => {
															return (
																<tr key={index}>
																	<td>
																		<div className="form-check custom-checkbox checkbox-select mx-2">
																			<input
																				type="checkbox"
																				className="form-check-input"
																				id={`check${index}`}
																				onClick={(e) => chackboxFun(e, item)}
																			/>
																			<label
																				className="form-check-label"
																				htmlFor={`check${index}`}
																			></label>
																		</div>
																	</td>
																	<td>{item.groupId}</td>
																	<td>{item.description}</td>
																	<td>{item.note}</td>
																	<td>
																		<Button
																			className="btn-xs"
																			variant={item.status == 1 ? "success" : "danger"}
																			onClick={() => onStatusHandler(item)}
																		>
																			{item.status == 1 ? "Active" : "Inactive"}
																		</Button>
																	</td>
																	<td>
																		<div className="d-flex">
																			<Link
																				onClick={() => onEditHandler(item)}
																				className="btn btn-warning shadow btn-xs sharp me-1"
																			>
																				<i className="fas fa-pencil-alt"></i>
																			</Link>

																			<Button
																				className="btn btn-danger shadow btn-xs sharp me-1"
																				onClick={() => onDeleteHandler(item._id)}
																			>
																				<i className="fa fa-trash"></i>
																			</Button>

																			<Link
																				//onClick={() => onEditHandler(item)}
																				className="btn btn-info shadow btn-xs sharp"
																			>
																				<i className="fa fa-external-link-square"></i>
																			</Link>
																		</div>
																	</td>
																</tr>
															);
														})
													}
												</tbody>
											</Table>
											: <div className="nodata-found-view">
												<i class="fa fa-search" aria-hidden="true"></i>
												<h2>Data not found</h2>
												<p>Group data is empty or Try again search</p>
												<button type="button" onClick={onCleanHandler}>
													Retry
												</button>
											</div>
										}
									</>
									: null}

								<div className="pagination-container">
									{parseInt(props.totalRecord) > 0 && props.showLoading == false ? (
										<div className="page-result alignResult">
											<p>Showing{" "}{(pageNo - 1) * perPage + 1 + " - " + ((pageNo - 1) * perPage + props.teamList.length) + " of " + "( " + props.totalRecord + " items )"}</p>
										</div>
									) : (
										<div></div>
									)}

									{parseInt(props.totalRecord) > 10 ? (
										<ReactPaginate
											containerClassName="pagination-gutter false pagination-circle pagination"
											pageClassName="page-item"
											pageLinkClassName="page-link"
											breakLabel="..."
											nextLabel={<i class="fa fa-angle-right" aria-hidden="true"></i>}
											nextClassName="page-item"
											nextLinkClassName="page-link"
											previousLabel={<i class="fa fa-angle-left" aria-hidden="true"></i>}
											previousClassName="page-item"
											previousLinkClassName="page-link"
											breakClassName="page-item"
											breakLinkClassName="page-link"
											renderOnZeroPageCount={null}
											activeClassName="active"
											onPageChange={handlePageClick}
											pageRangeDisplayed={3}
											pageCount={props.totalPage}
											//initialPage={active - 1}
											forcePage={pageNo - 1}
										/>
									) : null}
								</div>

							</Card.Body>
						</Card>
					</Col>
				</div>
				<Modal
					className="fade bd-example-modal-sm"
					size="lg"
					show={addModal}
				>
					<div className="add-user-modal-form-view">
						<div className="modal-title-view">
							<h3>{action == 1 ? "Add Group" : "Update Group"}</h3>
							<p>Manage Company/Organization Information such as name, type, location address and contacts.
							</p>
						</div>
						<div className="basic-form">
							<form onSubmit={onSubmit}>
								<div className="row">

									<div className="form-group form-group-modal-custom mb-3 col-4">
										<label className="mb-1">
											Id
										</label>
										<input className="form-control" value={groupId} onChange={(e) => setGroupId(e.target.value)} />
										{errors.gid && <div className="text-danger fs-12">{errors.gid}</div>}
									</div>
									<div className="form-group form-group-modal-custom mb-3 col-4">
										<label className="mb-1">
											Name
										</label>
										<input className="form-control" value={description} onChange={(e) => setDescription(e.target.value)} />
										{errors.desc && <div className="text-danger fs-12">{errors.desc}</div>}
									</div>
									<div className="form-group form-group-modal-custom mb-3 col-4">
										<label className="mb-1">
											Status
										</label>
										<div className="select-drop-down">
											<span><i class="fa fa-angle-down"></i></span>
											<select
												defaultValue={"option"}
												class="form-control select-control"
												value={status}
												onChange={(e) => setStatus(e.target.value)}>
												<option></option>
												<option value="1">Active</option>
												<option value="0">Inactive</option>
											</select>
										</div>
										{errors.status && <div className="text-danger fs-12">{errors.status}</div>}
									</div>
									<div className="client-title-view">
										<h3>Group Option</h3>
									</div>

									<div className="form-group form-group-modal-custom form-group-textarea-custom mb-3 col-13">
										<label className="mb-1">
											Description
										</label>
										<textarea className="form-control" value={note} onChange={(e) => setNote(e.target.value)} rows={4} cols={40} />
										{errors.note && <div className="text-danger fs-12">{errors.note}</div>}
									</div>

								</div>
								<div className="modal-button-group mt-4">
									<button type="button" onClick={() => setAddModal(false)} className="btn form-cencel-btn">
										Cancel
									</button>
									<button type="submit" className="btn btn-primary role-form-btn">
										{action == 1 ? "Save" : "Update"}
									</button>
								</div>
							</form>
						</div>

					</div>
				</Modal>

			</div>
		</>
	)
}

const mapStateToProps = (state) => {
	return {
		screenVisible: state.team.screenVisible,
		showLoading: state.employee.showLoading,
		roleLoading: state.role.showLoading,
		activeRoleList: state.role.activerRoleList,
		employeeList: state.employee.employeeList,
		companyId: state.auth.companyId,
		teamList: state.team.teamList,
		totalPage: state.team.totalPage,
		totalRecord: state.team.totalRecord,
		activeTeamList: state.team.teamListById,
		keeperList: state.employee.keeperList,
	};
};
export default connect(mapStateToProps)(Group);