import React, { useContext, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import swal from "sweetalert";
import Spinner from 'react-bootstrap/Spinner';
import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";
import {
	Row,
	Col,
	Card,
	Table,
	Modal,
	Button
} from "react-bootstrap";

import {
	loadingRoleAction, getRoleList, updateRoleStatusData, getRolePermissionsData, removeRoleData
} from '../../../store/actions/RoleActions';
import { globalLoadingAction } from '../../../store/actions/GlobalAction';


const RoleManagement = (props) => {

	const dispatch = useDispatch();
	const history = useHistory();

	const [viewModal, setViewModal] = useState(false);
	const [currentPermission, setCurrentPermission] = useState([]);
	const [roleTitle, setRoleTitle] = useState('');

	useEffect(() => {
		dispatch(globalLoadingAction(true));
		dispatch(getRoleList(props.companyId, props.history));
		dispatch(getRolePermissionsData(props.history));
	}, []);

	const onStatusHandler = (item) => {
		dispatch(globalLoadingAction(true));
		dispatch(updateRoleStatusData(item, props.history));
	}

	const onDeleteHandler = (id) => {
		swal({
			title: "Are you sure?",
			text:
				"You want to remove this item !",
			icon: "warning",
			buttons: true,
			dangerMode: true,
		}).then((willDelete) => {
			if (willDelete) {
				dispatch(removeRoleData(id, props.history));
			}
		})
	}

	const onRoleViewHandler = (data) => {
		setCurrentPermission(data.permission);
		setRoleTitle(data.role_name);
		setViewModal(true);
	}

	const onViewCancelHandler = () => {
		setCurrentPermission([])
		setViewModal(false);
	}

	const onCurrentStatus = (id) => {
		let status = null;
		const index = currentPermission.findIndex((val) => val === id);
		if (index >= 0) {
			status = true;
		} else {
			status = false;
		}
		return status;
	}

	return (
		<>
			<div className="row">
				<div className="breadcrumb-container">
					<ul>
						<li>
							<Link to={"#"}>System Setting</Link>
						</li>
						<li><i className="fa fa-angle-right"></i></li>
						<li>
							<Link to={"#"}>Role Management</Link>
						</li>
					</ul>
				</div>
				<div className="title-body-container">
					<div className="title-body">
						<h3>Role Management</h3>
						<p>Manage Company/Organization Information such as name, type, location address and contacts.</p>
					</div>
				</div>
				<div className="account-setting-content">
					<Col lg={12}>
						<Card>
							<Card.Header>
								<div className="add-role-button-view">
									<Link
										to={"/add-role"}
										className="btn btn-success"
									>
										Add Role
									</Link>
								</div>
							</Card.Header>
							<Card.Body>
								<Table responsive>
									<thead>
										<tr>
											<th>
												<strong>Role Name</strong>
											</th>
											<th>
												<strong>Type</strong>
											</th>
											<th>
												<strong>Description</strong>
											</th>
											<th>
												<strong>Status</strong>
											</th>
											<th>
												<strong>Actions</strong>
											</th>
										</tr>
									</thead>
									<tbody>
										{
											props.roleList.map((item, index) => {
												return (
													<tr key={index}>
														<td>{item.role_name}</td>
														<td>{item.is_default == 1 ? "System" : "User"}</td>
														<td>{item.description}</td>
														<td>
															<Button
																className="btn-xs"
																variant={item.status == 1 ? "success" : "danger"}
																onClick={() => onStatusHandler(item)}
																disabled={item.is_default == 1 ? true : false}
															>
																{item.status == 1 ? "Active" : "Inactive"}
															</Button>

														</td>
														<td>
															<div className="d-flex">

																<Link
																	id={'copyRole' + index}
																	to={{
																		pathname: '/copy-role',
																		state: item
																	}}
																	className="btn btn-warning shadow btn-xs sharp me-1"
																>
																	<i className="fas fa-clone"></i>
																</Link>

																<button
																	id={'viewRole' + index}
																	className="btn btn-info shadow btn-xs sharp me-1"
																	// onClick={() => setViewModal(true)}
																	onClick={() => onRoleViewHandler(item)}
																>
																	<i className="fa fa-eye"></i>
																</button>
																{item.is_default == 1 ? null :
																	<>
																		<Link
																			id={'updateRole' + index}
																			to={{
																				pathname: '/update-role',
																				state: item
																			}}
																			className="btn btn-warning shadow btn-xs sharp me-1"
																		>
																			<i className="fas fa-pencil-alt"></i>
																		</Link>

																		<Button
																			id={'deleteRole' + index}
																			className="btn btn-danger shadow btn-xs sharp"
																			onClick={() => onDeleteHandler(item._id)}
																		>
																			<i className="fa fa-trash"></i>
																		</Button>
																	</>
																}

															</div>
														</td>
													</tr>
												);
											})
										}
									</tbody>
								</Table>
							</Card.Body>
						</Card>
					</Col>
				</div>

				<Modal
					className="fade bd-example-modal-sm"
					size="lg"
					show={viewModal}
				>
					<div className="add-user-modal-form-view">
						<div className="modal-title-view">
							<h3>{roleTitle}</h3>
							{/* <p>Add new Clients Details</p> */}
						</div>
						<div className="view-role-container">

							{
								props.permissionsList.map((item, index) => {
									if (item.parent_id == 0) {
										return (
											<>
												{item.menu_id == 9 ? null :
													<div className="role-menu-item">
														<div className="form-group mt-3">
															<div key={index} className="form-check">
																<label className="form-check-label">
																	<input
																		type="checkbox"
																		defaultChecked={onCurrentStatus(item.menu_id)}
																		className="form-check-input read-form-check-input"
																		id={`check${item._id}`}
																	/>
																	{item.title}
																</label>
															</div>
														</div>
														{
															props.permissionsList.map((data, i) => {
																if (data.parent_id == item.menu_id) {
																	return (
																		<div className="form-group form-check-inline sub-checkMark">
																			<div key={index} className="form-check">
																				<label className="form-check-label">
																					<input
																						type="checkbox"
																						defaultChecked={onCurrentStatus(data.menu_id)}
																						className="form-check-input read-form-check-input"
																						id={`check${data._id}`}
																					/>
																					{data.title}
																				</label>
																			</div>

																			{
																				props.permissionsList.map((subData, j) => {
																					if (subData.parent_id == data.menu_id) {
																						return (

																							<label className="form-check-label sub-checkMark">
																								<input
																									type="checkbox"
																									defaultChecked={onCurrentStatus(subData.menu_id)}
																									className="form-check-input read-form-check-input"
																									id={`check${subData._id}`}
																								/>
																								{subData.title}
																							</label>

																						)
																					}
																				})
																			}

																		</div>
																	)
																}
															})
														}



													</div>
												}
											</>
										);
									}
								})
							}

							<div className="modal-button-group mt-4">
								<button type="button" onClick={() => onViewCancelHandler()} className="btn form-cencel-btn">
									Close
								</button>
							</div>

						</div>


					</div>
				</Modal>

			</div>
			{
				props.roleList.map((item, index) => {
					return (

						<>
							<ReactTooltip
								anchorId={'viewRole' + index}
								place="top"
								content="View role"
							/>
							<ReactTooltip
								anchorId={'copyRole' + index}
								place="top"
								content="Copy role"
							/>
							<ReactTooltip
								anchorId={'updateRole' + index}
								place="top"
								content="Update role"
							/>
							<ReactTooltip
								anchorId={'deleteRole' + index}
								place="top"
								content="Delete role"
							/>
						</>

					);
				})
			}
		</>

	)
}
const mapStateToProps = (state) => {
	return {
		showLoading: state.role.showLoading,
		roleList: state.role.roleList,
		companyId: state.auth.companyId,
		permissionsList: state.role.permissionsList,
	};
};
export default connect(mapStateToProps)(RoleManagement);