import axios from 'axios';
import GlobalConfigs from "../configs/GlobalConfigs";
import * as Apis from '../apis/Apis';

export function addClients(tax_id, id_type, other_id_type, tax_no, client_alt, username, organization_id, organization_name, organization_address, address1, address2, country, state, city, zipcode, email, mobile, status, password, country_code, approver_name, approver_email, approver_country_code, approver_mobile_no, reviewer_name, reviewer_email, reviewer_country_code, reviewer_mobile_no, billing_address,type) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;
    let company_id = JSON.parse(getData).company_id;
    const postData = {
        company_id,
        tax_id,
        id_type,
        other_id_type,
        tax_no,
        client_alt,
        username,
        organization_id,
        organization_name,
        organization_address,
        address1,
        address2,
        country,
        state,
        city,
        zipcode,
        email,
        mobile,
        status,
        password,
        country_code,
        approver_name,
        approver_email,
        approver_country_code,
        approver_mobile_no,
        reviewer_name,
        reviewer_email,
        reviewer_country_code,
        reviewer_mobile_no,
        billing_address,
        type
    };
    console.log('postData....',postData);
    return axios({
        url: GlobalConfigs.API_URL + Apis.ADD_CLIENTS_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function updateClients(user_id, client_id, tax_id, id_type, other_id_type, tax_no, client_alt, username,organization_id, organization_name, organization_address, address1, address2, country, state, city, zipcode, email, mobile, status, password, country_code, approver_name, approver_email, approver_country_code, approver_mobile_no, reviewer_name, reviewer_email, reviewer_country_code, reviewer_mobile_no, billing_address,type) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        user_id,
        client_id,
        tax_id,
        id_type,
        other_id_type,
        tax_no,
        client_alt,
        username,
        organization_id,
        organization_name,
        organization_address,
        address1,
        address2,
        country,
        state,
        city,
        zipcode,
        email,
        mobile,
        status,
        password,
        country_code,
        approver_name,
        approver_email,
        approver_country_code,
        approver_mobile_no,
        reviewer_name,
        reviewer_email,
        reviewer_country_code,
        reviewer_mobile_no,
        billing_address,
        type
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.UPDATE_CLIENTS_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function getClients(searchString, orderByField, orderByFieldValue, page_no, per_page) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;
    let company_id = JSON.parse(getData).company_id;

    const postData = {
        company_id,
        searchString,
        orderByField,
        orderByFieldValue,
        page_no,
        per_page,
    };

    return axios({
        url: GlobalConfigs.API_URL + Apis.GET_CLIENTS_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function updateClientStatus(client_id, status) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        client_id,
        status
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.UPDATE_CLIENTS_STATUS_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function removeClient(client_id) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        client_id,
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.REMOVE_CLIENTS_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}

export function removeMultipleClient(userIds) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        userIds,
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.REMOVE_MULTIPLE_EMPLOYEE_RECORD_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function downloadClientRecord(userIds) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        userIds,
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.DOWNLOAD_CLIENTS_RECORD_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}
