import {
    LOADING_TIME_CARD,
    GET_TIME_CARD_LIST,
    GET_TIME_CARD_SINGLE_DETAILS,
    GET_MANUAL_TIMESHEET,
} from '../constants';

const initialState = {
    list: [],
    details: {},
    totalPage: 0,
    totalRecord: 0,
    screenVisible: false,
    showLoading: false,
};

export function TimeCardReducer(state = initialState, action) {

    if (action.type === GET_TIME_CARD_LIST) {
        return {
            ...state,
            totalPage: action.payload.total_page,
            totalRecord: action.payload.total_record,
            list: action.payload.data,
            screenVisible: true,
            showLoading: false,
        };
    }

    if (action.type === GET_TIME_CARD_SINGLE_DETAILS) {
        return {
            ...state,
            details: action.payload,
        };
    }
    
    if (action.type === GET_MANUAL_TIMESHEET) {
        return {
            ...state,
            totalPage: action.payload.total_page,
            totalRecord: action.payload.total_record,
            list: action.payload.data,
            screenVisible: true,
            showLoading: false,
        };
    }

    if (action.type === LOADING_TIME_CARD) {
        return {
            ...state,
            showLoading: action.payload,
        };
    }
    return state;
}


