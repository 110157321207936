import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";
import { Dropdown } from 'react-bootstrap';

//images
import user from './../../../images/user.jpg';



const Settings = () => {
	
	useEffect(() => {
		
	}, []);
	
	return (
		<>
			<div className="row">
				<div className="title-body">
					<h3>Settings</h3>
					<p>Manage Company/Organization Information such as name, type, location address and contacts.</p>
				</div>
				<div className="account-setting-content">
					<div className="row">
						<div className="col-xl-11 col-md-12">
							<div className="row">
							
								<div className="col-xl-4 col-md-6">
									<div className="account-setting-card">
										<h4>Time Reporting</h4>
										<div className="account-setting-option">
											<div className="account-setting-item">
												<div><p>User Settings</p></div>
												<div></div>
											</div>
											<div className="account-setting-item">
												<div><p>Report Settings</p></div>
												<div></div>
											</div>
											<div className="account-setting-item">
												<div><p>Approvals</p></div>
												<div></div>
											</div>
											<div className="account-setting-item">
												<div><p>Time Cupture</p></div>
												<div></div>
											</div>
										</div>
									</div>
								</div>

								<div className="col-xl-4 col-md-6">
									<div className="account-setting-card">
										<h4>Billing & Invoicing</h4>
										<div className="account-setting-option">
											<div className="account-setting-item">
												<div><p>User Settings</p></div>
												<div></div>
											</div>
											<div className="account-setting-item">
												<div><p>Invoices/Statements</p></div>
												<div></div>
											</div>
											<div className="account-setting-item">
												<div><p>History</p></div>
												<div></div>
											</div>
											
										</div>
									</div>
								</div>

								<div className="col-xl-4 col-md-6">
									<div className="account-setting-card">
										<h4>Expenses</h4>
										<div className="account-setting-option">
											<div className="account-setting-item">
												<div><p>User Settings</p></div>
												<div></div>
											</div>
											<div className="account-setting-item">
												<div><p>Report Settings</p></div>
												<div></div>
											</div>
											<div className="account-setting-item">
												<div><p>Control Data</p></div>
												<div></div>
											</div>
											
										</div>
									</div>
								</div>

								<div className="col-xl-4 col-md-6 mt-4">
									<div className="account-setting-card mt-2">
										<h4>Performance</h4>
										<div className="account-setting-option">
											<div className="account-setting-item">
												<div><p>User Settings</p></div>
												<div></div>
											</div>
											<div className="account-setting-item">
												<div><p>Report Settings</p></div>
												<div></div>
											</div>
											
											
										</div>
									</div>
								</div>

							</div>
						</div>
						<div className="col-xl-1 col-md-12">
						</div>
					</div>
				</div>

			</div>
		</>
	)
}
export default Settings;