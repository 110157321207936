import {
    LOGIN_FAILED_ACTION,
    LOGOUT_ACTION,
    LOGIN_CONFIRMED_ACTION,
    LOADING_TOGGLE_ACTION,
    SIGNUP_FAILED_ACTION,
    SET_OTP_ACTION,
    SIGNUP_CONFIRMED_ACTION,
    SETUP_USER_DATA,
    SETUP_COMPANY_DATA,
    UPDATE_PROFILE_DATA,
    GET_PERMISSION_MENU_DATA,
    GET_PROFILE_AND_COMPANY_DETAILS_ACTION
} from '../constants';

const initialState = {
    email: '',
    token: '',
    country: '',
    countryCode: '',
    otp: '',
    firstName: '',
    lastName: '',
    phoneNo: '',
    profilePic: '',
    role: '',
    rate: '',
    regularHour: '',
    userType: '',
    empType: '',
    timeKepper: '',
    userId: '',
    systemAdmin: false,
    permissionsList: [],
    quickLinks: [],
    companyId: '',
    company_details: {},
    showLoading: false,
};

export function AuthReducer(state = initialState, action) {

    if (action.type === SIGNUP_CONFIRMED_ACTION) {
        return {
            ...state,
            token: action.payload,
            showLoading: false,
        };
    }

    if (action.type === LOGIN_CONFIRMED_ACTION) {
        return {
            ...state,
            userId: action.payload._id,
            email: action.payload.email,
            token: action.payload.token,
            userType: action.payload.user_type,
            country: action.payload.country,
            profilePic: action.payload.profile_pic,
            rate: action.payload.rate,
            regularHour: action.payload.regular_hour,
            permissionsList: action.payload.permission,
            quickLinks: action.payload.quick_links,
            systemAdmin: action.payload.system_admin,
            timeKepper: action.payload.time_kepper,
            empType:action.payload.employee_type,
            showLoading: false,
        };
    }

    if (action.type === GET_PERMISSION_MENU_DATA) {
        return {
            ...state,
            permissionsList: action.payload.permission,
            systemAdmin: action.payload.system_admin,
        };
    }

    if (action.type === LOGOUT_ACTION) {
        return {
            ...state,
            id: '',
            email: '',
            token: '',
            country: '',
            otp: '',
            firstName: '',
            lastName: '',
            phoneNo: '',
            role: '',
            rate: '',
            regularHour: '',
            profilePic: '',
            companyId: '',
            company_details: {},
        };
    }

    if (
        action.type === SIGNUP_FAILED_ACTION ||
        action.type === LOGIN_FAILED_ACTION
    ) {
        return {
            ...state,
            errorMessage: action.payload,
            successMessage: '',
            showLoading: false,
        };
    }
    if (action.type === SET_OTP_ACTION) {
        return {
            ...state,
            email: action.payload.email,
            country: action.payload.country,
            otp: action.payload.otp,
            showLoading: false,
        };
    }
    if (action.type === GET_PROFILE_AND_COMPANY_DETAILS_ACTION) {
        return {
            ...state,
            email: action.payload.email,
            country: action.payload.country,
            countryCode: action.payload.country_code,
            firstName: action.payload.first_name,
            lastName: action.payload.last_name,
            phoneNo: action.payload.phone_no,
            role: action.payload.designation,
            profilePic: action.payload.profile_pic,
            companyId: action.payload.company_id,
            company_details: action.payload.company_details,
            showLoading: false,
        };
    }
    if (action.type === SETUP_USER_DATA) {
        return {
            ...state,
            id: action.payload.id,
            firstName: action.payload.first_name,
            lastName: action.payload.last_name,
            phoneNo: action.payload.phone_no,
            role: action.payload.role,
            showLoading: false,
        };
    }
    if (action.type === UPDATE_PROFILE_DATA) {
        return {
            ...state,
            country: action.payload.country,
            firstName: action.payload.first_name,
            lastName: action.payload.last_name,
            phoneNo: action.payload.phone_no,
            profilePic: action.payload.profile_pic,
            rate: action.payload.rate,
            regularHour: action.payload.regular_hour,
            showLoading: false,
        };
    }
    if (action.type === SETUP_COMPANY_DATA) {
        return {
            ...state,
            company_details: action.payload,
            showLoading: false,
        };
    }
    if (action.type === LOADING_TOGGLE_ACTION) {
        return {
            ...state,
            showLoading: action.payload,
        };
    }
    return state;
}


