import swal from "sweetalert";
import {
    addTeam,
    getTeam,
    updateTeamStatus,
    removeTeam,
    updateTeam,
    getTeamById,
    getActiveTeam,
    downloadTeamRecord,
    removeMultipleTeam
} from '../../services/TeamService';

import {
    LOADING_TEAM,
    ADD_TEAM,
    GET_TEAM_LIST,
    UPDATE_TEAM_STATUS,
    REMOVE_TEAM_RECORD,
    GET_TEAM_LIST_BY_ID,
    GET_ACTIVE_TEAM_LIST
} from '../constants';

import * as utility from '../../utility/Utility';
import { globalLoadingAction } from './GlobalAction';


export function addTeamData(groupId, description, note, status, perPage, history) {
    return (dispatch) => {
        addTeam(groupId, description, note, status)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(getTeamList('', '', '', 1, perPage, history));
                    utility.showSuccess(response.data.msg);
                } else {
                    utility.showError(response.data.msg);
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                //utility.showError(response.data.msg);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function updateTeamData(id, groupId, description, status, note, searchString, orderByField, orderByFieldValue, page_no, per_page, history) {
    return (dispatch) => {
        updateTeam(id, groupId, description, status, note)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(getTeamList(searchString, orderByField, orderByFieldValue, page_no, per_page, history));
                    utility.showSuccess(response.data.msg);
                } else {
                    utility.showError(response.data.msg);
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                //utility.showError(response.data.msg);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function getTeamList(searchString, orderByField, orderByFieldValue, page_no, per_page, history) {
    return (dispatch) => {
        getTeam(searchString, orderByField, orderByFieldValue, page_no, per_page)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(getTeamAction(response.data));
                } else {
                    utility.showError(response.data.msg);
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                //utility.showError(response.data.msg);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function getTeamByIdList(id, history) {
    return (dispatch) => {
        getTeamById(id)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(getTeamByIdAction(response.data.data.employee_ids));
                    //utility.showSuccess(response.data.msg);
                } else {
                    utility.showError(response.data.msg);
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                //utility.showError(response.data.msg);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function getActiveTeamList(history) {
    return (dispatch) => {
        getActiveTeam()
            .then((response) => {
                console.log('response...',response.data);
                if (response.data.status == 200) {
                    dispatch(getActiveTeamAction(response.data.data));
                    //utility.showSuccess(response.data.msg);
                } else {
                    utility.showError(response.data.msg);
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                //utility.showError(response.data.msg);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function updateTeamStatusData(item, history) {
    return (dispatch) => {
        updateTeamStatus(item._id, item.status === 1 ? 0 : 1)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(updateTeamStatusAction(item));
                    utility.showSuccess(response.data.msg);
                } else {
                    utility.showError(response.data.msg);
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                //utility.showError(response.data.msg);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function removeTeamData(id, searchString, orderByField, orderByFieldValue, page_no, per_page, history) {
    return (dispatch) => {
        removeTeam(id)
            .then((response) => {
                if (response.data.status == 200) {
                    //dispatch(removeTeamAction(id));
                    swal(response.data.msg, { icon: "success", });
                    dispatch(getTeamList(searchString, orderByField, orderByFieldValue, page_no, per_page));
                } else {
                    swal(response.data.msg, "error");
                }
            })
            .catch((error) => {
                //console.log(error);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function removeMultipleTeamData(groupIds, searchString, orderByField, orderByFieldValue, page_no, per_page, history) {
    return (dispatch) => {
        removeMultipleTeam(groupIds)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(getTeamList(searchString, orderByField, orderByFieldValue, page_no, per_page, history));
                    utility.showSuccess(response.data.msg);
                } else {
                    dispatch(globalLoadingAction(false));
                    utility.showError(response.data.msg);
                }
            })
            .catch((error) => {
                //console.log(error);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function downloadTeamData(userIds, history) {
    return (dispatch) => {
        downloadTeamRecord(userIds)
            .then((response) => {
                if (response.data.status == 200) {
                    const outputFilename = `${Date.now()}.xls`;
                    const link = document.createElement('a');
                    link.href = response.data.data;
                    link.setAttribute('download', outputFilename);
                    document.body.appendChild(link);
                    link.click();
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                dispatch(globalLoadingAction(false));
            });
    };
}


export function getTeamAction(data) {
    return {
        type: GET_TEAM_LIST,
        payload: data,
    };
}
export function getTeamByIdAction(data) {
    return {
        type: GET_TEAM_LIST_BY_ID,
        payload: data,
    };
}
export function getActiveTeamAction(data) {
    return {
        type: GET_ACTIVE_TEAM_LIST,
        payload: data,
    };
}
export function updateTeamStatusAction(data) {
    return {
        type: UPDATE_TEAM_STATUS,
        payload: data,
    };
}
export function removeTeamAction(id) {
    return {
        type: REMOVE_TEAM_RECORD,
        payload: id,
    };
}
export function loadingTeamAction(status) {
    return {
        type: LOADING_TEAM,
        payload: status,
    };
}
