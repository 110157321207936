import {
    GLOBAL_LOADING,
} from '../constants';

export function globalLoadingAction(status) {
    return {
        type: GLOBAL_LOADING,
        payload: status,
    };
}
