import axios from 'axios';
import GlobalConfigs from "../configs/GlobalConfigs";
import * as Apis from '../apis/Apis';


export function updateProfile(country, country_code, first_name, last_name, phone_no, designation, emp_rate, regular_hour, profile_pic) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;
    let user_id = JSON.parse(getData)._id;

    const formData = new FormData();
    formData.append("user_id", user_id);
    formData.append("country", country);
    formData.append("country_code", country_code);
    formData.append("first_name", first_name);
    formData.append("last_name", last_name);
    formData.append("phone_no", phone_no);
    formData.append("designation", designation);
    formData.append("emp_rate", emp_rate);
    formData.append("regular_hour", regular_hour);
    formData.append("profile_pic", profile_pic);

    return axios({
        url: GlobalConfigs.API_URL + Apis.UPDATE_PROFILE_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: formData,
    });

}

