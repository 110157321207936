import {
    LOADING_SUBSCRIBTION,
    START_TIME_ACTION,
    STOP_TIME_ACTION,
    OFFSET_TIME_ACTION,
    ADD_TIME_CAPTURE,
    UPDATE_TIME_CAPTURE,
    GET_TIME_CAPTURE,
    GET_ASSIGNED_TASK_ACTION,
    SET_SINGLE_DATA_OBJECT,
    GET_PROJECT_DETAILS_BY_ID,
    GET_TRACKING_DETAILS_ACTION,
    GET_APPROVED_TRACKING_FOR_APPROVER_ACTION,
    GET_REJECT_TRACKING_FOR_APPROVER_ACTION,
    GET_PENDING_TRACKING_FOR_APPROVER_ACTION,
    GET_PROJECT_APPROVER_TRACKING_DETAILS_ACTION
} from '../constants';

const initialState = {
    list: [],
    totalPage: 0,
    totalRecord: 0,
    taskList: [],
    taskPage: 0,
    taskRecord: 0,
    timeDetails: {},
    totalTrackPage: 0,
    totalTrackRecord: 0,
    timeTrackList: [],
    insertedId: '',
    approvePendingList: [],
    approverPendingPage: 0,
    approverPendingRecord: 0,
    approverApprovedList: [],
    approverApprovedPage: 0,
    approverApprovedRecord: 0,
    approverRejectList: [],
    approverRejectPage: 0,
    approverRejectRecord: 0,
    approverCardDetails: null,
    initialOffset: 0,
    offTime: false,
    isRunning: false,
    acknowledged: false,
    showLoading: false,
    screenVisible: false,
};

export function TimeCaptureReducer(state = initialState, action) {

    if (action.type === START_TIME_ACTION) {
        return {
            ...state,
            isRunning: action.payload,
        };
    }

    if (action.type === STOP_TIME_ACTION) {
        return {
            ...state,
            initialOffset: action.payload,
        };
    }

    if (action.type === OFFSET_TIME_ACTION) {
        return {
            ...state,
            initialOffset: state.initialOffset + action.payload,
        };
    }
    if (action.type === SET_SINGLE_DATA_OBJECT) {
        return {
            ...state,
            timeDetails: action.payload,
        };
    }
    if (action.type === ADD_TIME_CAPTURE) {
        return {
            ...state,
            acknowledged: action.payload.data.acknowledged,
            insertedId: action.payload.data.insertedId,
            showLoading: false,
        };
    }

    if (action.type === UPDATE_TIME_CAPTURE) {
        return {
            ...state,
            acknowledged: action.payload.acknowledged,
            insertedId: action.payload.insertedId,
            showLoading: false,
        };
    }

    if (action.type === GET_ASSIGNED_TASK_ACTION) {
        return {
            ...state,
            taskList: action.payload.data,
            taskPage: action.payload.total_page,
            taskRecord: action.payload.total_record,
            showLoading: false,
            screenVisible: true,
        };
    }

    if (action.type === GET_TIME_CAPTURE) {
        return {
            ...state,
            list: action.payload.data,
            totalPage: action.payload.total_page,
            totalRecord: action.payload.total_record,
            showLoading: false,
            screenVisible: true
        };
    }

    if (action.type === GET_TRACKING_DETAILS_ACTION) {
        return {
            ...state,
            timeTrackList: action.payload.data,
            totalTrackPage: action.payload.total_page,
            totalTrackRecord: action.payload.total_record,
            screenVisible: true,
        };
    }
    if (action.type === GET_PENDING_TRACKING_FOR_APPROVER_ACTION) {
        return {
            ...state,
            approvePendingList: action.payload.data,
            approverPendingPage: action.payload.total_page,
            approverPendingRecord: action.payload.total_record,
            screenVisible: true,
        };
    }
    if (action.type === GET_APPROVED_TRACKING_FOR_APPROVER_ACTION) {
        return {
            ...state,
            approverApprovedList: action.payload.data,
            approverApprovedPage: action.payload.total_page,
            approverApprovedRecord: action.payload.total_record,
            screenVisible: true,
        };
    }
    if (action.type === GET_REJECT_TRACKING_FOR_APPROVER_ACTION) {
        return {
            ...state,
            approverRejectList: action.payload.data,
            approverRejectPage: action.payload.total_page,
            approverRejectRecord: action.payload.total_record,
            screenVisible: true,
        };
    }
    if (action.type === GET_PROJECT_APPROVER_TRACKING_DETAILS_ACTION) {
        return {
            ...state,
            approverCardDetails: action.payload,
        };
    }
    if (action.type === LOADING_SUBSCRIBTION) {
        return {
            ...state,
            showLoading: action.payload,
        };
    }
    return state;
}


