import swal from "sweetalert";
import {
    addLocation,
    getLocation,
    updateLocationStatus,
    removeLocation,
    updateLocation,
    downloadLocation,
    addLocationType,
    getLocationType,
    removeMultipleLocation
} from '../../services/LocationService';

import {
    LOADING_LOCATION,
    GET_LOCATION_LIST,
    UPDATE_LOCATION_STATUS,
    GET_LOCATION_TYPE_LIST,
} from '../constants';

import * as utility from '../../utility/Utility';
import { globalLoadingAction } from './GlobalAction';


export function addLocationData(locId, location_type, address, address_two, country, country_id, state, state_id, city, postal_code, status, perPage, history) {
    return (dispatch) => {
        addLocation(locId, location_type, address, address_two, country, country_id, state, state_id, city, postal_code, status)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(addLocationTypeData(location_type, history));
                    dispatch(getLocationList('', '', '', 1, perPage, history));
                    utility.showSuccess(response.data.msg);
                } else {
                    utility.showError(response.data.msg);
                    dispatch(globalLoadingAction(false));
                }
            })
            .catch((error) => {
                //console.log(error);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function addLocationTypeData(type, history) {
    return (dispatch) => {
        addLocationType(type)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(getLocationTypeData(history));
                }
            })
            .catch((error) => {
                //console.log(error);
            });
    };
}

export function getLocationTypeData(history) {
    return (dispatch) => {
        getLocationType()
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(getLocationTypeAction(response.data));
                }
            })
            .catch((error) => {
                //console.log(error);
            });
    };
}

export function updateLocationData(id, locId, location_type, address, address_two, country, country_id, state, state_id, city, postal_code, status, searchString, orderByField, orderByFieldValue, page_no, per_page, history) {
    return (dispatch) => {
        updateLocation(id, locId, location_type, address, address_two, country, country_id, state, state_id, city, postal_code, status)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(getLocationList(searchString, orderByField, orderByFieldValue, page_no, per_page, history));
                    utility.showSuccess(response.data.msg);
                } else {
                    utility.showError(response.data.msg);
                    dispatch(globalLoadingAction(false));
                }
            })
            .catch((error) => {
                //console.log(error);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function getLocationList(searchString, orderByField, orderByFieldValue, page_no, per_page, history) {
    return (dispatch) => {
        getLocation(searchString, orderByField, orderByFieldValue, page_no, per_page)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(getLocationAction(response.data));
                } else {
                    utility.showError(response.data.msg);
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function updateLocationStatusData(item, history) {
    return (dispatch) => {
        updateLocationStatus(item._id, item.status === 1 ? 0 : 1)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(updateLocationStatusAction(item));
                    utility.showSuccess(response.data.msg);
                } else {
                    utility.showError(response.data.msg);
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                //utility.showError(response.data.msg);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function removeLocationData(id, searchString, orderByField, orderByFieldValue, page_no, per_page, history) {
    return (dispatch) => {
        removeLocation(id, 0)
            .then((response) => {
                if (response.data.status == 200) {
                    swal(response.data.msg, { icon: "success", });
                    dispatch(getLocationList(searchString, orderByField, orderByFieldValue, page_no, per_page, history));
                } else {
                    swal(response.data.msg, "error");
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function removeMultipleLocationData(locationIds, searchString, orderByField, orderByFieldValue, page_no, per_page, history) {
    return (dispatch) => {
        removeMultipleLocation(locationIds)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(getLocationList(searchString, orderByField, orderByFieldValue, page_no, per_page, history));
                    utility.showSuccess(response.data.msg);
                } else {
                    dispatch(globalLoadingAction(false));
                    utility.showError(response.data.msg);
                }
            })
            .catch((error) => {
                //console.log(error);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function downloadLocationData(locationIds, history) {
    return (dispatch) => {
        downloadLocation(locationIds)
            .then((response) => {
                if (response.data.status == 200) {
                    const outputFilename = `${Date.now()}.xls`;
                    const link = document.createElement('a');
                    link.href = response.data.data;
                    link.setAttribute('download', outputFilename);
                    document.body.appendChild(link);
                    link.click();
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                dispatch(globalLoadingAction(false));
            });
    };
}


export function getLocationAction(data) {
    return {
        type: GET_LOCATION_LIST,
        payload: data,
    };
}
export function getLocationTypeAction(data) {
    return {
        type: GET_LOCATION_TYPE_LIST,
        payload: data,
    };
}
export function updateLocationStatusAction(data) {
    return {
        type: UPDATE_LOCATION_STATUS,
        payload: data,
    };
}
export function loadingLocationAction(status) {
    return {
        type: LOADING_LOCATION,
        payload: status,
    };
}
