import {
    LOADING_PROJECT,
    ADD_PROJECT,
    GET_PROJECT,
    GET_PROJECT_DETAILS,
    UPDATE_PROJECT_STATUS,
    REMOVE_PROJECT_DETAILS,
    GET_PROJECT_ACTIVITIES,
    UPDATE_PROJECT_ACTIVITIES_STATUS,
    REMOVE_PROJECT_ACTIVITIES_DETAILS,
    GET_TASK_LIST,
    UPDATE_TASK_STATUS,
    GET_PROJECT_ACTIVITIES_CHECK_EXISTANCE,
    REMOVE_TASK_LIST,
    GET_TASK_DETAILS_BY_ID,
    GET_PROJECT_DETAILS_BY_ID
} from '../constants';

const initialState = {
    projectList: [],
    activitiesList: [],
    taskList: [],
    taskDetails: {},
    projectDetails: {},
    totalProjectPage: 0,
    totalProjectRecord: 0,
    totalActivitiesPage: 0,
    totalActivitiesRecord: 0,
    totalTaskPage: 0,
    totalTaskRecord: 0,
    hasData: true,
    screenVisible: false,
    showLoading: false,
    isProject: true
};

export function ProjectReducer(state = initialState, action) {

    if (action.type === ADD_PROJECT) {
        return {
            ...state,
            //projectList: action.payload,
            showLoading: false,
        };
    }

    if (action.type === GET_PROJECT) {
        return {
            ...state,
            totalProjectPage: action.payload.total_page,
            totalProjectRecord: action.payload.total_record,
            projectList: action.payload.data,
            isProject: action.payload.data.length > 0 ? true : false,
            screenVisible: true,
            showLoading: false,
        };
    }

    if (action.type === GET_PROJECT_DETAILS) {
        return {
            ...state,
            projectDetails: action.payload,
            showLoading: false,
        };
    }

    if (action.type === GET_PROJECT_DETAILS_BY_ID) {
        return {
            ...state,
            projectDetails: action.payload,
            showLoading: false,
        };
    }

    if (action.type === UPDATE_PROJECT_STATUS) {
        const list = [...state.projectList];
        const index = list.findIndex((item) => item._id === action.payload._id);
        list[index].status = action.payload.status === 1 ? 0 : 1;
        return {
            ...state,
            projectList: list,
            showLoading: false,
        };
    }

    if (action.type === REMOVE_PROJECT_DETAILS) {
        const list = [...state.projectList];
        const index = list.findIndex((item) => item._id === action.payload);
        list.splice(index, 1);
        return {
            ...state,
            projectList: list,
            showLoading: false,
        };
    }

    if (action.type === GET_PROJECT_ACTIVITIES) {
        return {
            ...state,
            totalActivitiesPage: action.payload.total_page,
            totalActivitiesRecord: action.payload.total_record,
            activitiesList: action.payload.data,
            screenVisible: true,
            showLoading: false,
        };
    }

    if (action.type === GET_PROJECT_ACTIVITIES_CHECK_EXISTANCE) {
        return {
            ...state,
            hasData: action.payload,
        };
    }

    if (action.type === UPDATE_PROJECT_ACTIVITIES_STATUS) {
        const list = [...state.activitiesList];
        const index = list.findIndex((item) => item._id === action.payload._id);
        list[index].status = action.payload.status === 1 ? 0 : 1;
        return {
            ...state,
            activitiesList: list,
            showLoading: false,
        };
    }

    if (action.type === REMOVE_PROJECT_ACTIVITIES_DETAILS) {
        const list = [...state.activitiesList];
        const index = list.findIndex((item) => item._id === action.payload);
        list.splice(index, 1);
        return {
            ...state,
            activitiesList: list,
            showLoading: false,
        };
    }
    if (action.type === GET_TASK_LIST) {
        return {
            ...state,
            totalTaskPage: action.payload.total_page,
            totalTaskRecord: action.payload.total_record,
            taskList: action.payload.data,
            screenVisible: true,
            showLoading: false,
        };
    }
    if (action.type === UPDATE_TASK_STATUS) {
        const list = [...state.taskList];
        const index = list.findIndex((item) => item._id === action.payload._id);
        list[index].status = action.payload.status === 1 ? 0 : 1;
        return {
            ...state,
            taskList: list,
            showLoading: false,
        };
    }

    if (action.type === REMOVE_TASK_LIST) {
        const list = [...state.taskList];
        const index = list.findIndex((item) => item._id === action.payload);
        list.splice(index, 1);
        return {
            ...state,
            taskList: list,
            showLoading: false,
        };
    }

    if (action.type === GET_TASK_DETAILS_BY_ID) {
        return {
            ...state,
            taskDetails: action.payload,
        };
    }

    if (action.type === LOADING_PROJECT) {
        return {
            ...state,
            showLoading: action.payload,
        };
    }
    return state;
}


