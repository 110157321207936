import React, { useState, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Link } from "react-router-dom";
import LogoutPage from './Logout';

const Header = ({ onNote }) => {
	const [rightSelect, setRightSelect] = useState('Eng');
	//For fix header
	const [headerFix, setheaderFix] = useState(false);
	useEffect(() => {
		window.addEventListener("scroll", () => {
			setheaderFix(window.scrollY > 50);
		});
	}, []);

	//const [searchBut, setSearchBut] = useState(false);	
	var path = window.location.pathname.split("/");
	var name = path[path.length - 1].split("-");
	var filterName = name.length >= 3 ? name.filter((n, i) => i > 0) : name;
	var finalName = filterName.includes("app")
		? filterName.filter((f) => f !== "app")
		: filterName.includes("ui")
			? filterName.filter((f) => f !== "ui")
			: filterName.includes("uc")
				? filterName.filter((f) => f !== "uc")
				: filterName.includes("basic")
					? filterName.filter((f) => f !== "basic")
					: filterName.includes("jquery")
						? filterName.filter((f) => f !== "jquery")
						: filterName.includes("table")
							? filterName.filter((f) => f !== "table")
							: filterName.includes("page")
								? filterName.filter((f) => f !== "page")
								: filterName.includes("email")
									? filterName.filter((f) => f !== "email")
									: filterName.includes("ecom")
										? filterName.filter((f) => f !== "ecom")
										: filterName.includes("chart")
											? filterName.filter((f) => f !== "chart")
											: filterName.includes("editor")
												? filterName.filter((f) => f !== "editor")
												: filterName;
	return (
		<div className={`header ${headerFix ? "is-fixed" : ""}`}>
			<div className="header-content">
				<nav className="navbar navbar-expand">
					<div className="collapse navbar-collapse justify-content-between">
						<div className="header-left">
							<div
								className="dashboard_bar"
								style={{ textTransform: "capitalize" }}
							>
								{/* <h3>Welcome Ben-Clerk</h3> */}
							</div>
						</div>
						<div className="navbar-nav header-right">
							<div className="nav-item d-flex align-items-center">
								<div className="input-group search-area">
									<input type="text" className="form-control" placeholder="Search" />
									<span className="input-group-text">
										<Link to={"#"}>
											<i class="bi bi-search"></i>
										</Link></span>
								</div>

								<div className="nav-head-notify-icon-view">
									<button><i class="bi bi-bell"></i></button>
									<button><i class="bi bi-envelope"></i></button>
								</div>
								<div className="nav-head-power-btn-view">

									<Dropdown as="li" className="nav-item dropdown header-profile">
										<Dropdown.Toggle variant="" as="a" className="nav-link i-false c-pointer">
											<button className="power-btn"><i class="bi bi-power"></i></button>
										</Dropdown.Toggle>
										<Dropdown.Menu align="right" className="dropdown-menu dropdown-menu-end">

											<LogoutPage />
										</Dropdown.Menu>
									</Dropdown>
								</div>
							</div>


						</div>


					</div>
				</nav>
			</div>
		</div>
	);
};

export default Header;
