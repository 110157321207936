import React, { Fragment, useContext, useEffect, useState, useRef } from "react";
import { connect, useDispatch } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import {
    Modal,
    Spinner
} from "react-bootstrap";
import moment from 'moment';


const ChatBox = (props) => {

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            props.onSubmitHandler();
        }
      };

    return (
        <Fragment>
            <Modal
                className="fade bd-example-modal-sm"
                size="md"
                onHide={props.closeHandler}
                show={props.show}
            >
                <div className="review-modal">

                    <div className="review-title-view">
                        <h3>Note</h3>
                        <button
                            onClick={props.closeHandler}
                            className="review-cross-btn"><i class="fa fa-times" aria-hidden="true"></i></button>
                    </div>
                    <div className="review-content-view">

                        <div className="review-chat-view">

                            {
                                props.data.map((item, index) => {
                                    return (
                                        <>
                                            {props.currentUser == item.user_id ?
                                                <div key={index} className="chat-bot-right">
                                                    <div className="chat-bot-me">
                                                        <p className='chat-bot-name'>{item.user_type}:{" "}{item.user_first_name}{" "}{item.user_last_name}</p>
                                                        <p>{item.note}</p>
                                                    </div>
                                                    <p className='chat-bot-date'>{moment(item.created_at).format('DD MMMM hh:mm A')}</p>
                                                </div>
                                                :
                                                <div key={index} className="chat-bot-left">
                                                    <div className="chat-bot-you">
                                                        <p className='chat-bot-name'>{item.user_type}:{" "}{item.user_first_name}{" "}{item.user_last_name}</p>
                                                        <p>{item.note}</p>
                                                    </div>
                                                    <p className='chat-bot-date'>{moment(item.created_at).format('DD MMMM hh:mm A')}</p>
                                                </div>
                                            }
                                        </>
                                    );
                                })
                            }
                        </div>
                        <div className="review-chat-btn-view">
                            <div className="chat-input">
                                <input type='text' className="chat-input-control" value={props.value}
                                    onChange={(e) => props.onChangeHandler(e.target.value)}
                                    onKeyDown={(e)=>handleKeyDown(e)}
                                    placeholder='Write your message...' />
                            </div>
                            <div className="chat-btn-control">
                                <button type='button'
                                    onClick={() => props.onSubmitHandler()}
                                    disabled={props.value ? false : true}>
                                    <i class="fa fa-paper-plane" aria-hidden="true"></i></button>
                            </div>
                        </div>

                        {props.loading ?
                            <div className='chat-loding-view'>
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="md"
                                    role="status"
                                    aria-hidden="true"
                                />
                            </div>
                            : null}
                    </div>

                </div>
            </Modal>
        </Fragment>
    )
}

export default ChatBox;