import {
    GLOBAL_LOADING,
} from '../constants';

const initialState = {
    showLoading: false,
};

export function GlobalReducer(state = initialState, action) {

    if (action.type === GLOBAL_LOADING) {
        return {
            ...state,
            showLoading: action.payload,
        };
    }

    return state;
}


