import React, { useContext, useEffect, useState, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Row, Col, Card, Table, Modal, Button, Dropdown, Accordion } from "react-bootstrap";
import moment from 'moment';
import swal from "sweetalert";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getTimeCardSingleDetails, getSuperVisorTimeCardDetails, addOwnTimeSheetData, updateOwnTimeSheetData, removeTimeSheetData } from '../../../store/actions/TimeCardActions';
import { sendToReviewerData, getNoteData, addNoteData } from '../../../store/actions/TimeCaptureActions';
import { getClientsData } from '../../../store/actions/ClientsActions';
import { getProjectList, getProjectByClientIdList, getProjectActivitiesListData, getAccessableTaskList, getProjectDetailsByIdData, getTaskList } from '../../../store/actions/ProjectActions';
import { globalLoadingAction } from '../../../store/actions/GlobalAction';
import { getSystemUserConfigData } from '../../../store/actions/SystemDefaultActions';
import DateRangeModal from '../Components/DateRangeModal';
import SheetReviewModal from '../Components/SheetReviewModal';
import ChatBox from "../Components/ChatBox";

const SupervisorTimeCard = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { state } = useLocation();

    const [cardList, setCardList] = useState([]);

    //note
    const [message, setMessage] = useState('');
    const [projectId, setProjectId] = useState(null);
    const [noteData, setNoteData] = useState([]);
    const [isNote, setIsNote] = useState(false);
    const [loading, setLoading] = useState(false);



    useEffect(() => {
        dispatch(globalLoadingAction(true));
        dispatch(getSuperVisorTimeCardDetails(props.history));
    }, []);

    useEffect(() => {
        if (props.timeCardDetails?.data) {
            setCardList(props.timeCardDetails.data);
        }
    }, [props.timeCardDetails]);


    const onSubmitMessageHandler = () => {
        setMessage("");
        setLoading(true);
        dispatch(addNoteData(projectId, message, AddNotData, props.history));
    }
    const AddNotData = (data) => {
        setNoteData(data);
        setLoading(false);
    }

    const onGetNoteHandler = (item) => {
        setProjectId(item.project_id);
        dispatch(globalLoadingAction(true));
        dispatch(getNoteData(item.project_id, setNotData, props.history));
    }

    const setNotData = (data) => {
        dispatch(globalLoadingAction(false));
        setNoteData(data);
        setIsNote(true);
    }

    const closeHandler = () => {
        setIsNote(false);
        setNoteData([]);
    }

    return (
        <>
            <div className="row">

                <div className="breadcrumb-container">
                    <ul>
                        <li>
                            <Link to={"#"}>Reporting</Link>
                        </li>
                        <li><i className="fa fa-angle-right"></i></li>
                        <li>
                            <Link to={"#"}>Time card</Link>
                        </li>
                    </ul>
                </div>
                <div className="title-body mb-3">
                    <h3>Time card</h3>
                </div>

                <div className="account-setting-content">
                    <Col lg={12}>
                        <Card className='m-0'>
                            <Card.Body>
                                {cardList.length > 0 ?
                                    cardList.map((details, parentindex) => {
                                        return (
                                            <>
                                                <div className='timeSheet-project-card mb-1 mt-3'>
                                                    <div>
                                                        <h4 className='timeSheet-project-title'>{details.project_name}</h4>
                                                    </div>
                                                    <div>
                                                        <button
                                                            type='button'
                                                            //disabled={details.send_for_approval == 1 ? false : true}
                                                            className='note-project-btm ms-2'
                                                            onClick={() => onGetNoteHandler(details)}
                                                        >Note</button>
                                                    </div>
                                                </div>
                                                <Table responsive key={parentindex} className='cart-table'>
                                                    <thead>
                                                        <tr className='cart-table-header'>
                                                            <th>
                                                                <strong>Date</strong>
                                                            </th>
                                                            <th>
                                                                <strong>Activities</strong>
                                                            </th>
                                                            <th>
                                                                <strong>Task</strong>
                                                            </th>
                                                            <th>
                                                                <strong>Task ID</strong>
                                                            </th>   
                                                            <th>
                                                                <strong>Start time</strong>
                                                            </th>
                                                            <th>
                                                                <strong>Finish time</strong>
                                                            </th>
                                                            <th><strong></strong></th>
                                                            <th className='text-center'>
                                                                <strong>Regular Hrs</strong>
                                                            </th>
                                                            <th className='text-center'>
                                                                <strong>Total Hrs</strong>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            details?.data?.map((item, index) => {
                                                                return (
                                                                    <tr key={index}>
                                                                        <td>{moment(item.start_time).format('DD/MM/YYYY')}</td>
                                                                        <td>{item.activity}</td>
                                                                        <td>{item.task}</td>
                                                                        <td>{item.task_unique_id}</td>
                                                                        <td>{moment(item.start_time).format('h:mm:ss A')}</td>
                                                                        <td>{moment(item.end_time).format('h:mm:ss A')}
                                                                        </td>
                                                                        <td>
                                                                        </td>
                                                                        <td className='total-hrs'>{item.regular_hour}</td>
                                                                        <td className='total-hrs'>{item.total_hour}</td>
                                                                    </tr>
                                                                );
                                                            })
                                                        }
                                                        <tr className='total-time'>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td><strong>TOTAL HOURS :</strong></td>
                                                            <td className='total-hrs'><strong>{details?.aggregate_data?.total_regular_hour}</strong></td>
                                                            <td className='total-hrs'><strong>{details?.aggregate_data?.total_hour}</strong></td>
                                                        </tr>
                                                        <tr className='total-time'>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td><strong>RATE PER HOURS :</strong></td>
                                                            <td className='total-hrs'><strong>${details?.aggregate_data?.emp_rate}</strong></td>
                                                            <td className='total-hrs'><strong></strong></td>
                                                        </tr>
                                                        <tr className='total-time'>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td><strong>TOTAL PAY :</strong></td>
                                                            <td className='total-hrs'><strong>${details?.aggregate_data?.total_regular_pay}</strong></td>
                                                            <td className='total-hrs'><strong>${details?.aggregate_data?.total_pay}</strong></td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </>
                                        )
                                    })
                                    :
                                    <div className='text-center mt-3'><h3>No Data Found</h3></div>}

                            </Card.Body>
                        </Card>
                        <div>
                            <p><strong>Note:</strong> Use increments of 0.25 (15 minutes) when recording hours.</p>
                        </div>
                    </Col>
                </div>
            </div>

            <ChatBox
                show={isNote}
                data={noteData}
                value={message}
                onChangeHandler={(val) => setMessage(val)}
                onSubmitHandler={() => onSubmitMessageHandler()}
                closeHandler={closeHandler}
                loading={loading}
                currentUser={props.userId}
            />
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        screenVisible: state.clients.screenVisible,
        showLoading: state.clients.showLoading,
        clientList: state.clients.clientList,
        projectList: state.project.projectList,
        activitiesList: state.project.activitiesList,
        taskList: state.project.taskList,
        countryList: state.country.countryList,
        timeCardDetails: state.timeCard.details,
        configDetails: state.systemconfig.configDetails,
        systemAdmin: state.auth.systemAdmin,
        projectDetails: state.project.projectDetails,
        userId: state.auth.userId,
    };
};
export default connect(mapStateToProps)(SupervisorTimeCard);