import React, { useContext, useEffect, useState, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Row, Col, Card, Table, Modal, Button, Dropdown, Accordion } from "react-bootstrap";
import moment from 'moment';
import Moment from 'react-moment';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getReviewerTimeCardSingleDetails } from '../../../store/actions/TimeCardActions';
import { reviewerApproveTrackingDetailsData,getReviewerTimeCardDetails, getReviewerTrackingData, getNoteData, sendToApproverData, addNoteData, updateTimeCaptureApproveStatusByReviewerData } from '../../../store/actions/TimeCaptureActions';
import { globalLoadingAction } from '../../../store/actions/GlobalAction';
import ChatBox from "../Components/ChatBox";

const ReviewerTimeCard = (props) => {

    const dispatch = useDispatch();
    const history = useHistory();
    // const { state } = useLocation();
    const [projectDetails, setProjectDetails] = useState({});
    const [cardList, setCardList] = useState([]);
    const [projectId, setProjectId] = useState(null);

    //Note
    const [message, setMessage] = useState('');
    const [noteData, setNoteData] = useState([]);
    const [isNote, setIsNote] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        dispatch(globalLoadingAction(true));
        // dispatch(getReviewerTimeCardSingleDetails(state.id, props.history));
        dispatch(getReviewerTimeCardDetails(props.history));
    }, []);

    useEffect(() => {
        if (props.approverCardDetails?.data) {
            setCardList(props.approverCardDetails.data);
        }
        // if (props.approverCardDetails?.project_details) {
        //     setProjectDetails(props.approverCardDetails.project_details);
        // }
    }, [props.approverCardDetails]);

    const onGetNoteHandler = (item) => {
        setProjectId(item.project_id);
        dispatch(globalLoadingAction(true));
        dispatch(getNoteData(item.project_id, setNotData, props.history));
    }

    const setNotData = (data) => {
        dispatch(globalLoadingAction(false));
        setNoteData(data);
        setIsNote(true);
    }
    const closeHandler = () => {
        setIsNote(false);
        setNoteData([]);
    }
    const onSubmitMessageHandler = () => {
        setMessage("");
        setLoading(true);
        dispatch(addNoteData(projectId, message, AddNotData, props.history));
    }
    const AddNotData = (data) => {
        setNoteData(data);
        setLoading(false);
    }

    // const onAssignHandler = (item) => {
    //     let track_details = item.time_track_details;
    //     let array = [];
    //     track_details.forEach(element => {
    //         if (element.time_track_id) {
    //             array.push(element.time_track_id)
    //         }
    //     });
    //     dispatch(globalLoadingAction(true));
    //     dispatch(reviewerApproveTrackingDetailsData(state.id, array, getTrackData, props.history));
    // };

    const getTrackData = () => {
        // dispatch(getReviewerTimeCardSingleDetails(state.id, props.history));
        dispatch(getReviewerTimeCardDetails(props.history));
    }


    return (
        <>
            <div className="row">

                <div className="breadcrumb-container">
                    <ul>
                        <li>
                            <Link to={"/task-details"}>Review List</Link>
                        </li>
                        {/* <li><i className="fa fa-angle-right"></i></li> */}
                        {/* <li>
                            <Link to={"/time-cart"}>Timesheet</Link>
                        </li> */}
                    </ul>
                </div>
                <div className="title-body mb-3">
                    <h3>Timesheet</h3>
                </div>

                <div className="account-setting-content">
                    <Col lg={12}>
                        <Card className='m-0'>

                            <Card.Body className="pt-3">

                                {/* <div className="card-detail-header">

                                    <div className="card-detail-left">
                                        <div>
                                            <label className='mb-2'><strong>Project ID:</strong> {projectDetails?.project_id}</label>
                                        </div>
                                        <div>
                                            <label className='mb-2'><strong>Project Name:</strong> {projectDetails?.title}</label>
                                        </div>
                                        <div>
                                            <label><strong>Project Rate:</strong> {projectDetails?.rate}</label>
                                        </div>
                                    </div>
                                    <div className="card-detail-right">
                                        {projectDetails?.start_date ?
                                            <div>
                                                <label className='mb-2'><strong>Project Start Date:</strong> {<Moment format="D MMM YYYY" withTitle>{projectDetails?.start_date}</Moment>}</label>
                                            </div>
                                            : null}
                                        {projectDetails?.end_date ?
                                            <div>
                                                <label><strong>Project End Date:</strong> {<Moment format="D MMM YYYY" withTitle>{projectDetails?.end_date}</Moment>}</label>
                                            </div>
                                            : null}
                                    </div>
                                </div> */}
                                <div>
                                    {cardList.length > 0 ?
                                        cardList.map((details, parentindex) => {
                                            return (
                                                <>
                                                    <div key={parentindex} className='timeSheet-project-card mb-1 mt-3'>
                                                        <div>
                                                            {/* <h4 className='timeSheet-project-title'>{details.project_name}</h4> */}
                                                        </div>
                                                        <div>
                                                            {/* <button
                                                                type='button'
                                                                disabled={details.notable == 1 ? false : true}
                                                                className='btn btn-success send-app-project-btm'
                                                                onClick={() => onAssignHandler(details)}
                                                            >Approved</button> */}
                                                            <button
                                                                type='button'
                                                                //disabled={details.notable == 1 ? false : true}
                                                                className='note-project-btm ms-2'
                                                                onClick={() => onGetNoteHandler(details)}
                                                            >Note</button>
                                                        </div>
                                                    </div>
                                                    <Table responsive key={parentindex} className='cart-table'>
                                                        <thead>
                                                            <tr className='cart-table-header'>
                                                                <th>
                                                                    <strong>Date</strong>
                                                                </th>
                                                                <th>
                                                                    <strong>Activities</strong>
                                                                </th>
                                                                <th>
                                                                    <strong>Task</strong>
                                                                </th>
                                                                <th>
                                                                    <strong>Task ID</strong>
                                                                </th>
                                                                <th>
                                                                    <strong>Start time</strong>
                                                                </th>
                                                                <th>
                                                                    <strong>Finish time</strong>
                                                                </th>
                                                                <th><strong></strong></th>
                                                                <th className='text-center'>
                                                                    <strong>Regular Hrs</strong>
                                                                </th>
                                                                <th className='text-center'>
                                                                    <strong>Total Hrs</strong>
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                details?.data?.map((item, index) => {
                                                                    return (
                                                                        <tr key={index}>
                                                                            <td>{moment(item.start_time).format('DD/MM/YYYY')}</td>
                                                                            <td>{item.activity}</td>
                                                                            <td>{item.task}</td>
                                                                            <td>{item.task_unique_id}</td>
                                                                            <td>{moment(item.start_time).format('h:mm:ss A')}</td>
                                                                            <td>{moment(item.end_time).format('h:mm:ss A')}
                                                                            </td>
                                                                            <td>

                                                                            </td>
                                                                            <td className='total-hrs'>{item.regular_hour}</td>
                                                                            <td className='total-hrs'>{item.total_hour}</td>
                                                                        </tr>
                                                                    );
                                                                })
                                                            }
                                                            <tr className='total-time'>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td><strong>TOTAL HOURS :</strong></td>
                                                                <td className='total-hrs'><strong>{details?.aggregate_data?.total_regular_hour}</strong></td>
                                                                <td className='total-hrs'><strong>{details?.aggregate_data?.total_hour}</strong></td>
                                                            </tr>
                                                            <tr className='total-time'>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td><strong>RATE PER HOURS :</strong></td>
                                                                <td className='total-hrs'><strong>${details?.aggregate_data?.emp_rate}</strong></td>
                                                                <td className='total-hrs'><strong></strong></td>
                                                            </tr>
                                                            <tr className='total-time'>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td><strong>TOTAL PAY :</strong></td>
                                                                <td className='total-hrs'><strong>${details?.aggregate_data?.total_regular_pay}</strong></td>
                                                                <td className='total-hrs'><strong>${details?.aggregate_data?.total_pay}</strong></td>
                                                            </tr>
                                                        </tbody>
                                                    </Table>
                                                </>
                                            )
                                        })
                                        :
                                        <div className='text-center mt-3'><h3>No Data Found</h3></div>}

                                </div>
                            </Card.Body>
                        </Card>
                        <div>
                            <p><strong>Note:</strong> Use increments of 0.25 (15 minutes) when recording hours.</p>
                        </div>
                    </Col>
                </div>
            </div>
            {console.log('approverCardDetails',props.approverCardDetails)}
            <ChatBox
                show={isNote}
                data={noteData}
                value={message}
                onChangeHandler={(val) => setMessage(val)}
                onSubmitHandler={() => onSubmitMessageHandler()}
                closeHandler={closeHandler}
                loading={loading}
                currentUser={props.userId}
            />
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        screenVisible: state.timeCapture.screenVisible,
        showLoading: state.timeCapture.showLoading,
        list: state.timeCapture.approvePendingList,
        totalPage: state.timeCapture.approverPendingPage,
        totalRecord: state.timeCapture.approverPendingRecord,
        approverCardDetails: state.timeCapture.approverCardDetails,
        userId: state.auth.userId,
    };
};
export default connect(mapStateToProps)(ReviewerTimeCard);