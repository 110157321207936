class GlobalConfigs {
  get VERSION() {
    return "1";
  }
  get API_URL() {
    //   return process.env.REACT_APP_APIHOSTURL
    return "https://dev.clockmenow.com/api/"
  }
  get IMAGE_URL() {
    return "https://dev.clockmenow.com/"
  }
}

const globalConfigs = new GlobalConfigs();

export default globalConfigs;