import {
    LOADING_TEAM,
    ADD_TEAM,
    GET_TEAM_LIST,
    GET_TEAM_LIST_BY_ID,
    UPDATE_TEAM_STATUS,
    GET_ACTIVE_TEAM_LIST,
    REMOVE_TEAM_RECORD
} from '../constants';

const initialState = {
    teamList: [],
    teamListById: [],
    activeTeamList: [],
    totalPage: 0,
    totalRecord: 0,
    screenVisible: false,
    showLoading: false,
};

export function TeamReducer(state = initialState, action) {

    if (action.type === GET_TEAM_LIST) {
        return {
            ...state,
            totalPage: action.payload.total_page,
            totalRecord: action.payload.total_record,
            teamList: action.payload.data,
            screenVisible: true,
            showLoading: false,
        };
    }
    if (action.type === GET_ACTIVE_TEAM_LIST) {
        return {
            ...state,
            activeTeamList: action.payload,
            showLoading: false,
        };
    }
    if (action.type === GET_TEAM_LIST_BY_ID) {
        return {
            ...state,
            teamListById: action.payload,
            showLoading: false,
        };
    }

    if (action.type === UPDATE_TEAM_STATUS) {
        const list = [...state.teamList];
        const index = list.findIndex((item) => item._id === action.payload._id);
        list[index].status = action.payload.status === 1 ? 0 : 1;
        return {
            ...state,
            teamList: list,
            showLoading: false,
        };
    }

    if (action.type === REMOVE_TEAM_RECORD) {
        const list = [...state.teamList];
        const index = list.findIndex((item) => item._id === action.payload);
        list.splice(index, 1);
        return {
            ...state,
            teamList: list,
            showLoading: false,
        };
    }

    if (action.type === LOADING_TEAM) {
        return {
            ...state,
            showLoading: action.payload,
        };
    }
    return state;
}


