import React, { useContext, useEffect, useState, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Row, Col, Card, Table, Modal, Button, Dropdown, Accordion } from "react-bootstrap";
import moment from 'moment';
import swal from "sweetalert";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { sendToReviewerData, getNoteData, addNoteData } from '../../../store/actions/TimeCaptureActions';
import { getTimeCardSingleDetails, addTimeSheetData, updateTimeSheetData, removeTimeSheetData } from '../../../store/actions/TimeCardActions';
import { getClientsData } from '../../../store/actions/ClientsActions';
import { getProjectList, getProjectByClientIdList, getProjectActivitiesListData, getAccessableTaskList, getProjectDetailsByIdData, getTaskList } from '../../../store/actions/ProjectActions';
import { globalLoadingAction } from '../../../store/actions/GlobalAction';
import { getSystemUserConfigData } from '../../../store/actions/SystemDefaultActions';
import DateRangeModal from '../Components/DateRangeModal';
import SheetReviewModal from '../Components/SheetReviewModal'
import ChatBox from "../Components/ChatBox";

const CartDetails = (props) => {

    const dispatch = useDispatch();
    const history = useHistory();
    const { state } = useLocation();
    const startDateRef = useRef();
    const endDateRef = useRef();
    const filterRef = useRef();

    let errorsObj = { client: '', project: '', activity: '', task: '', description: '', startDate: '', endDate: '' };
    const [errors, setErrors] = useState(errorsObj);

    let errorsFilterObj = { endDate: '' };
    const [filterErrors, setFilterErrors] = useState(errorsFilterObj);

    const [client, setClient] = useState('');
    const [project, setProject] = useState('');
    const [activity, setActivity] = useState('');
    const [task, setTask] = useState('');
    const [description, setDescription] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [filterType, setFilterType] = useState(0);
    const [addModal, setAddModal] = useState(false);
    const [action, setAction] = useState(1);
    const [record, setRecord] = useState(null);
    const [projectStartDate, setProjectStartDate] = useState('');
    const [projectEndDate, setProjectEndDate] = useState('');
    const [isDateRange, setIsDateRange] = useState(false);
    const [reviewModal, setReviewModal] = useState(false);
    const [groupBy, setGroupBy] = useState(true);
    const [cardList, setCardList] = useState([]);

    //note
    const [message, setMessage] = useState('');
    const [projectId, setProjectId] = useState(null);
    const [noteData, setNoteData] = useState([]);
    const [isNote, setIsNote] = useState(false);
    const [loading, setLoading] = useState(false);

    //filter
    const [isFilter, setIsFilter] = useState(false);
    const [filterModal, setFilterModal] = useState(false);
    const [filterClient, setFilterClient] = useState('');
    const [filterProject, setFilterProject] = useState('');
    const [filterStartDate, setFilterStartDate] = useState('');
    const [filterEndDate, setFilterEndDate] = useState('');

    useEffect(() => {
        dispatch(globalLoadingAction(true));
        dispatch(getSystemUserConfigData(2, props.systemAdmin, props.history));
        dispatch(getClientsData("", "", "", 1, 0, props.history));
    }, []);

    useEffect(() => {
        setFilterType(props?.configDetails?.filter_option_id);
    }, [props.configDetails]);

    useEffect(() => {
        if (filterType === 1 || filterType === 2) {
            dispatch(globalLoadingAction(true));
            dispatch(getTimeCardSingleDetails(state.id, filterType, false, '', '', '', '', groupBy, props.history));
            setFilterStartDate('');
            setFilterEndDate('');
            onClearFilter();
        }
    }, [filterType]);

    useEffect(() => {
        if (props.timeCardDetails?.data) {
            setCardList(props.timeCardDetails.data);
        }
    }, [props.timeCardDetails]);

    useEffect(() => {
        if (props.projectDetails?.start_date) {
            setProjectStartDate(new Date(props.projectDetails.start_date));
            setProjectEndDate(new Date(props.projectDetails.end_date));
        }
    }, [props.projectDetails]);

    function onUpdateData(e) {

        e.preventDefault();
        let error = false;
        const errorObj = { ...errorsObj };

        if (client === '') {
            errorObj.client = 'Client is Required';
            error = true;
        }
        if (project === '') {
            errorObj.project = 'Project is Required';
            error = true;
        }
        if (activity === '') {
            errorObj.activity = 'Activity is Required';
            error = true;
        }
        if (task === '') {
            errorObj.task = 'Task is Required';
            error = true;
        }
        if (startDate === '') {
            errorObj.startDate = 'Start date is Required';
            error = true;
        }
        if (endDate === '') {
            errorObj.endDate = 'End date is Required';
            error = true;
        }
        if (startDate >= projectStartDate && endDate >= projectEndDate) {
            setIsDateRange(true);
            error = true;
        }
        if (description === '') {
            errorObj.description = 'Description is Required';
            error = true;
        }

        setErrors(errorObj);
        if (error) {
            return;
        }


        if (action == 1) {
            setAddModal(false);
            setReviewModal(true);
        } else {
            dispatch(globalLoadingAction(true));
            dispatch(updateTimeSheetData(
                state.id,
                record,
                client == "0" ? "" : client,
                project == "0" ? "" : project,
                activity == "0" ? "" : activity,
                task == "0" ? "" : task,
                description,
                startDate,
                endDate,
                filterType,
                true,
                filterClient ? filterClient : '',
                filterProject ? filterProject : '',
                filterType == 3 ? moment(filterStartDate).format('YYYY-MM-DD') : '',
                filterType == 3 ? moment(filterEndDate).format('YYYY-MM-DD') : '',
                groupBy,
                props.history));
            setAddModal(false);
            clienHandler();
        }

    }

    const onFinalSubmitHandler = () => {
        dispatch(globalLoadingAction(true));
        dispatch(addTimeSheetData(
            state.id,
            client == "0" ? "" : client,
            project == "0" ? "" : project,
            activity == "0" ? "" : activity,
            task == "0" ? "" : task,
            description,
            startDate,
            endDate,
            filterType,
            filterType == 3 ? moment(filterStartDate).format('YYYY-MM-DD') : '',
            filterType == 3 ? moment(filterEndDate).format('YYYY-MM-DD') : '',
            groupBy,
            props.history));
        setReviewModal(false);
        onClearFilter();
        clienHandler();
    }

    const onReviewCloseHandler = () => {
        setAddModal(true);
        setReviewModal(false);
    }

    const clienHandler = () => {
        setClient('');
        setProject('');
        setActivity('');
        setTask('');
        setDescription('');
        setStartDate('');
        setEndDate('');
    }

    const onClientHandler = (val) => {
        setClient(val);
        setProject('');
        setActivity('');
        setTask('');
        dispatch(getProjectByClientIdList(val, props.history));
    }

    const onProjectHandler = (val) => {
        setProject(val);
        setActivity('');
        setTask('');
        if (val) {
            dispatch(getProjectDetailsByIdData(val, props.history));
            dispatch(getProjectActivitiesListData(val, '', '', '', 1, 0, props.systemAdmin, props.history));
        }

    }

    const onActivityHander = (val) => {
        setActivity(val);
        setTask('');
        dispatch(getAccessableTaskList(val, state.id, props.history));
    }

    const onFilterTypeHandler = (val) => {
        setFilterType(val);
    }

    const onAddHandler = () => {
        const errorObj = { ...errorsObj };
        setErrors(errorObj);
        setRecord('');
        setClient('');
        setProject('');
        setActivity('');
        setTask('');
        setStartDate('');
        setEndDate('');
        setDescription('');
        setAddModal(true);
        setAction(1);
    }

    const onEditHandler = (item) => {
        dispatch(getProjectDetailsByIdData(item.project_id, props.history));
        dispatch(getProjectByClientIdList(item.client_id, props.history));
        dispatch(getProjectActivitiesListData(item.project_id, '', '', '', 1, 0, props.systemAdmin, props.history));
        dispatch(getAccessableTaskList(item.activity_id, state.id, props.history));
        setRecord(item.time_track_id);
        setClient(item.client_id);
        setProject(item.project_id);
        setActivity(item.activity_id);
        setTask(item.task_id);
        setStartDate(new Date(item.start_time));
        setEndDate(new Date(item.end_time));
        setDescription(item.description);
        setProjectStartDate(new Date(item.project_start_date));
        setProjectEndDate(new Date(item.project_end_date));
        setErrors(errorsObj);
        setAddModal(true);
        setAction(2);
    }

    const onDeleteHandler = (item) => {

        swal({
            title: "Are you sure?",
            text:
                "You want to remove this item !",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                dispatch(globalLoadingAction(true));
                dispatch(removeTimeSheetData(item._id,
                    item.time_track_id,
                    state.id,
                    filterType,
                    false,
                    filterClient ? filterClient : '',
                    filterProject ? filterProject : '',
                    filterType == 3 ? moment(filterStartDate).format('YYYY-MM-DD') : '',
                    filterType == 3 ? moment(filterEndDate).format('YYYY-MM-DD') : '', groupBy,
                    props.history));

            }
        });
    }

    const onFilterClientHandler = (val) => {
        setFilterClient(val);
        setFilterProject('');
        dispatch(getProjectByClientIdList(val, props.history));
        dispatch(globalLoadingAction(true));
        dispatch(getTimeCardSingleDetails(state.id, filterType, false,
            val, '',
            filterType == 3 ? moment.utc(filterStartDate).format('YYYY-MM-DD') : '',
            filterType == 3 ? moment.utc(filterEndDate).format('YYYY-MM-DD') : '', groupBy, props.history));
    }

    const onFilterProjectHandler = (val) => {
        setFilterProject(val);
        dispatch(globalLoadingAction(true));
        dispatch(getTimeCardSingleDetails(state.id, filterType, false,
            filterClient, val,
            filterType == 3 ? moment.utc(filterStartDate).format('YYYY-MM-DD') : '',
            filterType == 3 ? moment.utc(filterEndDate).format('YYYY-MM-DD') : '', groupBy, props.history));
    }

    const onChangeDateFilterHandler = (dates) => {
        const [start, end] = dates;
        setFilterStartDate(start);
        setFilterEndDate(end);
        if (start && end) {
            dispatch(globalLoadingAction(true));
            const utcStartDate = moment.utc(start).format('YYYY-MM-DD');
            const utcEndDate = moment.utc(end).format('YYYY-MM-DD');
            dispatch(getTimeCardSingleDetails(state.id, 3, false, '', '', utcStartDate, utcEndDate, groupBy, props.history));
            setFilterType(3);
            onClearFilter();
        }
    }

    function onFilterSubmit(e) {
        e.preventDefault();
        dispatch(globalLoadingAction(false));
        dispatch(getTimeCardSingleDetails(state.id, filterType, false,
            filterClient, filterProject,
            filterType == 3 ? moment.utc(filterStartDate).format('YYYY-MM-DD') : '',
            filterType == 3 ? moment.utc(filterEndDate).format('YYYY-MM-DD') : '', groupBy, props.history));
        setFilterModal(false);
        setIsFilter(true);
    }

    const onClearFilterHandler = () => {
        dispatch(globalLoadingAction(false));
        dispatch(getTimeCardSingleDetails(state.id, filterType, false, '', '', filterType == 3 ? moment.utc(filterStartDate).format('YYYY-MM-DD') : '',
            filterType == 3 ? moment.utc(filterEndDate).format('YYYY-MM-DD') : '', groupBy, props.history));
        onClearFilter();
    }
    const onClearFilter = () => {
        setFilterModal(false);
        setFilterClient('');
        setFilterProject('');
        setIsFilter(false);
    }

    const onAssignHandler = (item) => {
        let array = [];
        item.data.forEach(element => {
            if (element.time_track_id) {
                array.push(element.time_track_id)
            }
        });
        dispatch(globalLoadingAction(true));
        dispatch(sendToReviewerData(item.project_id, array, sendToReviewerCallBack, props.history));
    }
    const sendToReviewerCallBack = () => {
        dispatch(globalLoadingAction(true));
        dispatch(getTimeCardSingleDetails(state.id, filterType, false,
            filterClient, filterProject,
            filterType == 3 ? moment.utc(filterStartDate).format('YYYY-MM-DD') : '',
            filterType == 3 ? moment.utc(filterEndDate).format('YYYY-MM-DD') : '', groupBy, props.history));
    }


    const onSubmitMessageHandler = () => {
        setMessage("");
        setLoading(true);
        dispatch(addNoteData(projectId, message, AddNotData, props.history));
    }
    const AddNotData = (data) => {
        setNoteData(data);
        setLoading(false);
    }

    const onGetNoteHandler = (item) => {
        setProjectId(item.project_id);
        dispatch(globalLoadingAction(true));
        dispatch(getNoteData(item.project_id, setNotData, props.history));
    }

    const setNotData = (data) => {
        dispatch(globalLoadingAction(false));
        setNoteData(data);
        setIsNote(true);
    }

    const closeHandler = () => {
        setIsNote(false);
        setNoteData([]);
    }


    const formatDate = (date) => {
        return moment(date).format('DD/MM/YY');
    };

    return (
        <>
            <div className="row">

                <div className="breadcrumb-container">
                    <ul>
                        <li>
                            <Link to={"#"}>Reporting</Link>
                        </li>
                        <li><i className="fa fa-angle-right"></i></li>
                        <li>
                            <Link to={"/time-cart"}>Time Entry</Link>
                        </li>
                        <li><i className="fa fa-angle-right"></i></li>

                        <li>
                            <Link to={"#"}>Timesheet</Link>
                        </li>
                    </ul>
                </div>
                <div className="title-body mb-3">
                    <h3>{filterType == 1 ? 'Monthly' : filterType == 2 ? 'Weekly' : filterType == 3 ? `${formatDate(filterStartDate)} To ${formatDate(filterEndDate)}` : null} Timesheet</h3>
                </div>
                <div className="title-body-container">

                    <div className="title-filter-container">
                        <div className="button-filter">
                            <button className="time-sheet-filter-btn" style={{ backgroundColor: filterType == 1 ? '#332d28' : '#7e7c7a' }} onClick={() => onFilterTypeHandler(1)} type='button'>Monthly</button>
                            <button className="time-sheet-filter-btn" style={{ backgroundColor: filterType == 2 ? '#332d28' : '#7e7c7a' }} onClick={() => onFilterTypeHandler(2)} type='button'>Weekly</button>
                            <div className="time-sheet-filter-date-view">
                                <DatePicker
                                    ref={filterRef}
                                    selected={filterStartDate}
                                    className="d-none"
                                    selectsRange
                                    startDate={filterStartDate}
                                    endDate={filterEndDate}
                                    onChange={(dates) => onChangeDateFilterHandler(dates)}
                                />
                                <button
                                    type="button"
                                    className="time-sheet-filter-date-btn"
                                    style={{ backgroundColor: filterType == 3 ? '#332d28' : '#7e7c7a' }}
                                    onClick={() => filterRef.current.setOpen(true)}
                                >
                                    {filterStartDate && filterEndDate
                                        ? `${formatDate(filterStartDate)} - ${formatDate(filterEndDate)}`
                                        : 'Select Date Range'}
                                </button>
                            </div>
                            <div className="form-group card-filter-select me-2">
                                <div className="select-drop-down">
                                    <span><i class="fa fa-angle-down"></i></span>
                                    <select
                                        defaultValue={"option"}
                                        className="form-control select-control"
                                        value={filterClient}
                                        onChange={(e) => onFilterClientHandler(e.target.value)}>
                                        <option value="">Client</option>
                                        {
                                            props.clientList.map((item, index) => {
                                                return <option key={index} value={item._id}>{item.first_name}</option>
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="form-group card-filter-select me-2">
                                <div className="select-drop-down">
                                    <span><i class="fa fa-angle-down"></i></span>
                                    <select
                                        defaultValue={"option"}
                                        className="form-control select-control"
                                        value={filterProject}
                                        onChange={(e) => onFilterProjectHandler(e.target.value)}>
                                        <option value="">Project</option>
                                        {
                                            props.projectList.map((item, index) => {
                                                return <option key={index} value={item._id}>{item.title}</option>
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                            {/* <button className="time-sheet-filter-btn" style={{ backgroundColor: isFilter ? '#332d28' : '#7e7c7a' }} onClick={() => setFilterModal(true)} type='button'>Filter</button> */}
                            <button className="add-user-btn" onClick={onAddHandler}><i class="fa fa-plus" aria-hidden="true"></i></button>
                        </div>

                    </div>

                </div>
                <div className="account-setting-content">
                    <Col lg={12}>
                        <Card className='m-0'>

                            <Card.Body className="pt-3">

                                <div className="card-detail-header">

                                    <div className="card-detail-left">
                                        <div>
                                            <label className='mb-2'><strong>Employee Name:</strong> {props.timeCardDetails?.user_details?.first_name} {props.details?.user_details?.last_name}</label>
                                        </div>
                                        <div>
                                            <label><strong>Employee Id:</strong> {props.timeCardDetails?.user_details?.employees?.employee_id}</label>
                                        </div>
                                    </div>
                                    <div className="card-detail-right">
                                        <div>
                                            <label className='mb-2'><strong>Employee Email:</strong> {props.timeCardDetails?.user_details?.email}</label>
                                        </div>
                                        <div>
                                            {/* <label><strong>Employee Phone:</strong> {props.details?.user_details?.phone_no}</label> */}
                                        </div>
                                    </div>
                                </div>
                                {cardList.length > 0 ?
                                    cardList.map((details, parentindex) => {
                                        return (
                                            <>
                                                <div className='timeSheet-project-card mb-1 mt-3'>
                                                    <div>
                                                        <h4 className='timeSheet-project-title'>{details.project_name}</h4>
                                                    </div>
                                                    <div>
                                                        <button
                                                            type='button'
                                                            disabled={details.send_for_approval == 1 ? true : false}
                                                            className='assign-project-btm'
                                                            onClick={() => onAssignHandler(details)}
                                                        >Assing time Approver</button>
                                                        <button
                                                            type='button'
                                                            disabled={details.send_for_approval == 1 ? false : true}
                                                            className='note-project-btm ms-2'
                                                            onClick={() => onGetNoteHandler(details)}
                                                        >Note</button>
                                                    </div>
                                                </div>
                                                <Table responsive key={parentindex} className='cart-table'>
                                                    <thead>
                                                        <tr className='cart-table-header'>
                                                            <th>
                                                                <strong>Date</strong>
                                                            </th>
                                                            <th>
                                                                <strong>Activities</strong>
                                                            </th>
                                                            <th>
                                                                <strong>Task</strong>
                                                            </th>
                                                            <th>
                                                                <strong>Task ID</strong>
                                                            </th>
                                                            <th>
                                                                <strong>Start time</strong>
                                                            </th>
                                                            <th>
                                                                <strong>Finish time</strong>
                                                            </th>
                                                            <th><strong></strong></th>
                                                            <th className='text-center'>
                                                                <strong>Regular Hrs</strong>
                                                            </th>
                                                            <th className='text-center'>
                                                                <strong>Total Hrs</strong>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            details?.data?.map((item, index) => {
                                                                return (
                                                                    <tr>
                                                                        <td>{moment(item.start_time).format('DD/MM/YYYY')}</td>
                                                                        <td>{item.activity}</td>
                                                                        <td>{item.task}</td>
                                                                        <td>{item.task_unique_id}</td>
                                                                        <td>{moment(item.start_time).format('h:mm:ss A')}</td>
                                                                        <td>{moment(item.end_time).format('h:mm:ss A')}
                                                                        </td>
                                                                        <td>
                                                                            {item.send_for_approval == 0 ?
                                                                                <Dropdown>
                                                                                    <Dropdown.Toggle
                                                                                        className="card-sheet-btn i-false"
                                                                                    >
                                                                                        <i class="fa fa-ellipsis-h" aria-hidden="true"></i>
                                                                                    </Dropdown.Toggle>
                                                                                    <Dropdown.Menu>
                                                                                        <Dropdown.Item onClick={() => onEditHandler(item)}>Edit</Dropdown.Item>
                                                                                        <Dropdown.Item onClick={() => onDeleteHandler(item)}>Delete</Dropdown.Item>
                                                                                    </Dropdown.Menu>
                                                                                </Dropdown>
                                                                                : null}
                                                                        </td>
                                                                        <td className='total-hrs'>{item.regular_hour}</td>
                                                                        {/* <td className="overtime-hrs">3</td> */}
                                                                        <td className='total-hrs'>{item.total_hour}</td>
                                                                    </tr>
                                                                );
                                                            })
                                                        }

                                                        <tr className='total-time'>
                                                            {/* <td></td> */}
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td><strong>TOTAL HOURS :</strong></td>
                                                            <td className='total-hrs'><strong>{details?.aggregate_data?.total_regular_hour}</strong></td>
                                                            {/* <td className='total-hrs'><strong>6.00</strong></td> */}
                                                            <td className='total-hrs'><strong>{details?.aggregate_data?.total_hour}</strong></td>
                                                        </tr>
                                                        <tr className='total-time'>
                                                            {/* <td></td> */}
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td><strong>RATE PER HOURS :</strong></td>
                                                            <td className='total-hrs'><strong>${details?.aggregate_data?.emp_rate}</strong></td>
                                                            {/* <td className='total-hrs'><strong>$5.00</strong></td> */}
                                                            <td className='total-hrs'><strong></strong></td>
                                                        </tr>
                                                        <tr className='total-time'>
                                                            {/* <td></td> */}
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td><strong>TOTAL PAY :</strong></td>
                                                            <td className='total-hrs'><strong>${details?.aggregate_data?.total_regular_pay}</strong></td>
                                                            {/* <td className='total-hrs'><strong>$30.00</strong></td> */}
                                                            <td className='total-hrs'><strong>${details?.aggregate_data?.total_pay}</strong></td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </>
                                        )
                                    })
                                    :
                                    <div className='text-center mt-3'><h3>No Data Found</h3></div>}
                            </Card.Body>
                        </Card>
                        <div>
                            <p><strong>Note:</strong> Use increments of 0.25 (15 minutes) when recording hours.</p>
                        </div>
                    </Col>
                </div>
            </div>
            <Modal
                className="fade bd-example-modal-sm"
                size="lg"
                show={addModal}
                onHide={() => setAddModal(false)}
            >
                <div className="add-user-modal-form-view client-modal">
                    <button onClick={() => setAddModal(false)} className="modal-cross-btn"><i class="fa fa-times" aria-hidden="true"></i></button>
                    <div className="modal-title-view">
                        <h3>{action == 1 ? "Add" : "Update"} Timesheet</h3>
                    </div>
                    <div className="basic-form">
                        <form onSubmit={onUpdateData}>
                            <div className="row">

                                <div className="form-group form-group-modal-custom mb-3 col-6">
                                    <label className="mb-1">
                                        <strong>Client</strong>
                                    </label>
                                    <div className="select-drop-down">
                                        <span><i class="fa fa-angle-down"></i></span>
                                        <select
                                            defaultValue={"option"}
                                            className="form-control select-control"
                                            value={client}
                                            onChange={(e) => onClientHandler(e.target.value)}>
                                            <option value=""></option>
                                            {
                                                props.clientList.map((item, index) => {
                                                    return <option key={index} value={item._id}>{item.first_name}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                    {errors.client && <div className="text-danger fs-12">{errors.client}</div>}
                                </div>

                                <div className="form-group form-group-modal-custom mb-3 col-6">
                                    <label className="mb-1">
                                        <strong>Project</strong>
                                    </label>
                                    <div className="select-drop-down">
                                        <span><i class="fa fa-angle-down"></i></span>
                                        <select
                                            defaultValue={"option"}
                                            className="form-control select-control"
                                            value={project}
                                            onChange={(e) => onProjectHandler(e.target.value)}>
                                            <option value=""></option>
                                            {
                                                props.projectList.map((item, index) => {
                                                    return <option key={index} value={item._id}>{item.title}</option>
                                                })
                                            }
                                        </select>
                                        {errors.project && <div className="text-danger fs-12">{errors.project}</div>}
                                    </div>
                                </div>

                                <div className="form-group form-group-modal-custom mb-3 col-6">
                                    <label className="mb-1">
                                        <strong>Activity</strong>
                                    </label>
                                    <div className="select-drop-down">
                                        <span><i class="fa fa-angle-down"></i></span>
                                        <select
                                            defaultValue={"option"}
                                            className="form-control select-control"
                                            value={activity}
                                            onChange={(e) => onActivityHander(e.target.value)}>
                                            <option value=""></option>
                                            {
                                                props.activitiesList.map((item, index) => {
                                                    return <option key={index} value={item._id}>{item.title}</option>
                                                })
                                            }
                                        </select>
                                        {errors.activity && <div className="text-danger fs-12">{errors.activity}</div>}
                                    </div>
                                </div>

                                <div className="form-group form-group-modal-custom mb-3 col-6">
                                    <label className="mb-1">
                                        <strong>Task</strong>
                                    </label>
                                    <div className="select-drop-down">
                                        <span><i class="fa fa-angle-down"></i></span>
                                        <select
                                            defaultValue={"option"}
                                            className="form-control select-control"
                                            value={task}
                                            onChange={(e) => setTask(e.target.value)}>
                                            <option value=""></option>
                                            {
                                                props.taskList.map((item, index) => {
                                                    return <option key={index} value={item._id}>{item.title}</option>
                                                })
                                            }
                                        </select>
                                        {errors.task && <div className="text-danger fs-12">{errors.task}</div>}
                                    </div>
                                </div>

                                <div className="form-group form-group-modal-custom mb-3 col-6">
                                    <label className="mb-1">
                                        <strong>Start time</strong>
                                    </label>
                                    <div className="form-calendar">
                                        <DatePicker
                                            ref={startDateRef}
                                            selected={startDate}
                                            timeInputLabel="Time:"
                                            className="form-control"
                                            placeholderText=""
                                            dateFormat="MM/dd/yyyy h:mm aa"
                                            showTimeInput
                                            onChange={(date) => setStartDate(date)}
                                        />
                                        <button type='button' className="form-calendar-btn"
                                            onClick={() => startDateRef.current.setOpen(true)}
                                        >
                                            <i class="fa-regular fa-calendar-days"></i>
                                        </button>
                                    </div>
                                    {errors.startDate && <div className="text-danger fs-12">{errors.startDate}</div>}
                                </div>

                                <div className="form-group form-group-modal-custom mb-3 col-6">
                                    <label className="mb-1">
                                        <strong>End time</strong>
                                    </label>
                                    <div className="form-calendar">
                                        <DatePicker
                                            ref={endDateRef}
                                            selected={endDate}
                                            timeInputLabel="Time:"
                                            className="form-control"
                                            placeholderText=""
                                            dateFormat="MM/dd/yyyy h:mm aa"
                                            showTimeInput
                                            onChange={(date) => setEndDate(date)}
                                        />
                                        <button type='button' className="form-calendar-btn"
                                            onClick={() => endDateRef.current.setOpen(true)}
                                        >
                                            <i class="fa-regular fa-calendar-days"></i>
                                        </button>
                                    </div>
                                    {errors.endDate && <div className="text-danger fs-12">{errors.endDate}</div>}
                                </div>

                                <div className="form-group mb-3 col-12">
                                    <label className="mb-1">
                                        <strong>Description</strong>
                                    </label>
                                    <textarea
                                        className="form-control"
                                        rows={2}
                                        //placeholder='Description'
                                        value={description}
                                        onChange={(e) => setDescription(e.target.value)} />
                                    {errors.description && <div className="text-danger fs-12">{errors.description}</div>}
                                </div>

                            </div>

                            <div className="modal-button-group mt-4">
                                <button type="button" onClick={() => setAddModal(false)} className="btn form-cencel-btn">
                                    Cancel
                                </button>
                                <button type="submit" className="btn btn-primary role-form-btn">
                                    {action == 1 ? "Add" : "Update"}
                                </button>
                            </div>
                        </form>
                    </div>

                </div>
            </Modal>
            <Modal
                className="fade bd-example-modal-sm"
                size="md"
                show={filterModal}
                onHide={() => setFilterModal(false)}
            >
                <div className="add-user-modal-form-view">
                    <button onClick={() => setFilterModal(false)} className="modal-cross-btn"><i class="fa fa-times" aria-hidden="true"></i></button>
                    <div className="modal-title-view">
                        <h3>Filter</h3>
                    </div>
                    <div className="basic-form">
                        <form onSubmit={onFilterSubmit}>
                            <div className="row">

                                <div className="form-group form-group-modal-custom mb-3">
                                    <label className="mb-1">
                                        <strong>Client</strong>
                                    </label>
                                    <div className="select-drop-down">
                                        <span><i class="fa fa-angle-down"></i></span>
                                        <select
                                            defaultValue={"option"}
                                            className="form-control select-control"
                                            value={filterClient}
                                            onChange={(e) => onFilterClientHandler(e.target.value)}>
                                            <option value=""></option>
                                            {
                                                props.clientList.map((item, index) => {
                                                    return <option key={index} value={item._id}>{item.first_name}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>

                                <div className="form-group form-group-modal-custom mb-3">
                                    <label className="mb-1">
                                        <strong>Project</strong>
                                    </label>
                                    <div className="select-drop-down">
                                        <span><i class="fa fa-angle-down"></i></span>
                                        <select
                                            defaultValue={"option"}
                                            className="form-control select-control"
                                            value={filterProject}
                                            onChange={(e) => setFilterProject(e.target.value)}>
                                            <option value=""></option>
                                            {
                                                props.projectList.map((item, index) => {
                                                    return <option key={index} value={item._id}>{item.title}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>

                            </div>

                            <div className="modal-button-group mt-4">
                                <button type="button" disabled={filterClient || filterProject ? false : true} onClick={() => onClearFilterHandler()} className="btn form-cencel-btn">
                                    Clear Filter
                                </button>
                                <button type="submit" disabled={filterClient || filterProject ? false : true} className="btn btn-primary role-form-btn">
                                    Submit
                                </button>
                            </div>
                        </form>
                    </div>

                </div>
            </Modal>
            <DateRangeModal
                show={isDateRange}
                onClose={() => setIsDateRange(false)}
            />
            <SheetReviewModal
                title={"Are you soure ?"}
                description={"you want to add time"}
                show={reviewModal}
                onClose={() => onReviewCloseHandler()}
                onSubmit={onFinalSubmitHandler}
            />
            <ChatBox
                show={isNote}
                data={noteData}
                value={message}
                onChangeHandler={(val) => setMessage(val)}
                onSubmitHandler={() => onSubmitMessageHandler()}
                closeHandler={closeHandler}
                loading={loading}
                currentUser={props.userId}
            />
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        screenVisible: state.clients.screenVisible,
        showLoading: state.clients.showLoading,
        clientList: state.clients.clientList,
        projectList: state.project.projectList,
        activitiesList: state.project.activitiesList,
        taskList: state.project.taskList,
        countryList: state.country.countryList,
        timeCardDetails: state.timeCard.details,
        configDetails: state.systemconfig.configDetails,
        systemAdmin: state.auth.systemAdmin,
        projectDetails: state.project.projectDetails,
        userId: state.auth.userId,
    };
};
export default connect(mapStateToProps)(CartDetails);