import React, { Fragment, useContext, useEffect, useState, useRef } from "react";
import { connect, useDispatch } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import {
    Row,
    Col,
    Card,
    Table,
    Modal,
    Button,
    Accordion,
    Spinner
} from "react-bootstrap";
import Select from "react-select";
import { verifyEmail } from '../../../services/EmployeeService';
import { validateEmail, validatePhone, validatePassword } from '../../../utility/Utility';
import { getCountryList, getStateList, getCityList, countryLoadingAction } from '../../../store/actions/CountryAction';
import { getProjectByClientIdList, } from '../../../store/actions/ProjectActions';

const AddClient = (props) => {

    const dispatch = useDispatch();
    const history = useHistory();

    let errorsObj = { alt: '', orId: '', orName: '', orAddress: '', email: '', mobile: '', status: '', pws: '', comPws: '', username: '', bAddress: '', address: '', appName: '', appEmail: '', appCode: '', appMobile: '', resName: '', resEmail: '', resCode: '', resMobile: '', country: '', state: '', city: '', zip: '', idType: '', typeNo: '', otherType: '',nature:'' };

    const [errors, setErrors] = useState(errorsObj);
    const [taxId, setTaxId] = useState('');
    const [idType, setIdType] = useState('');
    const [otherType, setOtherType] = useState('');
    const [typeNo, setTypeNo] = useState('');
    const [alt, setAlt] = useState('');
    const [organizationName, setOrganizationName] = useState('');
    const [organizationAdd, setOrganizationAdd] = useState('');
    const [organizationId, setOrganizationId] = useState('');
    const [clientType, setClientType] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [status, setStatus] = useState('');
    const [username, setUsername] = useState('');
    const [addressOne, setAddressOne] = useState('');
    const [addressTwo, setAddressTwo] = useState('');
    const [countryCode, setCountryCode] = useState('');
    const [country, setCountry] = useState('');
    const [countryState, setCountryState] = useState('');
    const [city, setCity] = useState('');
    const [zip, setZip] = useState('');
    const [password, setPassword] = useState('');
    const [comPassword, setComPassword] = useState('');
    const [recodeId, setRecodeId] = useState('');
    const [clientId, setClientId] = useState('');

    const [pws, setPws] = useState(false);
    const [conPws, setConPws] = useState(false);
    const [billingAddress, setBillingAddress] = useState('');
    const [approverName, setApproverName] = useState('');
    const [approverEmail, setApproverEmail] = useState('');
    const [approverMobileCode, setApproverMobileCode] = useState('');
    const [approverMobile, setApproverMobile] = useState('');
    const [reviewerName, setReviewerName] = useState('');
    const [reviewerEmail, setReviewerEmail] = useState('');
    const [reviewerMobileCode, setReviewerMobileCode] = useState('');
    const [reviewerMobile, setReviewerMobile] = useState('');
    const [approver, setApprover] = useState(false);
    const [reviewer, setReviewer] = useState(false);
    const [billing, setBilling] = useState(false);
    const [accOne, setAccOne] = useState(false);
    const [accTwo, setAccTwo] = useState(false);
    const [accThree, setAccThree] = useState(false);
    const [accFour, setAccFour] = useState(false);
    const [accFive, setAccFive] = useState(false);
    const [validEmail, setValidEmail] = useState(false);

    useEffect(() => {
        resetValue();
    }, [props.show]);

    useEffect(() => {
        dispatch(getCountryList(props.history));
    }, []);

    const resetValue = () => {
        const errorObj = { ...errorsObj };
        setErrors(errorObj);
        if (props.action === 1) {
            setTaxId('');
            setIdType('');
            setTypeNo('');
            setOtherType('');
            setOrganizationId('');
            setAlt('');
            setOrganizationName('');
            setOrganizationAdd('');
            setUsername('');
            setEmail('');
            setMobile('');
            setStatus('');
            setCountryCode('');
            setBillingAddress('');
            setApproverName('');
            setApproverEmail('');
            setApproverMobileCode('');
            setApproverMobile('');
            setReviewerName('');
            setReviewerEmail('');
            setReviewerMobileCode('');
            setReviewerMobile('');
            setZip('');
            setAddressOne('');
            setClientType("");
        } else {
            dispatch(getProjectByClientIdList(props.data._id, props.history));
            setRecodeId(props.data._id);
            setClientId(props.data.other_details[0]?.client_id);
            setTaxId(props.data.other_details[0]?.tax_id);
            setTypeNo(props.data.other_details[0]?.tax_no ? props.data.other_details[0]?.tax_no : '');
            setOtherType(props.data.other_details[0]?.other_id_type ? props.data.other_details[0]?.other_id_type : '');
            setOrganizationId(props.data.other_details[0]?.organization_id ? props.data.other_details[0]?.organization_id : '');
            setIdType(props.data.other_details[0]?.id_type);
            setClientType(props.data.other_details[0]?.type);
            setAlt(props.data.other_details[0].client_alt);
            setUsername(props.data?.first_name);
            setOrganizationName(props.data.other_details[0].organization_name);
            setOrganizationAdd(props.data.other_details[0].organization_address);
            setAddressOne(props.data?.address1);
            setAddressTwo(props.data?.address2);
            setCountry(props.data?.country);
            setCountryState(props.data?.state);
            setCity(props.data?.city);
            setZip(props.data?.zipcode);
            setEmail(props.data.email);
            setMobile(props.data.phone_no);
            setStatus(props.data.status);
            setCountryCode(props.data.country_code);
            setBillingAddress(props.data.other_details[0]?.billing_address);
            setApproverName(props.data.other_details[0]?.approver_name);
            setApproverEmail(props.data.other_details[0]?.approver_email);
            setApproverMobileCode(props.data.other_details[0]?.approver_country_code);
            setApproverMobile(props.data.other_details[0]?.approver_mobile_no);
            setReviewerName(props.data.other_details[0]?.reviewer_name);
            setReviewerEmail(props.data.other_details[0]?.reviewer_email);
            setReviewerMobileCode(props.data.other_details[0]?.reviewer_country_code);
            setReviewerMobile(props.data.other_details[0]?.reviewer_mobile_no);

        }
        setAccTwo(false);
        setAccOne(false);
        setAccThree(false);
        setAccFour(false);
    }

    function onSubmit(e) {

        e.preventDefault();
        let error = false;
        const errorObj = { ...errorsObj };

        if (organizationId.trim() === '') {
            errorObj.orId = 'Client Id is Required';
            error = true;
        }

        if (organizationName.trim() === '') {
            errorObj.orName = 'Client name is Required';
            error = true;
        }
        if (organizationAdd.trim() === '') {
            errorObj.orAddress = 'Address is Required';
            error = true;
        }
        if (alt === '') {
            errorObj.alt = 'Alt id is Required';
            error = true;
        } else if (alt.length !== 10) {
            errorObj.alt = 'Alt ID must be exactly 10 characters long.';
            error = true;
        }
        if (email.trim() === '') {
            errorObj.email = 'Email is Required';
            error = true;
        } else if (validateEmail(email) === true || validEmail) {
            errorObj.email = 'Invalid Email Id';
            error = true;
        }
        if (mobile.trim() === '') {
            errorObj.mobile = 'Mobile is Required';
            error = true;
        } else if (validatePhone(mobile) === false) {
            errorObj.mobile = 'Invalid Number';
            error = true;
        }
        if (clientType === '') {
            errorObj.nature = 'Type is Required';
            error = true;
        }
        if (idType === '') {
            errorObj.idType = 'Entity type is Required';
            error = true;
        }
        if (typeNo === '') {
            errorObj.typeNo = 'ID is Required';
            error = true;
        }
        if (idType === "other" && otherType === '') {
            errorObj.otherType = 'Other Entity type is Required';
            error = true;
        }
        if (username.trim() === '') {
            errorObj.username = 'Username is Required';
            error = true;
        }
        if (password.trim() === '' && props.action == 1) {
            errorObj.pws = 'Password is Required';
            error = true;
        } else if (validatePassword(password).result == false && props.action == 1) {
            errorObj.pws = validatePassword(password).error;
            error = true;
        }
        if (comPassword.trim() === '' && props.action == 1) {
            errorObj.comPws = 'Confirm  Password is Required';
            error = true;
        } else if (password !== comPassword) {
            errorObj.comPws = 'Password and confirm password does not match';
            error = true;
        }
        if (country === '') {
            errorObj.country = 'Country is Required';
            error = true;
        }
        if (countryState === '') {
            errorObj.state = 'State is Required';
            error = true;
        }
        if (city === '') {
            errorObj.city = 'city is Required';
            error = true;
        }
        if (status === '') {
            errorObj.status = 'Status is Required';
            error = true;
        }
        if (zip === '') {
            errorObj.zip = 'Postal code is Required';
            error = true;
        }
        if (approver && approverName.trim() === '') {
            errorObj.appName = 'Approver name is Required';
            error = true;
        }
        if (approver && approverEmail.trim() === '') {
            errorObj.appEmail = 'Approver email is Required';
            error = true;
        }
        if (approver && approverMobileCode === '') {
            errorObj.appCode = 'Country code is Required';
            error = true;
        }
        if (approver && approverMobile.trim() === '') {
            errorObj.appMobile = 'Mobile is Required';
            error = true;
        } else if (approver && validatePhone(approverMobile) === false) {
            errorObj.appMobile = 'Invalid Number';
            error = true;
        }
        if (reviewer && reviewerName.trim() === '') {
            errorObj.resName = 'Reviewer name is Required';
            error = true;
        }
        if (reviewer && reviewerEmail.trim() === '') {
            errorObj.resEmail = 'Reviewer email is Required';
            error = true;
        }
        if (reviewer && reviewerMobileCode === '') {
            errorObj.resCode = 'Country code is Required';
            error = true;
        }
        if (reviewer && reviewerMobile.trim() === '') {
            errorObj.resMobile = 'Mobile is Required';
            error = true;
        } else if (reviewer && validatePhone(reviewerMobile) === false) {
            errorObj.resMobile = 'Invalid Number';
            error = true;
        }
        if (billing && billingAddress === '') {
            errorObj.bAddress = 'Billing address is Required';
            error = true;
        }
        if (error && (organizationAdd == '' || country == "" || countryState == "" || city == "" || zip == "" || mobile == "")) {
            setAccFive(true);
        }
        if (error && (idType == '' || typeNo == "" || (idType === "other" && otherType === ''))) {
            setAccFour(true);
        }
        if (error && comPassword == '') {
            setAccOne(true);
        }
        if (error && (approver || reviewer || billing)) {
            setAccTwo(true);
        }
        setErrors(errorObj);
        if (error) {
            return;
        }

        let obj = {
            'recodeId': recodeId,
            'clientId': clientId,
            'taxId': '',
            'idType': idType,
            'other_id_type': otherType,
            'tax_no': typeNo,
            'alt': alt,
            'username': username,
            'organization_id': organizationId,
            'organization_name': organizationName,
            'organization_address': organizationAdd,
            'address1': addressOne,
            'address2': addressTwo,
            'country': country,
            'state': countryState,
            'city': city,
            'zipcode': zip,
            'email': email,
            'mobile': mobile,
            'status': status,
            'comPassword': comPassword,
            'countryCode': countryCode,
            'approverName': approverName,
            'approverEmail': approverEmail,
            'approverMobileCode': approverMobileCode,
            'approverMobile': approverMobile,
            'reviewerName': reviewerName,
            'reviewerEmail': reviewerEmail,
            'reviewerMobileCode': reviewerMobileCode,
            'reviewerMobile': reviewerMobile,
            'billingAddress': billingAddress,
            'type':clientType
        };
        props.submitHandler(obj);
        setAlt('');
        setTaxId('');
        setIdType('');
        setTypeNo('');
        setOtherType('');
        setOrganizationId('');
        setOrganizationName('');
        setOrganizationAdd('');
        setEmail('');
        setMobile('');
        setStatus('');
        setPassword('');
        setComPassword('');
        setRecodeId('');
        setCountryCode('');
        setBillingAddress('');
        setApproverName('');
        setApproverEmail('');
        setApproverMobileCode('');
        setApproverMobile('');
        setReviewerName('');
        setReviewerEmail('');
        setReviewerMobileCode('');
        setReviewerMobile('');
        setApprover(false);
        setReviewer(false);
        setBilling(false);
        setAccOne(false);
        setAccTwo(false);
    }

    const updatedCountries = props.countryList.map((country) => ({
        label: country.phone_code,
        value: country.phone_code,
        ...country
    }));

    const getCountries = props.countryList.map((country) => ({
        label: country.name,
        value: country.name,
        ...country
    }));

    const updatedStates = props.stateList.map((state) => ({
        label: state.name,
        value: state.name,
        ...state
    }));

    const updatedCitys = props.cityList.map((state) => ({
        label: state.name,
        value: state.name,
        ...state
    }));

    const onChangecountry = (option) => {
        setCountry(option.name);
        setCountryCode(option.phone_code);
        setCountryState('');
        setCity('');
        dispatch(getStateList(parseInt(option.id), props.history));
    }

    const onChangeState = (option) => {
        setCountryState(option.name);
        setCity('');
        dispatch(getCityList(parseInt(option.id), props.history));
    }

    const onChangeCity = (option) => {
        setCity(option.name)
    }

    const onChangeApproverCountry = (option) => {
        setApproverMobileCode(option.phone_code);
    }

    const onChangeReviewerCountry = (option) => {
        setReviewerMobileCode(option.phone_code);
    }

    const onCollapseHandler = () => {
        setAccOne(!accOne);
        setAccTwo(accOne && accTwo && accThree && accFour ? true : false);
        setAccThree(accOne && accTwo && accThree && accFour ? true : false);
        setAccFour(accOne && accTwo && accThree && accFour ? true : false);
    }
    const onCollapseHandler2 = () => {
        setAccTwo(!accTwo);
        setAccOne(accOne && accTwo && accThree && accFour ? true : false);
        setAccThree(accOne && accTwo && accThree && accFour ? true : false);
        setAccFour(accOne && accTwo && accThree && accFour ? true : false);
    }
    const onCollapseHandler3 = () => {
        setAccThree(!accThree);
        setAccOne(accOne && accTwo && accThree && accFour ? true : false);
        setAccTwo(accOne && accTwo && accThree && accFour ? true : false);
        setAccFour(accOne && accTwo && accThree && accFour ? true : false);
    }

    const onCollapseHandler4 = () => {
        setAccFour(!accFour);
        setAccOne(accOne && accTwo && accThree && accFour ? true : false);
        setAccTwo(accOne && accTwo && accThree && accFour ? true : false);
        setAccThree(accOne && accTwo && accThree && accFour ? true : false);
    }

    const onCollapseHandler5 = () => {
        setAccFive(!accFive);
        setAccFour(accOne && accTwo && accThree && accFour ? true : false);
        setAccOne(accOne && accTwo && accThree && accFour ? true : false);
        setAccTwo(accOne && accTwo && accThree && accFour ? true : false);
        setAccThree(accOne && accTwo && accThree && accFour ? true : false);
    }

    const ononKeyUpEmailHandler = (email) => {
        verifyEmail(email.trim())
            .then((response) => {
                let errorObj = { ...errorsObj };
                errorObj.email = response.data.status == 400 ? response.data.msg : '';
                setValidEmail(response.data.status == 400 ? true : false);
                setErrors(errorObj);
            })
            .catch((error) => {
                let errorObj = { ...errorsObj };
                errorObj.email = "";
                setErrors(errorObj);
                setValidEmail(true);
                //console.log(error);
            });
    }

    const onSetAss = (check) => {
        if (check) {
            setBillingAddress(organizationAdd);
        } else {
            setBillingAddress('');
        }
    }

    const onAltHandler = (val) => {
        if (val.trim().length <= 10) {
            setAlt(val.trim());
        }

    }

    return (
        <Fragment>
            <Modal
                className="fade bd-example-modal-sm"
                size="lg"
                onHide={props.closeHandler}
                show={props.show}
            >
                <div className="add-user-modal-form-view client-modal">
                    <button onClick={props.closeHandler} className="modal-cross-btn"><i class="fa fa-times" aria-hidden="true"></i></button>
                    <div className="client-title-view">
                        <h3>Client Info</h3>
                    </div>
                    <div className="basic-form">

                        <form onSubmit={onSubmit}>
                            <div className="row">

                            <div className="form-group form-group-modal-custom mb-3 col-6 ps-1">
                                    <label className="mb-1">
                                        Client Id<span className="ascetic-text">*</span>
                                    </label>
                                    <input className="form-control" value={organizationId} onChange={(e) => setOrganizationId(e.target.value)} />
                                    {errors.orId && <div className="text-danger fs-12">{errors.orId}</div>}
                                </div>
                                
                                <div className="form-group form-group-modal-custom mb-3 col-6">
                                    <label className="mb-1">
                                        Client Name<span className="ascetic-text">*</span>
                                    </label>
                                    <input className="form-control" value={organizationName} onChange={(e) => setOrganizationName(e.target.value)} />
                                    {errors.orName && <div className="text-danger fs-12">{errors.orName}</div>}
                                </div>

                                <div className="form-group form-group-modal-custom mb-3 col-6 pe-1">
                                    <label className="mb-1">
                                        Alt Id<span className="ascetic-text">*</span>
                                    </label>
                                    <input type="text" className="form-control" value={alt} onChange={(e) => onAltHandler(e.target.value)} />
                                    {errors.alt && <div className="text-danger fs-12">{errors.alt}</div>}
                                </div>

                                {/* {props.action == 2 ? */}
                                    <div className="form-group form-group-modal-custom mb-3 col-6">
                                        <label className="mb-1">
                                            Status
                                        </label>
                                        <div className="select-drop-down">
                                            <span><i class="fa fa-angle-down"></i></span>
                                            <select
                                                defaultValue={"option"}
                                                class="form-control select-control"
                                                value={status}
                                                onChange={(e) => setStatus(e.target.value)}>
                                                <option></option>
                                                <option value="1">Active</option>
                                                <option value="0">Inactive</option>
                                                <option value="2">Pending</option>
                                            </select>
                                        </div>
                                        {errors.status && <div className="text-danger fs-12">{errors.status}</div>}
                                    </div>
                                    {/* // : null} */}

                                    <div className="form-group form-group-modal-custom mb-3 col-6">
                                        <label className="mb-1">
                                        Type
                                        </label>
                                        <div className="select-drop-down">
                                            <span><i class="fa fa-angle-down"></i></span>
                                            <select
                                                defaultValue={"option"}
                                                class="form-control select-control"
                                                value={clientType}
                                                onChange={(e) => setClientType(e.target.value)}>
                                                <option value=""></option>
                                                <option value="1">Individual</option>
                                                <option value="2">Organization</option>
                                            </select>
                                        </div>
                                        {errors.nature && <div className="text-danger fs-12">{errors.nature}</div>}
                                    </div>

                                <div className="account-container">

                                    <div
                                        className={accFive ? 'account-item account-item-active' : 'account-item'}
                                    >
                                        <div
                                            className={accFive ? 'account-button account-active' : 'account-button'}
                                            onClick={onCollapseHandler5}
                                        ><h3>Contact Info</h3><span>
                                                {accFive ? <i class="fa fa-caret-down" aria-hidden="true"></i> : <i class="fa fa-caret-right" aria-hidden="true"></i>}
                                            </span></div>
                                        <div className={accFive ? 'account-collapse show' : 'account-collapse'}>
                                            <div className="row">

                                                <div className="form-group form-group-modal-custom mb-3 col-6">
                                                    <label className="mb-1">
                                                        Address 1<span className="ascetic-text">*</span>
                                                    </label>
                                                    <input className="form-control" value={organizationAdd} onChange={(e) => setOrganizationAdd(e.target.value)} />
                                                    {errors.orAddress && <div className="text-danger fs-12">{errors.orAddress}</div>}
                                                </div>
                                                <div className="form-group form-group-modal-custom mb-3 col-6">
                                                    <label className="mb-1">
                                                        Address 2
                                                    </label>
                                                    <input className="form-control" value={addressOne} onChange={(e) => setAddressOne(e.target.value)} />
                                                    {errors.address && <div className="text-danger fs-12">{errors.address}</div>}
                                                </div>

                                                <div className="form-group form-group-modal-custom client-country mb-3 col-6">
                                                    <label className="mb-1">
                                                        Country<span className="ascetic-text">*</span>
                                                    </label>
                                                    <Select
                                                        id="country"
                                                        name="country"
                                                        label="country code"
                                                        classNames="select-custom-form"
                                                        options={getCountries}
                                                        defaultInputValue={props.action === 1 ? "" : props.data?.country}
                                                        placeholder=""
                                                        onChange={onChangecountry}
                                                    />
                                                    {errors.country && <div className="text-danger fs-12">{errors.country}</div>}
                                                </div>
                                                <div className="form-group form-group-modal-custom client-country mb-3 col-6">
                                                    <label className="mb-1">
                                                        State<span className="ascetic-text">*</span>
                                                    </label>
                                                    <Select
                                                        id="state"
                                                        name="state"
                                                        label="state"
                                                        classNames="select-custom-form"
                                                        options={updatedStates}
                                                        defaultInputValue={props.action === 1 ? "" : props.data?.state}
                                                        placeholder=""
                                                        onChange={onChangeState}
                                                    />
                                                    {errors.state && <div className="text-danger fs-12">{errors.state}</div>}
                                                </div>
                                                <div className="form-group form-group-modal-custom client-country mb-3 col-6">
                                                    <label className="mb-1">
                                                        City<span className="ascetic-text">*</span>
                                                    </label>
                                                    <Select
                                                        id="city"
                                                        name="city"
                                                        label="city"
                                                        classNames="select-custom-form"
                                                        options={updatedCitys}
                                                        defaultInputValue={props.action === 1 ? "" : props.data.city}
                                                        placeholder=""
                                                        onChange={onChangeCity}
                                                    />
                                                    {errors.city && <div className="text-danger fs-12">{errors.city}</div>}
                                                </div>
                                                <div className="form-group form-group-modal-custom mb-3 col-6">
                                                    <label className="mb-1">
                                                        Postal Code<span className="ascetic-text">*</span>
                                                    </label>
                                                    <input className="form-control" value={zip} onChange={(e) => setZip(e.target.value)} />
                                                    {errors.zip && <div className="text-danger fs-12">{errors.zip}</div>}
                                                </div>

                                                <div className="form-group col-6 mb-3">
                                                    <label className="mb-1">
                                                        Mobile<span className="ascetic-text">*</span>
                                                    </label>
                                                    <div className="phone-number-form-group">
                                                        <input type="text" class="form-country-code-control" disabled value={countryCode ? countryCode : ""} />
                                                        <input type="text" class="phone-control" value={mobile} onChange={(e) => setMobile(e.target.value)}
                                                        />

                                                    </div>
                                                    {errors.mobile && <div className="text-danger fs-12">{errors.mobile}</div>}
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        className={accFour ? 'account-item account-item-active' : 'account-item'}
                                    >
                                        <div
                                            className={accFour ? 'account-button account-active' : 'account-button'}
                                            onClick={onCollapseHandler4}
                                        ><h3>Tax Info</h3><span>
                                                {accFour ? <i class="fa fa-caret-down" aria-hidden="true"></i> : <i class="fa fa-caret-right" aria-hidden="true"></i>}
                                            </span></div>
                                        <div className={accFour ? 'account-collapse show' : 'account-collapse'}>
                                            <div className="row">

                                                <div className="form-group form-group-modal-custom mb-3 col-6">
                                                    <label className="mb-1">
                                                    Entity type<span className="ascetic-text">*</span>
                                                    </label>
                                                    <div className="select-drop-down">
                                                        <span><i class="fa fa-angle-down"></i></span>
                                                        <select
                                                            defaultValue={"option"}
                                                            className="form-control select-control"
                                                            value={idType}
                                                            onChange={(e) => setIdType(e.target.value)}>
                                                            <option value=""></option>
                                                            <option value="SSN">SSN (Social Security number)</option>
                                                            <option value="EIN">EIN (Employer Identification Number)</option>
                                                            <option value="ITIN">ITIN (Individual Taxpayer Identification Number)</option>
                                                            <option value="TFN">TFN (Tax file number)</option>
                                                            <option value="VAT">TFN (Value added tax)</option>
                                                            <option value="other">Other</option>
                                                        </select>
                                                    </div>
                                                    {errors.idType && <div className="text-danger fs-12">{errors.idType}</div>}
                                                </div>

                                                {idType === "other" ?
                                                    <div className="form-group form-group-modal-custom mb-3 col-6">
                                                        <label className="mb-1">
                                                            Other Entity type<span className="ascetic-text">*</span>
                                                        </label>
                                                        <input className="form-control" value={otherType} onChange={(e) => setOtherType(e.target.value)} />
                                                        {errors.otherType && <div className="text-danger fs-12">{errors.otherType}</div>}
                                                    </div>
                                                    : null}
                                                <div className="form-group form-group-modal-custom mb-3 col-6">
                                                    <label className="mb-1">
                                                        ID<span className="ascetic-text">*</span>
                                                    </label>
                                                    <input className="form-control" disabled={idType ? false : true} value={typeNo} onChange={(e) => setTypeNo(e.target.value)} />
                                                    {errors.typeNo && <div className="text-danger fs-12">{errors.typeNo}</div>}
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    {props.action == 1 ?
                                        <div
                                            className={accOne ? 'account-item account-item-active' : 'account-item'}
                                        >
                                            <div
                                                className={accOne ? 'account-button account-active' : 'account-button'}
                                                onClick={onCollapseHandler}
                                            ><h3>Security</h3><span>
                                                    {accOne ? <i class="fa fa-caret-down" aria-hidden="true"></i> : <i class="fa fa-caret-right" aria-hidden="true"></i>}
                                                </span></div>
                                            <div className={accOne ? 'account-collapse show' : 'account-collapse'}>

                                                <div className="row">

                                                    <div className="form-group form-group-modal-custom mb-3 col-6">
                                                        <label className="mb-1">
                                                            Username<span className="ascetic-text">*</span>
                                                        </label>
                                                        <input className="form-control" value={username} onChange={(e) => setUsername(e.target.value)} />
                                                        {errors.username && <div className="text-danger fs-12">{errors.username}</div>}
                                                    </div>

                                                    <div className="form-group form-group-modal-custom mb-3 col-6">
                                                        <label className="mb-1">
                                                            Email<span className="ascetic-text">*</span>
                                                        </label>
                                                        <input className="form-control"
                                                            value={email}
                                                            readOnly={props.action == 2 ? true : false}
                                                            onKeyUp={(e) => ononKeyUpEmailHandler(e.target.value)}
                                                            onChange={(e) => setEmail(e.target.value)} />
                                                        {errors.email && <div className="text-danger fs-12">{errors.email}</div>}
                                                    </div>

                                                    <div className="form-group form-group-modal-custom mb-3 col-6">
                                                        <label className="mb-1">
                                                            Password<span className="ascetic-text">*</span>
                                                        </label>
                                                        <div className="form-group-pws">
                                                            <input type={pws ? 'text' : 'password'} className="form-control" value={password} onChange={(e) => setPassword(e.target.value)} />
                                                            <div className="pws-icon-view" onClick={() => setPws(!pws)}>
                                                                {pws ?
                                                                    <i class="bi bi-eye"></i>
                                                                    :
                                                                    <i class="bi bi-eye-slash"></i>
                                                                }
                                                            </div>
                                                        </div>
                                                        {errors.pws && <div className="text-danger fs-12">{errors.pws}</div>}
                                                    </div>

                                                    <div className="form-group form-group-modal-custom mb-3 col-6">
                                                        <label className="mb-1">
                                                            Confirm Password<span className="ascetic-text">*</span>
                                                        </label>
                                                        <div className="form-group-pws">
                                                            <input type={conPws ? 'text' : 'password'} className="form-control" value={comPassword} onChange={(e) => setComPassword(e.target.value)} />
                                                            <div className="pws-icon-view" onClick={() => setConPws(!conPws)}>
                                                                {conPws ?
                                                                    <i class="bi bi-eye"></i>
                                                                    :
                                                                    <i class="bi bi-eye-slash"></i>
                                                                }
                                                            </div>
                                                        </div>
                                                        {errors.comPws && <div className="text-danger fs-12">{errors.comPws}</div>}
                                                    </div>


                                                </div>
                                            </div>
                                        </div>
                                        : null}

                                    <div
                                        className={accTwo ? 'account-item account-item-active' : 'account-item'}
                                    >
                                        <div
                                            className={accTwo ? 'account-button account-active' : 'account-button'}
                                            onClick={onCollapseHandler2}
                                        ><h3>Client Options</h3><span>
                                                {accTwo ? <i class="fa fa-caret-down" aria-hidden="true"></i> : <i class="fa fa-caret-right" aria-hidden="true"></i>}
                                            </span></div>
                                        <div className={accTwo ? 'account-collapse show' : 'account-collapse'}>
                                            <div className="row">

                                                <div className="form-check custom-checkbox acco-check col-12">
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="approver-check"
                                                    >Approver</label>
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        id="approver-check"
                                                        onClick={(e) => setApprover(e.target.checked)}
                                                    />
                                                </div>
                                                <div className="form-group form-group-modal-custom mb-3 col-6">
                                                    <label className="mb-1">
                                                        Name
                                                    </label>
                                                    <input className="form-control" value={approverName} onChange={(e) => setApproverName(e.target.value)} />
                                                    {errors.appName && <div className="text-danger fs-12">{errors.appName}</div>}
                                                </div>

                                                <div className="form-group form-group-modal-custom mb-3 col-6">
                                                    <label className="mb-1">
                                                        Email
                                                    </label>
                                                    <input className="form-control" value={approverEmail} onChange={(e) => setApproverEmail(e.target.value)} />
                                                    {errors.appEmail && <div className="text-danger fs-12">{errors.appEmail}</div>}
                                                </div>

                                                <div className="form-group form-group-modal-custom client-country mb-3 pe-0 col-6">
                                                    <label className="mb-1">
                                                        Country Code
                                                    </label>
                                                    <Select
                                                        id="country"
                                                        name="country"
                                                        label="country code"
                                                        classNames="select-custom-form"
                                                        options={updatedCountries}
                                                        defaultInputValue={approverMobileCode}
                                                        placeholder=""
                                                        onChange={onChangeApproverCountry}
                                                    />
                                                    {errors.appCode && <div className="text-danger fs-12">{errors.appCode}</div>}
                                                </div>

                                                <div className="form-group form-group-modal-custom mb-3 col-6">
                                                    <label className="mb-1">
                                                        Mobile
                                                    </label>
                                                    <input className="form-control" value={approverMobile} onChange={(e) => setApproverMobile(e.target.value)} />
                                                    {errors.appMobile && <div className="text-danger fs-12">{errors.appMobile}</div>}
                                                </div>

                                                <div className="form-check custom-checkbox acco-check col-12">
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="reviewer-check"
                                                    >Reviewer</label>
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        id="reviewer-check"
                                                        onClick={(e) => setReviewer(e.target.checked)}
                                                    />
                                                </div>
                                                <div className="form-group form-group-modal-custom mb-3 col-6">
                                                    <label className="mb-1">
                                                        Reviewer Name
                                                    </label>
                                                    <input className="form-control" value={reviewerName} onChange={(e) => setReviewerName(e.target.value)} />
                                                    {errors.resName && <div className="text-danger fs-12">{errors.resName}</div>}
                                                </div>

                                                <div className="form-group form-group-modal-custom mb-3 col-6">
                                                    <label className="mb-1">
                                                        Reviewer Email
                                                    </label>
                                                    <input className="form-control" value={reviewerEmail} onChange={(e) => setReviewerEmail(e.target.value)} />
                                                    {errors.resEmail && <div className="text-danger fs-12">{errors.resEmail}</div>}
                                                </div>
                                                <div className="form-group form-group-modal-custom client-country mb-3 pe-0 col-6">
                                                    <label className="mb-1">
                                                        Country Code
                                                    </label>
                                                    <Select
                                                        id="country"
                                                        name="country"
                                                        label="country code"
                                                        classNames="select-custom-form"
                                                        options={updatedCountries}
                                                        defaultInputValue={reviewerMobileCode}
                                                        placeholder=""
                                                        onChange={onChangeReviewerCountry}
                                                    />
                                                    {errors.resCode && <div className="text-danger fs-12">{errors.resCode}</div>}
                                                </div>

                                                <div className="form-group form-group-modal-custom mb-3 col-6">
                                                    <label className="mb-1">
                                                        Mobile
                                                    </label>
                                                    <input className="form-control" value={reviewerMobile} onChange={(e) => setReviewerMobile(e.target.value)} />
                                                    {errors.resMobile && <div className="text-danger fs-12">{errors.resMobile}</div>}
                                                </div>
                                                <div className="form-check custom-checkbox acco-check col-12">
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="billing-check"
                                                    >Billable</label>
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        id="billing-check"
                                                        onClick={(e) => setBilling(e.target.checked)}
                                                    />
                                                </div>
                                                <div className="form-group form-group-modal-custom mb-3 col-6">
                                                    <div>
                                                        <label className="mb-1">
                                                            Billing Address
                                                        </label>
                                                        <div>

                                                        </div>
                                                    </div>
                                                    <input className="form-control" value={billingAddress} onChange={(e) => setBillingAddress(e.target.value)} />
                                                    {errors.bAddress && <div className="text-danger fs-12">{errors.bAddress}</div>}
                                                </div>
                                                <div className="form-check custom-checkbox acco-check billing-check col-12">
                                                    <p>(</p>
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="billing-check"
                                                    >Same as Organization Address</label>
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        id="billing-check"
                                                        onClick={(e) => onSetAss(e.target.checked)}
                                                    />
                                                    <p>)</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {props.action !== 1 ?
                                        <div
                                            className={accThree ? 'account-item account-item-active' : 'account-item'}
                                        >
                                            <div
                                                className={accThree ? 'account-button account-active' : 'account-button'}
                                                onClick={onCollapseHandler3}
                                            ><h3>Classification</h3><span>
                                                    {accThree ? <i class="fa fa-caret-down" aria-hidden="true"></i> : <i class="fa fa-caret-right" aria-hidden="true"></i>}
                                                </span></div>
                                            <div className={accThree ? 'account-collapse show' : 'account-collapse'}>
                                                <div className="row">
                                                    <div className="client-project-list">

                                                        <Table responsive>
                                                            <thead>
                                                                <tr>
                                                                    <th>
                                                                        <strong>Project</strong>
                                                                    </th>
                                                                    <th>
                                                                        <strong>Start date</strong>
                                                                    </th>
                                                                    <th>
                                                                        <strong>End date</strong>
                                                                    </th>
                                                                    <th>
                                                                        <strong>Type</strong>
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    props.projectList.map((item, index) => {
                                                                        return (
                                                                            <tr key={index}>
                                                                                <td>{item.title}</td>
                                                                                <td>{item.start_date}</td>
                                                                                <td>{item.end_date}</td>
                                                                                <td>
                                                                                    {(() => {
                                                                                        switch (item.project_type) {
                                                                                            case 1:
                                                                                                return <Button
                                                                                                    className="btn-xs custom-btn-xs"
                                                                                                    variant="info"
                                                                                                >INT</Button>
                                                                                            case 2:
                                                                                                return <Button
                                                                                                    className="btn-xs custom-btn-xs"
                                                                                                    variant="warning"
                                                                                                >EXT</Button>
                                                                                            default:
                                                                                                return null
                                                                                        }
                                                                                    })()}
                                                                                </td>
                                                                            </tr>
                                                                        );
                                                                    })
                                                                }
                                                            </tbody>
                                                        </Table>

                                                    </div>


                                                </div>
                                            </div>
                                        </div>
                                        : null}

                                </div>        
                            </div>
                            <div className="modal-button-group mt-4">
                                <button type="button" onClick={props.closeHandler} className="btn form-cencel-btn">
                                    Cancel
                                </button>
                                <button type="submit" className="btn btn-primary role-form-btn">
                                    {props.action == 1 ? 'Save' : 'Update'}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </Modal>
        </Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
        clientList: state.clients.clientList,
        countryList: state.country.countryList,
        stateList: state.country.stateList,
        cityList: state.country.cityList,
        projectList: state.project.projectList,
    };
};
export default connect(mapStateToProps)(AddClient);