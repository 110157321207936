import axios from 'axios';
import GlobalConfigs from "../configs/GlobalConfigs";
import * as Apis from '../apis/Apis';

export function getRolePermissions() {
    return axios({
        url: GlobalConfigs.API_URL + Apis.GET_PERMISSION_LIST_API,
        method: "GET",
        headers: {
            ContentType: "application/json",
        },
    });

}

export function addRole(role_name, description, permissions, quick_links) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;
    let company_id = JSON.parse(getData).company_id;

    const postData = {
        role_name,
        company_id,
        description,
        permissions,
        quick_links
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.ADD_ROLE_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function updateRole(role_id, role_name, description, permissions, quick_links) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        role_id,
        role_name,
        description,
        permissions,
        quick_links
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.UPDATE_ROLE_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function getRole(company_id) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        company_id
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.GET_ROLE_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function getActiveRole(company_id) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        company_id
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.GET_ACTIVE_ROLE_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function updateRoleStatus(role_id, status) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        role_id,
        status
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.UPDATE_ROLE_STATUS_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function removeRole(role_id) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        role_id,
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.REMOVE_ROLE_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function verifyRoleName(role_id, role_name) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        role_id,
        role_name,
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.VERIFY_ROLE_NAME_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function addPrivilege(user_id, permissions) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        user_id,
        permissions
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.ADD_PREVILEGE_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function getPrivilege(user_id) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        user_id
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.GET_PREVILEGE_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}
