import axios from 'axios';
import GlobalConfigs from "../configs/GlobalConfigs";
import * as Apis from '../apis/Apis';

export function addLocation(loc_id, loc_type, address_1, address_2, country, country_id, state, state_id, city, postal_code, status) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;
    let company_id = JSON.parse(getData).company_id;

    const postData = {
        company_id,
        loc_id,
        loc_type,
        address_1,
        address_2,
        country,
        country_id,
        state,
        state_id,
        city,
        postal_code,
        status
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.ADD_LOCATION_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function addLocationType(type_name) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;
    let company_id = JSON.parse(getData).company_id;

    const postData = {
        company_id,
        type_name,
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.ADD_LOCATION_TYPE_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}

export function getLocationType() {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;
    let company_id = JSON.parse(getData).company_id;

    const postData = {
        company_id
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.GET_LOCATION_TYPE_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function updateLocation(location_id, loc_id, loc_type, address_1, address_2, country, country_id, state, state_id, city, postal_code, status) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;
    let company_id = JSON.parse(getData).company_id;

    const postData = {
        company_id,
        location_id,
        loc_id,
        loc_type,
        address_1,
        address_2,
        country,
        country_id,
        state,
        state_id,
        city,
        postal_code,
        status
    };

    return axios({
        url: GlobalConfigs.API_URL + Apis.UPDATE_LOCATION_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function getLocation(searchString, orderByField, orderByFieldValue, page_no, per_page) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;
    let company_id = JSON.parse(getData).company_id;

    const postData = {
        company_id,
        searchString,
        orderByField,
        orderByFieldValue,
        page_no,
        per_page,
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.GET_LOCATION_LIST_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function getActiveTeam() {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;
    let company_id = JSON.parse(getData).company_id;

    const postData = {
        company_id
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.GET_ACTIVE_TEAM_LIST_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function getTeamById(group_id) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        group_id
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.GET_TEAM_LIST_BY_ID_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function updateLocationStatus(location_id, status) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        location_id,
        status
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.UPDATE_LOCATION_STATUS_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function removeLocation(location_id, is_deleted) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        location_id,
        is_deleted
    };

    return axios({
        url: GlobalConfigs.API_URL + Apis.REMOVE_LOCATION_RECORD_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}

export function removeMultipleLocation(locationIds) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        locationIds,
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.REMOVE_MULTIPLE_LOCATION_RECORD_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function downloadLocation(locationIds) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        locationIds,
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.DOWNLOAD_LOCATION_RECORD_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}
