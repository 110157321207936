import axios from 'axios';
import GlobalConfigs from "../configs/GlobalConfigs";
import * as Apis from '../apis/Apis';

export function addSystemConfig(config_data) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        config_data
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.ADD_SYSTEM_CONFIG_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function getSystemConfig(system_admin) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        system_admin
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.GET_SYSTEM_CONFIG_LIST_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function getSystemUserConfig(filter_type, system_admin) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        filter_type,
        system_admin
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.GET_SYSTEM_USER_CONFIG_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

