import axios from 'axios';
import GlobalConfigs from "../configs/GlobalConfigs";
import * as Apis from '../apis/Apis';


export function getSubscribtion(user_id, token, plan_id, subscription_id) {

    const postData = {
        user_id,
        plan_id,
        subscription_id
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.GET_SUBSCRIPTION_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}
export function checkSubscribtion() {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;
    let user_id = JSON.parse(getData)._id;

    const postData = {
        user_id
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.CHECK_SUBSCRIPTION_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function cancelSubscribtion() {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;
    return axios({
        url: GlobalConfigs.API_URL + Apis.CANCEL_SUBSCRIPTION_API,
        method: "GET",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
    });

}

