import React, { Fragment, useContext, useEffect, useState, useRef } from "react";
import { connect, useDispatch } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import {
    Row,
    Col,
    Card,
    Table,
    Modal,
    Button,
    Accordion,
    Spinner
} from "react-bootstrap";

const DateRangeModal = (props) => {


    return (
        <Fragment>
            <Modal
                className="fade bd-example-modal-sm"
                size="sm"
                show={props.show}
            >
                <div className='add-subcription-plan'>
                    <h4>Date range should be between project start date and end date.</h4>
                    <div className='subcription-plan-btn-group'>
                        <button
                            type="button"
                            onClick={props.onClose}
                            className="btn confirm-btn">
                            OK
                        </button>
                    </div>

                </div>
            </Modal>
        </Fragment>
    )
}

export default DateRangeModal