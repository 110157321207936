import axios from 'axios';
import GlobalConfigs from "../configs/GlobalConfigs";
import * as Apis from '../apis/Apis';

export function addTeam(groupId, description, note, status) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;
    let company_id = JSON.parse(getData).company_id;

    const postData = {
        company_id,
        groupId,
        description,
        note,
        status
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.ADD_TEAM_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function updateTeam(group_id, groupId, description, status, note) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;
    let company_id = JSON.parse(getData).company_id;

    const postData = {
        company_id,
        group_id,
        groupId,
        description,
        status,
        note
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.UPDATE_TEAM_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function getTeam(searchString, orderByField, orderByFieldValue, page_no, per_page) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;
    let company_id = JSON.parse(getData).company_id;

    const postData = {
        company_id,
        searchString,
        orderByField,
        orderByFieldValue,
        page_no,
        per_page
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.GET_TEAM_LIST_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function getActiveTeam() {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;
    let company_id = JSON.parse(getData).company_id;

    const postData = {
        company_id
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.GET_ACTIVE_TEAM_LIST_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function getTeamById(group_id) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        group_id
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.GET_TEAM_LIST_BY_ID_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function updateTeamStatus(group_id, status) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        group_id,
        status
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.UPDATE_TEAM_STATUS_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function removeTeam(group_id) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        group_id,
    };

    return axios({
        url: GlobalConfigs.API_URL + Apis.REMOVE_TEAM_RECORD_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}

export function removeMultipleTeam(groupIds) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        groupIds,
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.REMOVE_MULTIPLE_TEAM_RECORD_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function downloadTeamRecord(groupIds) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        groupIds,
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.DOWNLOAD_TEAM_RECORD_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}
