import axios from 'axios';
import GlobalConfigs from "../configs/GlobalConfigs";
import * as Apis from '../apis/Apis';


export function addTime(client_id, project_id, activity_id, task_id, description, billable, start_time) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        client_id,
        project_id,
        activity_id,
        task_id,
        description,
        billable,
        start_time
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.ADD_TIME_TRACKER_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}

export function addUserTime(project_id, activity_id, task_id, description, billable, start_time) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        project_id,
        activity_id,
        task_id,
        description,
        billable,
        start_time
    };
    console.log('postData...', postData)
    return axios({
        url: GlobalConfigs.API_URL + Apis.ADD_USER_TIME_TRACKER_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}

export function updateTime(track_id, client_id, project_id, activity_id, task_id, description, billable, end_time) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        track_id,
        client_id,
        project_id,
        activity_id,
        task_id,
        description,
        billable,
        end_time
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.UPDATE_TIME_TRACKER_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}

export function updateUserTime(track_id, project_id, activity_id, task_id, description, billable, end_time) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        track_id,
        project_id,
        activity_id,
        task_id,
        description,
        billable,
        end_time
    };
    console.log('postData.up..', postData)
    return axios({
        url: GlobalConfigs.API_URL + Apis.UPDATE_USER_TIME_TRACKER_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}

export function updateTimeCaptureList(track_id, client_id, project_id, activity_id, task_id, description, billable, start_time, end_time) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        track_id,
        client_id,
        project_id,
        activity_id,
        task_id,
        description,
        billable,
        start_time,
        end_time
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.UPDATE_TIME_TRACKER_LIST_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}

export function updateUserTimeCaptureList(track_id, project_id, activity_id, task_id, description, billable, start_time, end_time) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        track_id,
        project_id,
        activity_id,
        task_id,
        description,
        billable,
        start_time,
        end_time
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.UPDATE_USER_TIME_TRACKER_LIST_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}

export function getTimeTracker(filter_type, start_date, end_date, page_no, per_page,orderByField, orderByFieldValue) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        filter_type,
        start_date,
        end_date,
        page_no,
        per_page,
        orderByField,
        orderByFieldValue
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.GET_TIME_TRACKER_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}

export function getUserTimeTracker(task_id, filter_type, start_date, end_date, page_no, per_page) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        task_id,
        filter_type,
        start_date,
        end_date,
        page_no,
        per_page
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.GET_USER_TIME_TRACKER_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}

export function timeBillable(track_id, billable) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        track_id,
        billable
    };
    console.log('postData', postData);
    return axios({
        url: GlobalConfigs.API_URL + Apis.ADD_BILLABLE_TIME_TRACKER_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}

export function removeTimeCapture(track_id) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        track_id
    };

    return axios({
        url: GlobalConfigs.API_URL + Apis.REMOVE_TIME_TRACKER_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}

export function getAssignedTask(searchString, orderByField, orderByFieldValue, page_no, per_page) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        searchString,
        orderByField,
        orderByFieldValue,
        page_no,
        per_page
    };

    return axios({
        url: GlobalConfigs.API_URL + Apis.GET_ASSIGNED_TASK_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}

export function updateTimeCaptureApproveStatus(track_id, approve_status) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        track_id,
        approve_status
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.UPDATE_TIME_TRACKER_APPROVE_STATUS_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}

export function updateMultipleTimeCaptureApproveStatus(track_id, approve_status) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        track_id,
        approve_status
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.UPDATE_MULTIPLE_TIME_TRACKER_APPROVE_STATUS_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}

export function sendToReviewer(project_id,time_track_ids) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        project_id,
        time_track_ids,
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.SEND_TO_APPROVER_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}

export function sendToApprover(project_id,time_track_ids) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        project_id,
        time_track_ids
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.SEND_TO_REVIEWER_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}

export function reviewerApproveTrackingDetails(project_id,time_track_ids) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        project_id,
        time_track_ids
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.REVIEWER_APPROVE_TRACKING_DETAILS_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}

export function sendBackApprover(project_id,time_track_ids) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        project_id,
        time_track_ids
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.SEND_BACK_TO_APPROVER_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}

export function getReviewerTracking(page_no, per_page, orderByField, orderByFieldValue) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        page_no,
        per_page,
        orderByField,
        orderByFieldValue
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.GET_REVIEWER_PROJECT_LIST_API,//GET_REVIEWER_TIME_TRACKER_LIST_API
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}


export function getApproverProject(page_no, per_page, orderByField, orderByFieldValue) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        page_no,
        per_page,
        orderByField,
        orderByFieldValue
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.GET_APPROVER_PROJECT_LIST_API,//GET_REVIEWER_TIME_TRACKER_LIST_API
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}

export function getApproverTracking(page_no, per_page, orderByField, orderByFieldValue) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        page_no,
        per_page,
        orderByField,
        orderByFieldValue
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.GET_APPROVER_TIME_TRACKER_LIST_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}

export function getProjectApproverTracking(project_id, type) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        project_id,
        type
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.GET_APPROVER_PROJECT_TRACKER_DETAILS_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}

export function getPendingTrackingForApprover(page_no, per_page, orderByField, orderByFieldValue) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        page_no,
        per_page,
        orderByField,
        orderByFieldValue
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.GET_PENDING_TIMETRACKER_LIST_FOR_APPROVER_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}

export function getApprovedTrackingForApprover(page_no, per_page, orderByField, orderByFieldValue) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        page_no,
        per_page,
        orderByField,
        orderByFieldValue
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.GET_APPROVED_TIMETRACKER_LIST_FOR_APPROVER_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}

export function getRejectTrackingForApprover(page_no, per_page, orderByField, orderByFieldValue) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        page_no,
        per_page,
        orderByField,
        orderByFieldValue
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.GET_REJECT_TIMETRACKER_LIST_FOR_APPROVER_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}

export function getNote(project_id) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        project_id
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.GET_TRANSACTION_LOG_NOTE_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}

export function addNote(project_id, note) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        project_id,
        note
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.ADD_TRANSACTION_LOG_NOTE_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}

export function getApproverTimeCard() {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    return axios({
        url: GlobalConfigs.API_URL + Apis.GET_APPROVER_TIMECARD_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        //data: postData,
    });
}

export function getReviewerTimeCard() {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    return axios({
        url: GlobalConfigs.API_URL + Apis.GET_REVIEWER_TIMECARD_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        //data: postData,
    });
}