import swal from "sweetalert";
import * as utility from '../../utility/Utility';
import { globalLoadingAction } from './GlobalAction';
import {
    addSystemConfig,
    getSystemConfig,
    getSystemUserConfig
} from '../../services/SystemDefaultService';

import {
    GET_SYSTEM_DEFAULT_LIST,
    GET_SYSTEM_USER_CONNFIC_DETAILS
} from '../constants';


export function addSystemConfigData(config_data, history) {
    return (dispatch) => {
        addSystemConfig(config_data)
            .then((response) => {
                if (response.data.status == 200) {
                    utility.showSuccess(response.data.msg);
                } else {
                    utility.showError(response.data.msg);
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                //utility.showError(response.data.msg);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function getSystemConfigData(systemAdmin, history) {
    return (dispatch) => {
        getSystemConfig(systemAdmin)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(getSystemConfigAction(response.data));
                } else {
                    utility.showError(response.data.msg);
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function getSystemUserConfigData(filter_type, system_admin, history) {
    return (dispatch) => {
        getSystemUserConfig(filter_type, system_admin)
            .then((response) => {
                console.log('response...',response.data);
                if (response.data.status == 200) {
                    dispatch(getSystemUserConfigAction(response.data));
                } else {
                    utility.showError(response.data.msg);
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function getSystemConfigAction(data) {
    return {
        type: GET_SYSTEM_DEFAULT_LIST,
        payload: data,
    };
}
export function getSystemUserConfigAction(data) {
    return {
        type: GET_SYSTEM_USER_CONNFIC_DETAILS,
        payload: data,
    };
}