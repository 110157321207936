import {
    GET_CLIENTS,
    UPDATE_CLIENTS_STATUS,
    REMOVE_CLIENTS,
    LOADING_CLIENTS,
} from '../constants';

const initialState = {
    clientList: [],
    totalPage: 0,
    totalRecord: 0,
    screenVisible: false,
    showLoading: false,
};

export function ClientsReducer(state = initialState, action) {

    if (action.type === GET_CLIENTS) {
        return {
            ...state,
            totalPage: action.payload.total_page,
            totalRecord: action.payload.total_record,
            clientList: action.payload.data,
            screenVisible: true,
            showLoading: false,
        };
    }

    if (action.type === UPDATE_CLIENTS_STATUS) {
        const list = [...state.clientList];
        const index = list.findIndex((item) => item._id === action.payload._id);
        list[index].status = action.payload.status === 1 ? 0 : 1;
        return {
            ...state,
            clientList: list,
            showLoading: false,
        };
    }

    if (action.type === REMOVE_CLIENTS) {
        const list = [...state.clientList];
        const index = list.findIndex((item) => item._id === action.payload);
        list.splice(index, 1);
        return {
            ...state,
            clientList: list,
            showLoading: false,
        };
    }

    if (action.type === LOADING_CLIENTS) {
        return {
            ...state,
            showLoading: action.payload,
        };
    }
    return state;
}


