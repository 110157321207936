import todoReducers from './Reducers';
import { combineReducers } from 'redux';
import { GlobalReducer } from './GlobalReducer';
import { AuthReducer } from './AuthReducer';
import { CountryReducer } from './CountryReducer';
import { RoleReducer } from './RoleReducer';
import { EmployeeReducer } from './EmployeeReducer';
import { ProfleReducer } from './ProfleReducer';
import { SubscribtionReducer } from './SubscribtionReducer';
import { ClientsReducer } from './ClientsReducer';
import { ProjectReducer } from './ProjectReducer';
import { TeamReducer } from './TeamReducer';
import { LocationReducer } from './LocationReducer';
import { TimeCaptureReducer } from './TimeCaptureReducer';
import { TimeCardReducer } from './TimeCardReducer';
import { SystemDefaultReducer } from './SystemDefaultReducer';

const rootReducers = combineReducers({
	global: GlobalReducer,
	auth: AuthReducer,
	country: CountryReducer,
	role: RoleReducer,
	employee: EmployeeReducer,
	profile: ProfleReducer,
	subscribtion: SubscribtionReducer,
	clients: ClientsReducer,
	project: ProjectReducer,
	team: TeamReducer,
	location: LocationReducer,
	timeCapture: TimeCaptureReducer,
	timeCard: TimeCardReducer,
	systemconfig: SystemDefaultReducer,
})

export default rootReducers;