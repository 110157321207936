import swal from "sweetalert";
import {
    checkSubscribtion,
    getSubscribtion,
    cancelSubscribtion
} from '../../services/SubscribtionService';

import {
    LOADING_SUBSCRIBTION,
    CHECK_SUBSCRIBTION,
    GET_SUBSCRIBTION,
    CANCEL_SUBSCRIBTION
} from '../constants';

import * as utility from '../../utility/Utility';
import { globalLoadingAction } from '../actions/GlobalAction';
import { loginConfirmedAction, getPermissionList } from '../actions/AuthActions';


export function getSubscribtionDetails(state, plan, subscription, history) {
    return (dispatch) => {
        getSubscribtion(state._id, state.token, plan, subscription)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(getSubscribtionAction(response.data));
                    let obj = {
                        "_id": state._id,
                        "token": state.token,
                        "company_id": state.company_id,
                        "country": state.country,
                        "country_code": state.country_code,
                        "email": state.email,
                        "status": state.status,
                        "user_type": state.user_type,
                        "permission": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29],
                        "quick_links": [],
                        "system_admin": state.system_admin,
                    }
                    if (state.expired == 1) {
                        history.push({
                            pathname: '/congratulations',
                            state: obj
                        });
                    } else {
                        dispatch(loginConfirmedAction(obj));
                        localStorage.setItem('userDetails', JSON.stringify(obj));
                        history.push('/dashboard');
                    }
                    utility.showSuccess(response.data.msg);

                } else {
                    utility.showError(response.data.msg);
                    dispatch(loadingSubscribtionAction(false));
                }
            })
            .catch((error) => {
                //console.log(error);
                dispatch(loadingSubscribtionAction(false));
            });
    };
}
export function checkSubscribtionDetails(history) {
    return (dispatch) => {
        checkSubscribtion()
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(checkSubscribtionAction({ "remaining_day": response.data.data.remaining_day, "subscriptionId": response.data.data.subscriptionId, "planId": response.data.data.planId, "expired": false }));
                } else {
                    dispatch(checkSubscribtionAction({ "remaining_day": '', "expired": true }));
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function cancelSubscribtionDetails(history) {
    return (dispatch) => {
        cancelSubscribtion()
            .then((response) => {
                // console.log('res.....',response.data);
                // if (response.data.status == 200) {
                //     dispatch(cancelSubscribtionAction({ "remaining_day": response.data.data.remaining_day, "expired": false }));
                // } else {
                //     dispatch(checkSubscribtionAction({ "remaining_day": '', "expired": true }));
                // }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function checkSubscribtionAction(data) {
    return {
        type: CHECK_SUBSCRIBTION,
        payload: data,
    };
}
export function cancelSubscribtionAction(data) {
    return {
        type: CANCEL_SUBSCRIBTION,
        payload: data,
    };
}
export function getSubscribtionAction(data) {
    return {
        type: GET_SUBSCRIBTION,
        payload: data,
    };
}
export function loadingSubscribtionAction(status) {
    return {
        type: LOADING_SUBSCRIBTION,
        payload: status,
    };
}
