import axios from 'axios';
import swal from "sweetalert";
import GlobalConfigs from "../configs/GlobalConfigs";
import * as Apis from '../apis/Apis';

export function getCountry() {
    return axios({
        url: GlobalConfigs.API_URL + Apis.GET_COUNTRIES_LIST_API,
        method: "GET",
        headers: {
            ContentType: "application/json",
        },
    });

}

export function getState(country_id) {

    const postData = {
        country_id
    }
    return axios({
        url: GlobalConfigs.API_URL + Apis.GET_STATES_LIST_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
        },
        data: postData,
    });

}

export function getCity(state_id) {

    const postData = {
        state_id
    }
    return axios({
        url: GlobalConfigs.API_URL + Apis.GET_CITYS_LIST_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
        },
        data: postData,
    });

}
