import axios from 'axios';
import GlobalConfigs from "../configs/GlobalConfigs";
import * as Apis from '../apis/Apis';


export function addTimeSheet(user_id, client_id, project_id, activity_id, task_id, description, start_time, end_time) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        user_id,
        client_id,
        project_id,
        activity_id,
        task_id,
        description,
        start_time,
        end_time
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.ADD_TIME_CARD_DETAILS_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}

export function updateTimeSheet(user_id, time_track_id, client_id, project_id, activity_id, task_id, description, start_time, end_time) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        user_id,
        time_track_id,
        client_id,
        project_id,
        activity_id,
        task_id,
        description,
        start_time,
        end_time
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.UPDATE_TIME_CARD_DETAILS_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}

export function addOwnTimeSheet(user_id, client_id, project_id, activity_id, task_id, description, start_time, end_time) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        user_id,
        client_id,
        project_id,
        activity_id,
        task_id,
        description,
        start_time,
        end_time
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.ADD_OWN_TIME_CARD_DETAILS_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}

export function addUserTimeSheet(system_admin, user_id, project_id, activity_id, task_id, description, start_time, end_time) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        system_admin,
        user_id,
        project_id,
        activity_id,
        task_id,
        description,
        start_time,
        end_time
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.ADD_USER_TIME_CARD_DETAILS_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}

export function addOwnMultipleManualTimeSheet(timesheet) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        timesheet
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.ADD_OWN_BULK_TIME_CARD_DETAILS_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}

export function addUserMultipleManualTimeSheet(system_admin, timesheet) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        system_admin,
        timesheet
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.ADD_USER_BULK_TIME_CARD_DETAILS_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}

export function updateOwnTimeSheet(user_id, time_track_id, client_id, project_id, activity_id, task_id, description, start_time, end_time) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        user_id,
        time_track_id,
        client_id,
        project_id,
        activity_id,
        task_id,
        description,
        start_time,
        end_time
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.UPDATE_OWN_TIME_CARD_DETAILS_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}

export function updateUserTimeSheet(system_admin, user_id, time_track_id, project_id, activity_id, task_id, description, start_time, end_time) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        system_admin,
        user_id,
        time_track_id,
        project_id,
        activity_id,
        task_id,
        description,
        start_time,
        end_time
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.UPDATE_USER_TIME_CARD_DETAILS_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}

export function removeTimeSheet(time_sheet_id, time_track_id) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        time_sheet_id,
        time_track_id
    };

    return axios({
        url: GlobalConfigs.API_URL + Apis.REMOVE_TIME_CARD_DETAILS_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}

export function getTimeCardList(searchString, orderByField, orderByFieldValue, page_no, per_page) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;
    let company_id = JSON.parse(getData).company_id;

    const postData = {
        company_id,
        searchString,
        orderByField,
        orderByFieldValue,
        page_no,
        per_page
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.GET_TIME_CARD_LIST_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}

export function getTimeCardSingleData(user_id, filter_type, own_timesheet, client_id, project_id, start_date, end_date,group_by_project) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        user_id,
        filter_type,
        own_timesheet,
        client_id,
        project_id,
        start_date,
        end_date,
        group_by_project
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.GET_TIME_CARD_DETAILS_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}

export function getManualTimeSheet(filter_type, start_date, end_date, client_id, project_id, activity_id, task_id, page_no, per_page) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        filter_type,
        start_date,
        end_date,
        client_id,
        project_id,
        activity_id,
        task_id,
        page_no,
        per_page
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.GET_MANUAL_TIMESHEET_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}

export function getReviewerTimeCardSingleData(project_id) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        project_id
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.GET_TIME_CARD_DETAILS_FOR_REVIEWER_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}
export function getSuperVisorTimeCard() {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    return axios({
        url: GlobalConfigs.API_URL + Apis.GET_SUPERVISOR_TIMECARD_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        //data: postData,
    });
}