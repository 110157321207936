import {
    UPDATE_PROFILE,
    LOADING_PROFILE
} from '../constants';

const initialState = {
    showLoading: false,
};

export function ProfleReducer(state = initialState, action) {

    if (action.type === UPDATE_PROFILE) {
        let getData = localStorage.getItem('userDetails');
        let data = JSON.parse(getData);
        data.country = action.payload.country;
        data.country_code = action.payload.country_code;
        data.first_name = action.payload.first_name;
        data.last_name = action.payload.last_name;
        data.phone_no = action.payload.phone_no;
        data.profile_pic = action.payload.profile_pic;
        data.country_code = action.payload.country_code;
        localStorage.setItem('userDetails', JSON.stringify(data));
        return {
            ...state,
            showLoading: false,
        };
    }

    if (action.type === LOADING_PROFILE) {
        return {
            ...state,
            showLoading: action.payload,
        };
    }
    return state;
}


