import {
    GET_COUNTRY,
    GET_STATE,
    GET_CITY,
    COUNTRY_LOADING,
} from '../constants';

const initialState = {
    countryList: [],
    stateList: [],
    cityList: [],
    showLoading: false,
};

export function CountryReducer(state = initialState, action) {


    if (action.type === GET_COUNTRY) {
        return {
            ...state,
            countryList: action.payload,
            showLoading: false,
        };
    }

    if (action.type === GET_STATE) {

        return {
            ...state,
            stateList: action.payload,
            showLoading: false,
        };
    }

    if (action.type === GET_CITY) {
        return {
            ...state,
            cityList: action.payload,
            showLoading: false,
        };
    }

    if (action.type === COUNTRY_LOADING) {
        return {
            ...state,
            showLoading: action.payload,
        };
    }

    return state;
}


