import {
    GET_SYSTEM_DEFAULT_LIST,
    GET_SYSTEM_USER_CONNFIC_DETAILS
} from '../constants';

const initialState = {
    options: [],
    configDetails: {},
    screenVisible: false,
    showLoading: false,
};

export function SystemDefaultReducer(state = initialState, action) {

    if (action.type === GET_SYSTEM_DEFAULT_LIST) {
        return {
            ...state,
            options: action.payload.data,
            screenVisible: true,
            showLoading: false,
        };
    }

    if (action.type === GET_SYSTEM_USER_CONNFIC_DETAILS) {
        return {
            ...state,
            configDetails: action.payload.data,
        };
    }

    return state;
}


