import React, { useContext, Fragment, useEffect, useState, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import { Link, useHistory, useLocation } from 'react-router-dom';
import moment from 'moment';
import Moment from 'react-moment';
import {
    Row,
    Col,
    Card,
    Table,
    Modal,
    Button,
    Dropdown,
} from "react-bootstrap";
import ReactPaginate from "react-paginate";
import ChatBox from "../Components/ChatBox";
import { getProjectApproverTrackingDetails, updateTimeCapturePendingStatusByApproverData, updateMultipleTimeCaptureApproveStatusPendingData, getPendingTrackingForApproverData, getNoteData, addNoteData, updateTimeCaptureApproveStatusByReviewerData } from '../../../store/actions/TimeCaptureActions';
import { globalLoadingAction } from '../../../store/actions/GlobalAction';

const RejectTab = (props) => {

    const dispatch = useDispatch();
    const history = useHistory();
    const [projectDetails, setProjectDetails] = useState({});
    const [cardList, setCardList] = useState([]);
    //Node
    const [message, setMessage] = useState('');
    const [noteData, setNoteData] = useState([]);
    const [isNote, setIsNote] = useState(false);
    const [projectId, setProjectId] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        dispatch(globalLoadingAction(true));
        dispatch(getProjectApproverTrackingDetails(props.projectId, 2, props.history));
    }, []);

    useEffect(() => {
        if (props.approverCardDetails?.data) {
            setCardList(props.approverCardDetails.data);
        }
        if (props.approverCardDetails?.project_details) {
            setProjectDetails(props.approverCardDetails.project_details);
        }
    }, [props.approverCardDetails]);

    const onGetNoteHandler = (project_id) => {
        setProjectId(project_id);
        dispatch(globalLoadingAction(true));
        dispatch(getNoteData(project_id, setNotData, props.history));
    }

    const setNotData = (data) => {
        dispatch(globalLoadingAction(false));
        setNoteData(data);
        setIsNote(true);
    }

    const closeHandler = () => {
        setIsNote(false);
        setNoteData([]);
    }

    const onSubmitMessageHandler = () => {
        setMessage("");
        setLoading(true);
        dispatch(addNoteData(projectId, message, AddNotData, props.history));
    }
    const AddNotData = (data) => {
        // if (recId && status) {
        //     setLoading(false);
        //     setIsNote(false);
        //     onSubmitStatus();
        // } else {
        //     setNoteData(data);
        //     setLoading(false);
        // }
        setNoteData(data);
        setLoading(false);
    }


    return (
        <Fragment>
            <div className="card-detail-header">

                <div className="card-detail-left">
                <div>
                        <label className='mb-2'><strong>Project ID:</strong> {projectDetails?.project_id}</label>
                    </div>
                    <div>
                        <label className='mb-2'><strong>Project Name:</strong> {projectDetails?.title}</label>
                    </div>
                    <div>
                        <label><strong>Project Rate:</strong> {projectDetails?.rate}</label>
                    </div>
                </div>
                <div className="card-detail-right">
                    {projectDetails?.start_date ?
                        <div>
                            <label className='mb-2'><strong>Project Start Date:</strong> {<Moment format="D MMM YYYY" withTitle>{projectDetails?.start_date}</Moment>}</label>
                        </div>
                        : null}
                    {projectDetails?.end_date ?
                        <div>
                            <label><strong>Project End Date:</strong> {<Moment format="D MMM YYYY" withTitle>{projectDetails?.end_date}</Moment>}</label>
                        </div>
                        : null}
                </div>
            </div>
            <div>
                {cardList.length > 0 ?
                    cardList.map((details, parentindex) => {
                        return (
                            <>
                                <div key={parentindex} className='timeSheet-project-card mb-1 mt-3'>
                                    <div>
                                        <h4 className='timeSheet-project-title'>{details.project_name}</h4>
                                    </div>
                                    <div>  
                                        <button
                                            type='button'
                                            //disabled={details.send_for_approval == 1 ? false : true}
                                            className='note-project-btm'
                                            onClick={() => onGetNoteHandler(projectDetails?._id)}
                                        >Note</button>
                                    </div>
                                </div>
                                <Table responsive key={parentindex} className='cart-table'>
                                    <thead>
                                        <tr className='cart-table-header'>
                                            <th>
                                                <strong>Date</strong>
                                            </th>
                                            <th>
                                                <strong>Activities</strong>
                                            </th>
                                            <th>
                                                <strong>Task</strong>
                                            </th>
                                            <th>
                                                <strong>Start time</strong>
                                            </th>
                                            <th>
                                                <strong>Finish time</strong>
                                            </th>
                                            <th><strong></strong></th>
                                            <th className='text-center'>
                                                <strong>Regular Hrs</strong>
                                            </th>
                                            <th className='text-center'>
                                                <strong>Total Hrs</strong>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            details?.time_track_details?.map((item, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{moment(item.start_time).format('DD/MM/YYYY')}</td>
                                                        {/* <td>{item.project_name}</td> */}
                                                        <td>{item.activity}</td>
                                                        <td>{item.task}</td>
                                                        <td>{moment(item.start_time).format('h:mm:ss A')}</td>
                                                        <td>{moment(item.end_time).format('h:mm:ss A')}
                                                        </td>
                                                        <td>

                                                        </td>
                                                        <td className='total-hrs'>{item.regular_hour}</td>
                                                        <td className='total-hrs'>{item.total_hour}</td>
                                                    </tr>
                                                );
                                            })
                                        }
                                        <tr className='total-time'>
                                            {/* <td></td> */}
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td><strong>TOTAL HOURS :</strong></td>
                                            <td className='total-hrs'><strong>{details?.aggregate_data?.total_regular_hour}</strong></td>
                                            <td className='total-hrs'><strong>{details?.aggregate_data?.total_hour}</strong></td>
                                        </tr>
                                        <tr className='total-time'>
                                            {/* <td></td> */}
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td><strong>RATE PER HOURS :</strong></td>
                                            <td className='total-hrs'><strong>${details?.aggregate_data?.emp_rate}</strong></td>
                                            <td className='total-hrs'><strong></strong></td>
                                        </tr>
                                        <tr className='total-time'>
                                            {/* <td></td> */}
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td><strong>TOTAL PAY :</strong></td>
                                            <td className='total-hrs'><strong>${details?.aggregate_data?.total_regular_pay}</strong></td>
                                            <td className='total-hrs'><strong>${details?.aggregate_data?.total_pay}</strong></td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </>
                        )
                    })
                    :
                    <div className='text-center mt-3'><h3>No Data Found</h3></div>}

            </div>
            <ChatBox
                show={isNote}
                data={noteData}
                value={message}
                onChangeHandler={(val) => setMessage(val)}
                onSubmitHandler={() => onSubmitMessageHandler()}
                closeHandler={closeHandler}
                loading={loading}
                currentUser={props.userId}
            />
            {console.log('approverCardDetails..', props.approverCardDetails)}
        </Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
        screenVisible: state.timeCapture.screenVisible,
        showLoading: state.timeCapture.showLoading,
        list: state.timeCapture.approvePendingList,
        totalPage: state.timeCapture.approverPendingPage,
        totalRecord: state.timeCapture.approverPendingRecord,
        approverCardDetails: state.timeCapture.approverCardDetails,
        userId: state.auth.userId,
    };
};
export default connect(mapStateToProps)(RejectTab);