import swal from "sweetalert";
import * as utility from '../../utility/Utility';
import { globalLoadingAction } from './GlobalAction';
import { getEmployeeList } from './EmployeeActions';
import {
    addClients,
    updateClients,
    getClients,
    removeClient,
    updateClientStatus,
    downloadClientRecord,
    removeMultipleClient
} from '../../services/ClientsService';

import {
    LOADING_CLIENTS,
    GET_CLIENTS,
    REMOVE_CLIENTS,
    UPDATE_CLIENTS_STATUS
} from '../constants';


export function addClientsData(taxId, idType, other_id_type, tax_no, alt, username, organization_id, organization_name, organization_address, address1, address2, country, state, city, zipcode, email, mobile, status, password, country_code, approver_name, approver_email, approver_code, approver_mobile, reviewer_name, reviewer_email, reviewer_code, reviewer_mobile, billing_address,type, perPage, history) {
    return (dispatch) => {
        addClients(taxId, idType, other_id_type, tax_no, alt, username, organization_id, organization_name, organization_address, address1, address2, country, state, city, zipcode, email, mobile, status, password, country_code, approver_name, approver_email, approver_code, approver_mobile, reviewer_name, reviewer_email, reviewer_code, reviewer_mobile, billing_address,type, perPage)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(getClientsData('', '', '', 1, perPage, history));
                    utility.showSuccess(response.data.msg);
                } else {
                    utility.showError(response.data.msg);
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                //utility.showError(response.data.msg);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function updateClientsData(user_id, client_id, taxId, idType, other_id_type, tax_no, alt, username,organization_id, organization_name, organization_address, address1, address2, country, state, city, zipcode, email, mobile, status, password, country_code, approver_name, approver_email, approver_code, approver_mobile, reviewer_name, reviewer_email, reviewer_code, reviewer_mobile, billing_address,type, searchString, orderByField, orderByFieldValue, page_no, per_page, history) {
    return (dispatch) => {
        updateClients(user_id, client_id, taxId, idType, other_id_type, tax_no, alt, username,organization_id, organization_name, organization_address, address1, address2, country, state, city, zipcode, email, mobile, status, password, country_code, approver_name, approver_email, approver_code, approver_mobile, reviewer_name, reviewer_email, reviewer_code, reviewer_mobile, billing_address,type)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(getClientsData(searchString, orderByField, orderByFieldValue, page_no, per_page, history));
                    utility.showSuccess(response.data.msg);
                } else {
                    utility.showError(response.data.msg);
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function getClientsData(searchString, orderByField, orderByFieldValue, page_no, per_page, history) {
    return (dispatch) => {
        getClients(searchString, orderByField, orderByFieldValue, page_no, per_page)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(getClientsAction(response.data));
                } else {
                    utility.showError(response.data.msg);
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function updateClientStatusData(item, history) {
    return (dispatch) => {
        updateClientStatus(item._id, item.status === 1 ? 0 : 1)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(updateClientStatusAction(item));
                    utility.showSuccess(response.data.msg);
                } else {
                    utility.showError(response.data.msg);
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function removeClientData(id, searchString, orderByField, orderByFieldValue, page_no, per_page, history) {
    return (dispatch) => {
        removeClient(id)
            .then((response) => {
                if (response.data.status == 200) {
                    //dispatch(removeClientAction(id));
                    swal(response.data.msg, { icon: "success", });
                    dispatch(getClientsData(searchString, orderByField, orderByFieldValue, page_no, per_page, history));
                } else {
                    swal(response.data.msg, "error");
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function removeMultipleClientData(userIds, searchString, orderByField, orderByFieldValue, page_no, per_page, history) {
    return (dispatch) => {
        removeMultipleClient(userIds)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(getClientsData(searchString, orderByField, orderByFieldValue, page_no, per_page, history));
                    utility.showSuccess(response.data.msg);
                } else {
                    dispatch(globalLoadingAction(false));
                    utility.showError(response.data.msg);
                }
            })
            .catch((error) => {
                //console.log(error);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function downloadClientData(userIds, history) {
    return (dispatch) => {
        downloadClientRecord(userIds)
            .then((response) => {
                if (response.data.status == 200) {
                    const outputFilename = `${Date.now()}.xls`;
                    const link = document.createElement('a');
                    link.href = response.data.data;
                    link.setAttribute('download', outputFilename);
                    document.body.appendChild(link);
                    link.click();
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function getClientsAction(data) {
    return {
        type: GET_CLIENTS,
        payload: data,
    };
}

export function updateClientStatusAction(status) {
    return {
        type: UPDATE_CLIENTS_STATUS,
        payload: status,
    };
}

export function removeClientAction(id) {
    return {
        type: REMOVE_CLIENTS,
        payload: id,
    };
}

export function loadingClientsAction(status) {
    return {
        type: LOADING_CLIENTS,
        payload: status,
    };
}
