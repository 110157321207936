import swal from "sweetalert";
import {
    getCountry,
    getState,
    getCity
} from '../../services/CountryServices';

import {
    GET_COUNTRY,
    GET_STATE,
    GET_CITY,
    COUNTRY_LOADING
} from '../constants';

import * as utility from '../../utility/Utility';


export function getCountryList(history) {
    return (dispatch) => {
        getCountry()
            .then((response) => {
                if(response.data.status){
                    dispatch(getCountryAction(response.data.data));
                }
            })
            .catch((error) => {
                //console.log(error.response.data);
                utility.showError('error');
            });
    };
}

export function getStateList(country_id, history) {
    return (dispatch) => {
        getState(country_id)
            .then((response) => {
                if(response.data.status){
                dispatch(getStateAction(response.data.data));
                }
            })
            .catch((error) => {
                //console.log(error.response.data);
                utility.showError('error');
            });
    };
}

export function getCityList(state_id, history) {
    return (dispatch) => {
        getCity(state_id)
            .then((response) => {
                if(response.data.status){
                dispatch(getCityAction(response.data.data));
                }
            })
            .catch((error) => {
                //console.log(error.response.data);
                utility.showError('error');
            });
    };
}

export function getCountryAction(data) {
    return {
        type: GET_COUNTRY,
        payload: data,
    };
}

export function getStateAction(data) {
    return {
        type: GET_STATE,
        payload: data,
    };
}

export function getCityAction(data) {
    return {
        type: GET_CITY,
        payload: data,
    };
}

export function countryLoadingAction(status) {
    return {
        type: COUNTRY_LOADING,
        payload: status,
    };
}
