import React, { useContext, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import {
    updateRoleData, addRoleData, loadingRoleAction, getRolePermissionsData
} from '../../../store/actions/RoleActions';
import { verifyRoleName } from '../../../services/RoleService';
import { globalLoadingAction } from '../../../store/actions/GlobalAction';


const CopyRole = (props) => {

    const dispatch = useDispatch();
    const history = useHistory();
    const { state } = useLocation();

    let errorsObj = { role: '', permission: '', description: '' };
    const [errors, setErrors] = useState(errorsObj);
    const [roleName, setRoleName] = useState("");
    const [description, setDescription] = useState("");
    const [permission, setPermission] = useState(state.permission);
    const [defaultPermission, setDefaultPermission] = useState([1, 2, 3, 4, 5, 20]);
    const [quickLink, setQuickLink] = useState(state.quick_links ? state.quick_links : []);
    const [currentPermission, setCurrentPermission] = useState(state.permission);
    const [validRoleName, setValidRoleName] = useState(false);
    const chackbox = document.querySelectorAll(".form-check-label input");

    useEffect(() => {
        dispatch(globalLoadingAction(true));
        dispatch(getRolePermissionsData(props.history));
    }, []);

    function onSubmit(e) {

        e.preventDefault();

        let error = false;
        const errorObj = { ...errorsObj };
        if (roleName === '') {
            errorObj.role = 'Role name is Required';
            error = true;
        } else if (validRoleName) {
            errorObj.role = 'Invalid role name';
            error = true;
        }
        if (description === '') {
            errorObj.description = 'Description is Required';
            error = true;
        }
        if (permission.length === 0) {
            errorObj.permission = 'Permission access is Required';
            error = true;

        }
        setErrors(errorObj);
        if (error) {
            return;
        }

        dispatch(globalLoadingAction(true));
        dispatch(addRoleData(roleName, description, permission, quickLink, 2, props.history));

        setRoleName('');
        setPermission([]);
        chackbox.forEach((val, index) => {
            chackbox[index].checked = false;
        });
    }




    const onChackboxHandlerMain = (element, item) => {

        let dataArray = props.permissionsList;
        let array = [...permission];

        if (element.target.checked == true) {
            array.push(item.menu_id);
            for (let i = 0; dataArray.length > i; i++) {
                if (item.menu_id == dataArray[i].parent_id) {
                    array.push(dataArray[i].menu_id);
                    document.querySelector(`#check${dataArray[i]._id}`).checked = true;
                    for (let x = 0; dataArray.length > x; x++) {
                        if (dataArray[i].menu_id == dataArray[x].parent_id) {
                            array.push(dataArray[x].menu_id);
                            document.querySelector(`#check${dataArray[x]._id}`).checked = true;
                        }
                    }
                }
            }

        } else {
            const index = array.findIndex((val) => val === item.menu_id);
            array.splice(index, 1);

            for (let i = 0; dataArray.length > i; i++) {
                if (item.menu_id == dataArray[i].parent_id) {
                    let childId = dataArray[i].menu_id;
                    let removeId = array.includes(childId);
                    if (removeId) {
                        const parentIndex = array.findIndex((val) => val === dataArray[i].menu_id);
                        array.splice(parentIndex, 1);
                        document.querySelector(`#check${dataArray[i]._id}`).checked = false;
                    }
                    for (let x = 0; dataArray.length > x; x++) {
                        if (dataArray[i].menu_id == dataArray[x].parent_id) {

                            let lastChildId = dataArray[x].menu_id;
                            let lastRemoveId = array.includes(lastChildId);
                            if (lastRemoveId) {
                                const childIndex = array.findIndex((val) => val === dataArray[x].menu_id);
                                array.splice(childIndex, 1);
                                document.querySelector(`#check${dataArray[x]._id}`).checked = false;
                            }

                        }
                    }
                }
            }
        }
        setPermission(array);

    }


    const onChackboxHandlerSub = (element, item, subItem) => {

        let dataArray = props.permissionsList;
        let array = [...permission];
        let parentId = array.includes(item.menu_id);

        if (element.target.checked == true) {

            if (parentId) {
                array.push(subItem.menu_id);
            } else {
                document.querySelector(`#check${item._id}`).checked = true;
                array.push(item.menu_id, subItem.menu_id);
            }
            for (let i = 0; dataArray.length > i; i++) {
                if (subItem.menu_id == dataArray[i].parent_id) {
                    array.push(dataArray[i].menu_id);
                    document.querySelector(`#check${dataArray[i]._id}`).checked = true;
                }
            }

        } else {

            const parentIndex = array.findIndex((val) => val === item.menu_id);
            const subIndex = array.findIndex((val) => val === subItem.menu_id);
            array.splice(subIndex, 1);
            let ins = false;

            for (let i = 0; dataArray.length > i; i++) {
                if (item.menu_id == dataArray[i].parent_id) {
                    let removeId = array.includes(dataArray[i].menu_id);
                    if (removeId) {
                        ins = true;
                        break
                    }

                }
            }

            if (!ins) {
                array.splice(parentIndex, 1);
                document.querySelector(`#check${item._id}`).checked = false;
            }

            for (let i = 0; dataArray.length > i; i++) {
                if (subItem.menu_id == dataArray[i].parent_id) {
                    let removeChildId = array.includes(dataArray[i].menu_id);
                    if (removeChildId) {
                        const childIndex = array.findIndex((val) => val === dataArray[i].menu_id);
                        array.splice(childIndex, 1);
                        document.querySelector(`#check${dataArray[i]._id}`).checked = false;
                    }

                }
            }
        }

        setPermission(array);
    }



    const onChackboxHandlerUnderSub = (element, item, subItem, underSubItem) => {


        let dataArray = props.permissionsList;
        let array = [...permission];
        let parentId = array.includes(item.menu_id);
        let secondParentId = array.includes(subItem.menu_id);

        if (element.target.checked == true) {

            if (parentId && secondParentId) {
                array.push(underSubItem.menu_id);
            } else if (parentId) {
                document.querySelector(`#check${subItem._id}`).checked = true;
                array.push(subItem.menu_id, underSubItem.menu_id);

            } else {
                document.querySelector(`#check${item._id}`).checked = true;
                document.querySelector(`#check${subItem._id}`).checked = true;
                array.push(item.menu_id, subItem.menu_id, underSubItem.menu_id);
            }

        } else {

            const parentIndex = array.findIndex((val) => val === item.menu_id);
            const childIndex = array.findIndex((val) => val === subItem.menu_id);
            const lastMenuIndex = array.findIndex((val) => val === underSubItem.menu_id);
            array.splice(lastMenuIndex, 1);

            let isLastChild = false;
            let isSubChild = false;

            for (let i = 0; dataArray.length > i; i++) {
                if (subItem.menu_id == dataArray[i].parent_id) {
                    let removeId = array.includes(dataArray[i].menu_id);
                    if (removeId) {
                        isLastChild = true;
                        break
                    }
                }
            }

            if (!isLastChild) {

                array.splice(childIndex, 1);
                document.querySelector(`#check${subItem._id}`).checked = false;

                for (let i = 0; dataArray.length > i; i++) {
                    if (item.menu_id == dataArray[i].parent_id) {
                        let removeSubId = array.includes(dataArray[i].menu_id);
                        if (removeSubId) {
                            isSubChild = true;
                            break
                        }
                    }
                }

                if (!isSubChild) {
                    array.splice(parentIndex, 1);
                    document.querySelector(`#check${item._id}`).checked = false;
                }
            }
        }

        setPermission(array);

    }


    const onCurrentStatus = (id) => {
        let status = null;
        const index = currentPermission.findIndex((val) => val === id);
        if (index >= 0) {
            status = true;
        } else {
            status = false;
        }
        return status;
    }

    const onLinkHandlerMain = (element, item) => {

        let dataArray = props.permissionsList;
        let array = [...quickLink];

        if (element.target.checked == true) {
            array.push(item.menu_id);
            for (let i = 0; dataArray.length > i; i++) {
                if (item.menu_id == dataArray[i].parent_id) {
                    array.push(dataArray[i].menu_id);
                    document.querySelector(`#link${dataArray[i]._id}`).checked = true;
                    for (let x = 0; dataArray.length > x; x++) {
                        if (dataArray[i].menu_id == dataArray[x].parent_id) {
                            array.push(dataArray[x].menu_id);
                            document.querySelector(`#link${dataArray[x]._id}`).checked = true;
                        }
                    }
                }
            }

        } else {
            const index = array.findIndex((val) => val === item.menu_id);
            array.splice(index, 1);

            for (let i = 0; dataArray.length > i; i++) {
                if (item.menu_id == dataArray[i].parent_id) {
                    let childId = dataArray[i].menu_id;
                    let removeId = array.includes(childId);
                    if (removeId) {
                        const parentIndex = array.findIndex((val) => val === dataArray[i].menu_id);
                        array.splice(parentIndex, 1);
                        document.querySelector(`#link${dataArray[i]._id}`).checked = false;
                    }
                    for (let x = 0; dataArray.length > x; x++) {
                        if (dataArray[i].menu_id == dataArray[x].parent_id) {

                            let lastChildId = dataArray[x].menu_id;
                            let lastRemoveId = array.includes(lastChildId);
                            if (lastRemoveId) {
                                const childIndex = array.findIndex((val) => val === dataArray[x].menu_id);
                                array.splice(childIndex, 1);
                                document.querySelector(`#link${dataArray[x]._id}`).checked = false;
                            }

                        }
                    }
                }
            }
        }
        setQuickLink(array);

    }

    const onLinkHandlerSub = (element, item, subItem) => {

        let dataArray = props.permissionsList;
        let array = [...quickLink];
        let parentId = array.includes(item.menu_id);

        if (element.target.checked == true) {

            if (parentId) {
                array.push(subItem.menu_id);
            } else {
                document.querySelector(`#link${item._id}`).checked = true;
                array.push(item.menu_id, subItem.menu_id);
            }
            for (let i = 0; dataArray.length > i; i++) {
                if (subItem.menu_id == dataArray[i].parent_id) {
                    array.push(dataArray[i].menu_id);
                    document.querySelector(`#link${dataArray[i]._id}`).checked = true;
                }
            }

        } else {

            const parentIndex = array.findIndex((val) => val === item.menu_id);
            const subIndex = array.findIndex((val) => val === subItem.menu_id);
            array.splice(subIndex, 1);
            let ins = false;

            for (let i = 0; dataArray.length > i; i++) {
                if (item.menu_id == dataArray[i].parent_id) {
                    let removeId = array.includes(dataArray[i].menu_id);
                    if (removeId) {
                        ins = true;
                        break
                    }

                }
            }

            if (!ins) {
                array.splice(parentIndex, 1);
                document.querySelector(`#link${item._id}`).checked = false;
            }

            for (let i = 0; dataArray.length > i; i++) {
                if (subItem.menu_id == dataArray[i].parent_id) {
                    let removeChildId = array.includes(dataArray[i].menu_id);
                    if (removeChildId) {
                        const childIndex = array.findIndex((val) => val === dataArray[i].menu_id);
                        array.splice(childIndex, 1);
                        document.querySelector(`#link${dataArray[i]._id}`).checked = false;
                    }

                }
            }
        }

        setQuickLink(array);
    }

    const onLinkHandlerUnderSub = (element, item, subItem, underSubItem) => {


        let dataArray = props.permissionsList;
        let array = [...quickLink];
        let parentId = array.includes(item.menu_id);
        let secondParentId = array.includes(subItem.menu_id);

        if (element.target.checked == true) {

            if (parentId && secondParentId) {
                array.push(underSubItem.menu_id);
            } else if (parentId) {
                document.querySelector(`#link${subItem._id}`).checked = true;
                array.push(subItem.menu_id, underSubItem.menu_id);

            } else {
                document.querySelector(`#link${item._id}`).checked = true;
                document.querySelector(`#link${subItem._id}`).checked = true;
                array.push(item.menu_id, subItem.menu_id, underSubItem.menu_id);
            }

        } else {

            const parentIndex = array.findIndex((val) => val === item.menu_id);
            const childIndex = array.findIndex((val) => val === subItem.menu_id);
            const lastMenuIndex = array.findIndex((val) => val === underSubItem.menu_id);
            array.splice(lastMenuIndex, 1);

            let isLastChild = false;
            let isSubChild = false;

            for (let i = 0; dataArray.length > i; i++) {
                if (subItem.menu_id == dataArray[i].parent_id) {
                    let removeId = array.includes(dataArray[i].menu_id);
                    if (removeId) {
                        isLastChild = true;
                        break
                    }
                }
            }

            if (!isLastChild) {

                array.splice(childIndex, 1);
                document.querySelector(`#link${subItem._id}`).checked = false;

                for (let i = 0; dataArray.length > i; i++) {
                    if (item.menu_id == dataArray[i].parent_id) {
                        let removeSubId = array.includes(dataArray[i].menu_id);
                        if (removeSubId) {
                            isSubChild = true;
                            break
                        }
                    }
                }

                if (!isSubChild) {
                    array.splice(parentIndex, 1);
                    document.querySelector(`#link${item._id}`).checked = false;
                }
            }
        }

        setQuickLink(array);

    }

    const onOuickLinkStatus = (id) => {
        let status = null;
        const index = permission.findIndex((val) => val === id);
        if (index >= 0) {
            status = false;
        } else {
            status = true;
        }
        return status;
    }

    const onOuickLinkCheck = (id) => {
        let status = null;
        const index = quickLink.findIndex((val) => val === id);
        if (index >= 0) {
            status = true;
        } else {
            status = false;
        }
        return status;
    }

    const onKeyUpRoleNameHandler = (name) => {
        let role_id = "";
        verifyRoleName(role_id, name.trim())
            .then((response) => {
                let errorObj = { ...errorsObj };
                errorObj.role = response.data.status == 400 ? response.data.msg : '';
                setValidRoleName(response.data.status == 400 ? true : false);
                setErrors(errorObj);
            })
            .catch((error) => {
                let errorObj = { ...errorsObj };
                errorObj.role = "";
                setValidRoleName(true);
                setErrors(errorObj);
                //console.log(error);
            });
    }

    const onGoBackHandler = () => {
        history.goBack();
    }

    return (
        <>
            <div className="row">
                <div className="breadcrumb-container">
                    <ul>
                        <li>
                            <Link to={"#"}>System Setting</Link>
                        </li>
                        <li><i className="fa fa-angle-right"></i></li>
                        <li>
                            <Link to="/role-management">Role Management</Link>
                        </li>
                        <li><i className="fa fa-angle-right"></i></li>
                        <li>
                            <Link to={"#"}>Copy Role</Link>
                        </li>
                    </ul>
                </div>
                <div className="title-body-container mb-3">
                    <div className="title-body">
                        <h3>Copy Role</h3>
                        <p>Manage Company/Organization Information such as name, type, location address and contacts.</p>
                    </div>
                </div>
                <div className="col-xl-6 col-xxl-12">
                    <div className="row">

                        <div className="col-xl-12">
                            <div className="card pt-5 pb-5">
                                <div className="row">
                                    <div className="col-xl-3"></div>
                                    <div className="col-xl-6">

                                        <div className="card-body">
                                            <div className="basic-form">

                                                <form onSubmit={onSubmit}>

                                                    <div className="form-group mb-3">
                                                        <label className="mb-1">
                                                            <strong>Role name</strong>
                                                        </label>
                                                        <input className="form-control"
                                                            value={roleName}
                                                            onKeyUp={(e) => onKeyUpRoleNameHandler(e.target.value)}
                                                            onChange={(e) => setRoleName(e.target.value)} />
                                                        {errors.role && <div className="text-danger fs-12">{errors.role}</div>}
                                                    </div>

                                                    <div className="form-group mb-3">
                                                        <label className="mb-1">
                                                            <strong>Description</strong>
                                                        </label>
                                                        <input className="form-control" value={description} onChange={(e) => setDescription(e.target.value)} />
                                                        {errors.description && <div className="text-danger fs-12">{errors.description}</div>}
                                                    </div>
                                                    <div className="form-group">
                                                        <label className="mb-1">
                                                            <strong>Permission</strong>
                                                        </label>
                                                    </div>
                                                    <div className="role-menu-container mb-3">

                                                        {
                                                            props.permissionsList.map((item, index) => {
                                                                if (item.parent_id == 0) {
                                                                    return (
                                                                        <>
                                                                            {item.menu_id == 9 ? null :
                                                                                <div className="role-menu-item">
                                                                                    <div className="form-group mt-3">
                                                                                        <div key={index} className="form-check">
                                                                                            <label className="form-check-label">
                                                                                                <input
                                                                                                    type="checkbox"
                                                                                                    defaultChecked={onCurrentStatus(item.menu_id)}
                                                                                                    className="form-check-input"
                                                                                                    id={`check${item._id}`}
                                                                                                    disabled={defaultPermission.includes(item.menu_id)}
                                                                                                    onClick={(e) => onChackboxHandlerMain(e, item)}
                                                                                                />
                                                                                                {item.title}
                                                                                            </label>
                                                                                        </div>
                                                                                    </div>
                                                                                    {
                                                                                        props.permissionsList.map((data, i) => {
                                                                                            if (data.parent_id == item.menu_id) {
                                                                                                return (
                                                                                                    <div className="form-group form-check-inline sub-checkMark">
                                                                                                        <div key={index} className="form-check">
                                                                                                            <label className="form-check-label">
                                                                                                                <input
                                                                                                                    type="checkbox"
                                                                                                                    defaultChecked={onCurrentStatus(data.menu_id)}
                                                                                                                    className="form-check-input"
                                                                                                                    id={`check${data._id}`}
                                                                                                                    disabled={defaultPermission.includes(data.menu_id)}
                                                                                                                    onClick={(e) => onChackboxHandlerSub(e, item, data)}
                                                                                                                />
                                                                                                                {data.title}
                                                                                                            </label>
                                                                                                        </div>

                                                                                                        {
                                                                                                            props.permissionsList.map((subData, j) => {
                                                                                                                if (subData.parent_id == data.menu_id) {
                                                                                                                    return (

                                                                                                                        <label className="form-check-label sub-checkMark">
                                                                                                                            <input
                                                                                                                                type="checkbox"
                                                                                                                                defaultChecked={onCurrentStatus(subData.menu_id)}
                                                                                                                                className="form-check-input"
                                                                                                                                id={`check${subData._id}`}
                                                                                                                                onClick={(e) => onChackboxHandlerUnderSub(e, item, data, subData)}
                                                                                                                            />
                                                                                                                            {subData.title}
                                                                                                                        </label>

                                                                                                                    )
                                                                                                                }
                                                                                                            })
                                                                                                        }

                                                                                                    </div>
                                                                                                )
                                                                                            }
                                                                                        })
                                                                                    }



                                                                                </div>
                                                                            }
                                                                        </>
                                                                    );
                                                                }
                                                            })
                                                        }

                                                        {/* {errors.permission && <div className="text-danger fs-12">{errors.permission}</div>} */}

                                                    </div>

                                                    <div className="form-group">
                                                        <label className="mb-1">
                                                            <strong>Quick Links</strong>
                                                        </label>
                                                    </div>
                                                    <div className="role-menu-container">
                                                        {
                                                            props.permissionsList.map((item, index) => {
                                                                if (item.parent_id == 0) {
                                                                    return (
                                                                        <>
                                                                            {item.menu_id == 9 ? null :
                                                                                <div className="role-menu-item">
                                                                                    <div className="form-group mt-3">
                                                                                        <div key={index} className="form-check">
                                                                                            <label className="form-check-label">
                                                                                                <input
                                                                                                    type="checkbox"
                                                                                                    className="form-check-input"
                                                                                                    id={`link${item._id}`}
                                                                                                    defaultChecked={onOuickLinkCheck(item.menu_id)}
                                                                                                    disabled={onOuickLinkStatus(item.menu_id)}
                                                                                                    onClick={(e) => onLinkHandlerMain(e, item)}
                                                                                                />
                                                                                                {item.title}
                                                                                            </label>
                                                                                        </div>
                                                                                    </div>
                                                                                    {
                                                                                        props.permissionsList.map((data, i) => {
                                                                                            if (data.parent_id == item.menu_id) {
                                                                                                return (
                                                                                                    <div className="form-group form-check-inline sub-checkMark">
                                                                                                        <div key={index} className="form-check">
                                                                                                            <label className="form-check-label">
                                                                                                                <input
                                                                                                                    type="checkbox"
                                                                                                                    className="form-check-input"
                                                                                                                    id={`link${data._id}`}
                                                                                                                    defaultChecked={onOuickLinkCheck(data.menu_id)}
                                                                                                                    disabled={onOuickLinkStatus(data.menu_id)}
                                                                                                                    onClick={(e) => onLinkHandlerSub(e, item, data)}
                                                                                                                />
                                                                                                                {data.title}
                                                                                                            </label>
                                                                                                        </div>

                                                                                                        {
                                                                                                            props.permissionsList.map((subData, j) => {
                                                                                                                if (subData.parent_id == data.menu_id) {
                                                                                                                    return (

                                                                                                                        <label className="form-check-label sub-checkMark">
                                                                                                                            <input
                                                                                                                                type="checkbox"
                                                                                                                                className="form-check-input"
                                                                                                                                id={`link${subData._id}`}
                                                                                                                                defaultChecked={onOuickLinkCheck(subData.menu_id)}
                                                                                                                                disabled={onOuickLinkStatus(subData.menu_id)}
                                                                                                                                onClick={(e) => onLinkHandlerUnderSub(e, item, data, subData)}
                                                                                                                            />
                                                                                                                            {subData.title}
                                                                                                                        </label>

                                                                                                                    )
                                                                                                                }
                                                                                                            })
                                                                                                        }

                                                                                                    </div>
                                                                                                )
                                                                                            }
                                                                                        })
                                                                                    }



                                                                                </div>
                                                                            }
                                                                        </>
                                                                    );
                                                                }
                                                            })
                                                        }

                                                        {/* {errors.permission && <div className="text-danger fs-12">{errors.permission}</div>} */}

                                                    </div>

                                                    <div className="modal-button-group mt-5">
                                                        <button onClick={onGoBackHandler} type="button" className="btn form-cencel-btn">
                                                            Cancel
                                                        </button>
                                                        <button type="submit" className="btn btn-primary role-form-btn" disabled={props.showLoading}>
                                                            Save
                                                        </button>
                                                    </div>

                                                </form>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="col-xl-3"></div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>


            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        showLoading: state.role.showLoading,
        permissionsList: state.role.permissionsList,
    };
};
export default connect(mapStateToProps)(CopyRole);