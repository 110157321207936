import React, { useContext, useEffect, useState, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import {
    Row,
    Col,
    Card,
    Table,
    Modal,
    Button,
    ProgressBar,
} from "react-bootstrap";
import moment from 'moment';
import ReactPaginate from "react-paginate";
import DatePicker from "react-datepicker";
import { getClientsData } from '../../../store/actions/ClientsActions';
import {
    addOwnMultipleManualTimeSheetData,
    getManualTimeSheetData, updateManualTimeSheetData, addManualTimeSheetData, updateUserTimeSheetData, removeTimeSheetData
} from '../../../store/actions/TimeCardActions';
import {
    getMultipleProjectActivitiesListData, getProjectByClientIdList, getMultipleProjectByClientIdList, getProjectActivitiesListData,
    getAccessableTaskList, getProjectDetailsByIdData, getMultipleAccessableTaskList
} from '../../../store/actions/ProjectActions';
import { getAssignedTaskData } from '../../../store/actions/TimeCaptureActions';
import { globalLoadingAction } from '../../../store/actions/GlobalAction';
import DateRangeModal from '../Components/DateRangeModal';
import SheetReviewModal from '../Components/SheetReviewModal';

const ManualTimeEntry = (props) => {

    const dispatch = useDispatch();
    const history = useHistory();
    const { state } = useLocation();

    const calendarRef = useRef();
    const startDateRef = useRef();
    const endDateRef = useRef();

    let errorsObj = { client: '', project: '', activity: '', task: '', description: '', startDate: '', endDate: '' };
    const [errors, setErrors] = useState(errorsObj);

    const [pattenArry, setPattenArry] = useState([]);

    const [client, setClient] = useState('');
    const [project, setProject] = useState('');
    const [activity, setActivity] = useState('');
    const [task, setTask] = useState('');
    const [description, setDescription] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [isDateRange, setIsDateRange] = useState(false);
    const [addModal, setAddModal] = useState(false);
    const [action, setAction] = useState(1);
    const [record, setRecord] = useState(null);
    const [projectStartDate, setProjectStartDate] = useState('');
    const [projectEndDate, setProjectEndDate] = useState('');
    const [reviewModal, setReviewModal] = useState(false);

    //filter
    const [searchText, setSearchText] = useState('');
    const [sordBy, setSordBy] = useState('');
    const [sort, setSort] = useState(null);
    const [filterType, setFilterType] = useState(0);
    const [filterStartDate, setFilterStartDate] = useState('');
    const [filterEndtDate, setFilterEndtDate] = useState('');
    const [filterClient, setFilterClient] = useState('');
    const [filterProject, setFilterProject] = useState('');
    const [filterActivity, setFilterActivity] = useState('');
    const [filterTask, setFilterTask] = useState('');
    const [pageNo, setPageNo] = useState(1);
    const [perPage, setPerPage] = useState(10);


    useEffect(() => {
        setPattenArry([{
            id: uuidv4(),
            client_id: '',
            project_id: '',
            activity_id: '',
            task_id: '',
            description: '',
            start_time: '',
            end_time: '',
            project: [],
            activity: [],
            task: []
        }]);
        dispatch(globalLoadingAction(true));
        dispatch(getManualTimeSheetData(filterType, filterStartDate, filterEndtDate, filterClient, filterProject, filterActivity, filterTask, 1, perPage, props.history));
        dispatch(getClientsData("", "", "", 1, 0, props.history));
        //dispatch(getProjectList('', '', '', 1, 0, props.systemAdmin, props.history));
    }, []);

    useEffect(() => {
        if (props.projectDetails?.start_date) {
            setProjectStartDate(new Date(props.projectDetails.start_date));
            setProjectEndDate(new Date(props.projectDetails.end_date));
        }
    }, [props.projectDetails]);

    const onAddHandler = () => {
        const errorObj = { ...errorsObj };
        setErrors(errorObj);
        setRecord('');
        setProject('');
        setActivity('');
        setTask('');
        setStartDate('');
        setEndDate('');
        setDescription('');
        setAddModal(true);
        setAction(1);
    }


    function onUpdateData(e) {

        e.preventDefault();

        let error = false;
        const errorObj = { ...errorsObj };

        if (client === '') {
            errorObj.client = 'Client is Required';
            error = true;
        }
        if (project === '') {
            errorObj.project = 'Project is Required';
            error = true;
        }
        if (activity === '') {
            errorObj.activity = 'Activity is Required';
            error = true;
        }
        if (task === '') {
            errorObj.task = 'Task is Required';
            error = true;
        }
        if (startDate === '') {
            errorObj.startDate = 'Start date is Required';
            error = true;
        }
        if (endDate === '') {
            errorObj.endDate = 'End date is Required';
            error = true;
        }
        if (startDate >= projectStartDate && endDate >= projectEndDate) {
            setIsDateRange(true);
            error = true;
        }
        if (description === '') {
            errorObj.description = 'Description is Required';
            error = true;
        }

        setErrors(errorObj);
        if (error) {
            return;
        }
        let obj = {
            filterType,
            filterStartDate,
            filterEndtDate,
            filterClient,
            filterProject,
            filterActivity,
            filterTask,
            page: parseInt(pageNo),
            perPage
        }

        if (action == 1) {
            setAddModal(false);
            setReviewModal(true);
        } else {
            dispatch(globalLoadingAction(true));
            dispatch(updateManualTimeSheetData(
                props.systemAdmin,
                props.userId,
                record,
                project == "0" ? "" : project,
                activity == "0" ? "" : activity,
                task == "0" ? "" : task,
                description,
                startDate,
                endDate,
                obj,
                props.history));
            setAddModal(false);
            cleanHandler();
        }

    }

    const onFinalSubmitHandler = () => {
        dispatch(globalLoadingAction(true));
        dispatch(addManualTimeSheetData(
            props.systemAdmin,
            props.userId,
            project == "0" ? "" : project,
            activity == "0" ? "" : activity,
            task == "0" ? "" : task,
            description,
            startDate,
            endDate,
            perPage,
            props.history));
        setPageNo(1);
        filterCline();
        setReviewModal(false);
        cleanHandler();
    }

    const onReviewCloseHandler = () => {
        setAddModal(true);
        setReviewModal(false);
    }

    const cleanHandler = () => {
        setClient('');
        setProject('');
        setActivity('');
        setTask('');
        setDescription('');
        setStartDate('');
        setEndDate('');
        setPattenArry([{
            id: uuidv4(),
            client_id: '',
            project_id: '',
            activity_id: '',
            task_id: '',
            description: '',
            start_time: '',
            end_time: '',
            project: [],
            activity: [],
            task: []
        }]);
    }

    const filterCline = () => {
        setFilterStartDate('');
        setFilterEndtDate('');
        setFilterClient('');
        setFilterProject('');
        setFilterActivity('');
        setFilterTask('');
    }



    const onClientHandler = (val) => {
        setClient(val);
        setProject('');
        setActivity('');
        setTask('');
        dispatch(getProjectByClientIdList(val, props.history));
    }

    const onProjectHandler = (val) => {
        setProject(val);
        setActivity('');
        setTask('');
        if (val) {
            dispatch(getProjectDetailsByIdData(val, props.history));
            dispatch(getProjectActivitiesListData(val, '', '', '', 1, 0, props.systemAdmin, props.history));
        }
    }

    const onActivityHander = (val) => {
        setActivity(val);
        setTask('');
        dispatch(getAccessableTaskList(val, props.systemAdmin ? "" : state.id, props.history));
    }

    const onSearchHandler = (val) => {
        setSearchText(val);
        setSort(null);
        setSordBy('');
        setPageNo(1);
        const timerId = setTimeout(() => {
            dispatch(globalLoadingAction(true));
            dispatch(getAssignedTaskData(val, '', '', 1, perPage, props.history));
        }, 1000);
        return () => clearTimeout(timerId); // Cleanup on component unmount or re-render
    }

    const handlePageClick = (data) => {
        setPageNo(data.selected + 1);
        dispatch(globalLoadingAction(true));
        dispatch(getManualTimeSheetData(
            filterType,
            filterStartDate ? moment(filterStartDate).format('YYYY-MM-DD') : '',
            filterEndtDate ? moment(filterEndtDate).format('YYYY-MM-DD') : '',
            filterClient,
            filterProject,
            filterActivity,
            filterTask,
            data.selected + 1,
            perPage,
            props.history));
    };

    const onFilterClientHandler = (val) => {
        setFilterClient(val);
        setFilterProject('');
        setFilterActivity('');
        setFilterTask('');
        setPageNo(1);
        dispatch(globalLoadingAction(true));
        dispatch(getManualTimeSheetData(
            filterType,
            filterStartDate ? moment(filterStartDate).format('YYYY-MM-DD') : '',
            filterEndtDate ? moment(filterEndtDate).format('YYYY-MM-DD') : '',
            val,
            filterProject,
            filterActivity,
            filterTask,
            1,
            perPage,
            props.history));
        if (val) {
            dispatch(getProjectByClientIdList(val, props.history));
        }

    }

    const onFilterProjectHandler = (val) => {
        setFilterProject(val);
        setFilterActivity('');
        setFilterTask('');
        setPageNo(1);
        dispatch(globalLoadingAction(true));
        dispatch(getManualTimeSheetData(
            filterType,
            filterStartDate ? moment(filterStartDate).format('YYYY-MM-DD') : '',
            filterEndtDate ? moment(filterEndtDate).format('YYYY-MM-DD') : '',
            filterClient,
            val,
            filterActivity,
            filterTask,
            1,
            perPage,
            props.history));
        if (val) {
            dispatch(getProjectActivitiesListData(val, '', '', '', 1, 0, props.systemAdmin, props.history));
        }
    }

    const onFilterActivityHander = (val) => {
        setFilterActivity(val);
        setFilterTask('');
        setPageNo(1);
        dispatch(globalLoadingAction(true));
        dispatch(getManualTimeSheetData(
            filterType,
            filterStartDate ? moment(filterStartDate).format('YYYY-MM-DD') : '',
            filterEndtDate ? moment(filterEndtDate).format('YYYY-MM-DD') : '',
            filterClient,
            filterProject,
            val,
            filterTask,
            1,
            perPage,
            props.history));
        if (val) {
            dispatch(getAccessableTaskList(val, props.systemAdmin ? "" : props.userId, props.history));
        }
    }

    const onFilterTaskHandler = (val) => {
        setFilterTask(val);
        setPageNo(1);
        dispatch(globalLoadingAction(true));
        dispatch(getManualTimeSheetData(
            filterType,
            filterStartDate ? moment(filterStartDate).format('YYYY-MM-DD') : '',
            filterEndtDate ? moment(filterEndtDate).format('YYYY-MM-DD') : '',
            filterClient,
            filterProject,
            filterActivity,
            val,
            1,
            perPage,
            props.history));
    }

    const onDateHandler = (dates) => {
        const [start, end] = dates;
        setFilterStartDate(start);
        setFilterEndtDate(end);
        setPageNo(1);
        dispatch(globalLoadingAction(true));
        dispatch(getManualTimeSheetData(
            filterType,
            start ? moment(start).format('YYYY-MM-DD') : '',
            end ? moment(end).format('YYYY-MM-DD') : '',
            filterClient,
            filterProject,
            filterActivity,
            filterTask,
            1,
            perPage,
            props.history));
    }

    const onCleanHandler = () => {
        setPageNo(1);
        setFilterStartDate('');
        setFilterEndtDate('');
        setFilterProject('');
        setFilterActivity('');
        setFilterTask('');
        dispatch(globalLoadingAction(true));
        dispatch(getManualTimeSheetData(0, '', '', '', '', '', '', 1, perPage, props.history));
    }

    const onSordByHandler = (val) => {
        setSordBy(val);
        setSort(null);
    }

    const onSordHandler = () => {
        if (sordBy) {
            setSort(!sort);
            dispatch(globalLoadingAction(true));
            dispatch(getAssignedTaskData(searchText, sordBy, !sort ? 'asc' : 'desc', 1, perPage, props.history));
            setPageNo(1);
        }
    }

    const onFilterTypeHandler = (val) => {
        setFilterType(val);
        // dispatch(globalLoadingAction(true));
        // dispatch(getTimeCaptureDetails(parseInt(val), '', '', 1, perPage ? parseInt(perPage) : 10, props.history));
        // setPageNo(1);
        // setStartDate('');
        // setEndDate('');
    }

    const onEditHandler = (item) => {
        // dispatch(getProjectDetailsByIdData(item.project_id, props.history));
        dispatch(getProjectByClientIdList(item.client_id, props.history));
        dispatch(getProjectActivitiesListData(item.project_id, '', '', '', 1, 0, props.systemAdmin, props.history));
        dispatch(getAccessableTaskList(item.activity_id, props.systemAdmin ? "" : state.id, props.history));
        setRecord(item._id);
        setClient(item.client_id);
        setProject(item.project_id);
        setActivity(item.activity_id);
        setTask(item.task_id);
        setStartDate(new Date(item.start_time));
        setEndDate(new Date(item.end_time));
        setDescription(item.description);
        setProjectStartDate(new Date(item.project_start_date));
        setProjectEndDate(new Date(item.project_end_date));
        setErrors(errorsObj);
        setAddModal(true);
        setAction(2);
    }

    const onAddClientHandler = (val, index) => {
        let arry = [...pattenArry];
        arry[index].client_id = val;
        arry[index].project_id = '';
        arry[index].activity_id = '';
        arry[index].task_id = '';
        setPattenArry(arry);
        if (val) {
            dispatch(getMultipleProjectByClientIdList(val, index, callBackProject, props.history));
        }
    }
    const callBackProject = (data, index) => {
        let arry = [...pattenArry];
        arry[index].project = data;
        setPattenArry(arry);

    }
    const onAddProjectHandler = (val, index) => {
        let arry = [...pattenArry];
        arry[index].project_id = val;
        arry[index].activity_id = '';
        arry[index].task_id = '';
        setPattenArry(arry);
        if (val) {
            dispatch(getMultipleProjectActivitiesListData(val, '', '', '', 1, 0, props.systemAdmin, index, callBackActivity, props.history));
        }
    }

    const callBackActivity = (data, index) => {
        let arry = [...pattenArry];
        arry[index].activity = data;
        setPattenArry(arry);

    }

    const onAddActivityHander = (val, index) => {
        let arry = [...pattenArry];
        arry[index].activity_id = val;
        arry[index].task_id = '';
        setPattenArry(arry);
        if (val) {
            dispatch(getMultipleAccessableTaskList(val, props.systemAdmin ? "" : props.userId, index, callBackTask, props.history));
        }
    }

    const callBackTask = (data, index) => {
        let arry = [...pattenArry];
        arry[index].task = data;
        setPattenArry(arry);

    }

    const onAddTaskHandler = (val, index) => {
        let arry = [...pattenArry];
        arry[index].task_id = val;
        setPattenArry(arry);
    }

    const onAddStartDateHandler = (dates, index) => {
        let arry = [...pattenArry];
        arry[index].start_time = dates;
        setPattenArry(arry);
    }
    const onAddEndDateHandler = (dates, index) => {
        let arry = [...pattenArry];
        arry[index].end_time = dates;
        setPattenArry(arry);
    }
    const onAddDescriptionHandler = (val, index) => {
        let arry = [...pattenArry];
        arry[index].description = val;
        setPattenArry(arry);
    }

    const onMultipleAddHandler = () => {
        let obj = {
            id: uuidv4(),
            client_id: '',
            project_id: '',
            activity_id: '',
            task_id: '',
            description: '',
            start_time: '',
            end_time: '',
            project: [],
            activity: [],
            task: []
        }
        let arry = [...pattenArry];
        arry.push(obj);
        setPattenArry(arry);
    }

    const onRemoveHandler = (id) => {
        let arry = [...pattenArry];
        const index = arry.findIndex(item => item.id === id);
        arry.splice(index, 1);
        setPattenArry(arry);
    }

    const onSubmitHandler = () => {
        let arry = [];
        let obj = {}
        pattenArry.forEach((item) => {
            obj = {
                client_id: item.client_id,
                user_id: props.userId,
                project_id: item.project_id,
                activity_id: item.activity_id,
                task_id: item.task_id,
                description: item.description,
                start_time: item.start_time,
                end_time: item.end_time
            }
            arry.push(obj);
        });
        dispatch(globalLoadingAction(true));
        dispatch(addOwnMultipleManualTimeSheetData(
            arry,
            perPage,
            props.history));
        setPageNo(1);
        filterCline();
        setReviewModal(false);
        cleanHandler();
    }

    const getDisabledItem = () => {
        let status = false;
        let arry = [...pattenArry];
        for (let i = 0; i < arry.length; i++) {
            let item = arry[i];
            if (!item.client_id || !item.project_id || !item.activity_id || !item.task_id || !item.description || !item.start_time || !item.end_time) {
                status = true;
                break;
            }
        }
        return status;
    }

    return (
        <>
            <div className="row">
                <div className="breadcrumb-container">
                    <ul>
                        <li>
                            <Link to={"#"}>Reporting</Link>
                        </li>
                        <li><i className="fa fa-angle-right"></i></li>
                        <li>
                            <Link to={"#"}>Manual Entry</Link>
                        </li>
                    </ul>
                </div>
                <div className="title-body-container">
                    <div className="title-body">
                        <h3>Manual Entry</h3>
                        <p>Maintain user information - Add, edit and invite users</p>
                    </div>
                    <div className="title-filter-container">
                        {/* <div className="search-filter">
                            <input placeholder="Search" value={searchText} onChange={(e) => onSearchHandler(e.target.value)} />
                            <i class="fa fa-search" aria-hidden="true"></i>
                        </div> */}

                        {/* <div className="sort-filter">
                            <select
                                defaultValue={"option"}
                                className=""
                                value={sordBy}
                                onChange={(e) => onSordByHandler(e.target.value)}>
                                <option>Sort by</option>
                                <option value="title">Title</option>
                                <option value="description">Description</option>
                                <option value="project_name">Project</option>
                                <option value="activity">Activity</option>
                            </select>
                            <button className={sort === null ? null : sort ? "sorting-asc" : "sorting-dec"} onClick={onSordHandler}>
                                <i class="fa fa-long-arrow-up" aria-hidden="true"></i>
                                <i class="fa fa-long-arrow-down" aria-hidden="true"></i>
                            </button>
                        </div> */}

                        <div className="button-filter">
                            <div>
                                <Link to="/time-capture"
                                    className="clock-btn">
                                    <i class="fa fa-clock" aria-hidden="true"></i>
                                </Link>
                            </div>
                            {/* <button className="add-user-btn me-0" onClick={onAddHandler} ><i class="fa fa-plus" aria-hidden="true"></i></button> */}
                            {/* <button className="add-filter-up-btn"><i class="fa fa-long-arrow-up" aria-hidden="true"></i></button> */}
                        </div>

                    </div>

                </div>
                <div className="account-setting-content">
                    <Col lg={12}>
                        <Card>
                            <Card.Body>
                                {/* <div className='mb-2'>
                                    <Link to="/time-capture"
                                        className="clock-btn">
                                        <i class="fa fa-clock" aria-hidden="true"></i>
                                    </Link>
                                </div> */}
                                <div className='row mb-2'>
                                    <div class=" col-sm-12 time-capture-classification-title"><h3>Add Manual Entry</h3></div>
                                </div>
                                {pattenArry.map((item, index) => (
                                    <div
                                        key={index}
                                        className='add-multiple-section'>
                                        <div className='add-multiple-content'>
                                            <div className='row'>

                                                <div className='col-sm-3 pe-1'>
                                                    <div className="select-drop-down filter-drop-down">
                                                        <span><i class="fa fa-angle-down"></i></span>
                                                        <select
                                                            defaultValue={"option"}
                                                            className="form-control filter-control"
                                                            value={item.client_id}
                                                            onChange={(e) => onAddClientHandler(e.target.value, index)}>
                                                            <option value="">select client</option>
                                                            {
                                                                props.clientList.map((item, index) => {
                                                                    return <option key={index} value={item._id}>{item.first_name}</option>
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className='col-sm-3 pe-1 ps-1'>
                                                    <div className="select-drop-down filter-drop-down">
                                                        <span><i class="fa fa-angle-down"></i></span>
                                                        <select
                                                            defaultValue={"option"}
                                                            className="form-control filter-control"
                                                            value={item.project_id}
                                                            onChange={(e) => onAddProjectHandler(e.target.value, index)}>
                                                            <option value="">select project</option>
                                                            {
                                                                item.project.map((item, index) => {
                                                                    return <option key={index} value={item._id}>{item.title}</option>
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className='col-sm-3 pe-1 ps-1'>
                                                    <div className="select-drop-down filter-drop-down">
                                                        <span><i class="fa fa-angle-down"></i></span>
                                                        <select
                                                            defaultValue={"option"}
                                                            className="form-control filter-control"
                                                            value={item.activity_id}
                                                            onChange={(e) => onAddActivityHander(e.target.value, index)}>
                                                            <option value="">select activity</option>
                                                            {
                                                                item.activity.map((item, index) => {
                                                                    return <option key={index} value={item._id}>{item.title}</option>
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className='col-sm-3 ps-1'>
                                                    <div className="select-drop-down filter-drop-down">
                                                        <span><i class="fa fa-angle-down"></i></span>
                                                        <select
                                                            defaultValue={"option"}
                                                            className="form-control filter-control"
                                                            value={item.task_id}
                                                            onChange={(e) => onAddTaskHandler(e.target.value, index)}>
                                                            <option value="">select task</option>
                                                            {
                                                                item.task.map((item, index) => {
                                                                    return <option key={index} value={item._id}>{item.title}</option>
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className='col-sm-3 pe-1 mt-2'>
                                                    <div className="date-control-view filter-date-control me-0 width-auto">
                                                        <DatePicker
                                                            ref={calendarRef}
                                                            selected={item.start_time}
                                                            timeInputLabel="Time:"
                                                            className="form-control"
                                                            placeholderText="Start date"
                                                            dateFormat="MM/dd/yyyy h:mm aa"
                                                            showTimeInput
                                                            onChange={(dates) => onAddStartDateHandler(dates, index)}
                                                        />
                                                        <button className="picker-btn"
                                                            onClick={() => calendarRef.current.setOpen(true)}
                                                        >
                                                            <i class="fa-solid fa-calendar-days"></i>
                                                        </button>

                                                    </div>
                                                </div>
                                                <div className='col-sm-3 ps-1 pe-1 mt-2'>
                                                    <div className="date-control-view filter-date-control me-0 width-auto">
                                                        <DatePicker
                                                            ref={calendarRef}
                                                            selected={item.end_time}
                                                            timeInputLabel="Time:"
                                                            className="form-control"
                                                            placeholderText="end date"
                                                            dateFormat="MM/dd/yyyy h:mm aa"
                                                            showTimeInput
                                                            onChange={(dates) => onAddEndDateHandler(dates, index)}
                                                        />
                                                        <button className="picker-btn"
                                                            onClick={() => calendarRef.current.setOpen(true)}
                                                        >
                                                            <i class="fa-solid fa-calendar-days"></i>
                                                        </button>

                                                    </div>
                                                </div>
                                                <div className='col-sm-6 ps-1 mt-2'>
                                                    <div>
                                                        <input
                                                            className="filter-control width-100"
                                                            placeholder='Description'
                                                            value={item.description}
                                                            onChange={(e) => onAddDescriptionHandler(e.target.value, index)} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='add-multiple-btn-content'>
                                            {index == (pattenArry.length - 1) ?
                                                <button
                                                    className="add-user-btn me-0"
                                                    disabled={!item.client_id || !item.project_id || !item.activity_id || !item.task_id || !item.description || !item.start_time || !item.end_time ? true : false}
                                                    onClick={onMultipleAddHandler} ><i class="fa fa-plus" aria-hidden="true"></i></button>
                                                : <button className="add-user-btn me-0" onClick={() => onRemoveHandler(item.id)} ><i class="fa fa-minus" aria-hidden="true"></i></button>}
                                        </div>
                                    </div>
                                ))}

                                <div className='add-multiple-manualEntry'>
                                    <button
                                        disabled={getDisabledItem()}
                                        type="button"
                                        onClick={() => onSubmitHandler()}
                                        className="btn btn-primary role-form-btn">Add</button>
                                </div>



                                <div className='row mb-2'>
                                    <div class=" col-sm-12 time-capture-classification-title mb-3"><h3>Manual Entry List</h3></div>
                                    <div className='col-sm-3'>
                                        <div className="select-drop-down filter-drop-down">
                                            <span><i class="fa fa-angle-down"></i></span>
                                            <select
                                                defaultValue={"option"}
                                                className="form-control filter-control"
                                                value={filterClient}
                                                onChange={(e) => onFilterClientHandler(e.target.value)}>
                                                <option value="">select client</option>
                                                {
                                                    props.clientList.map((item, index) => {
                                                        return <option key={index} value={item._id}>{item.first_name}</option>
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div class=" col-sm-12 time-capture-classification-title"><h3>Classification</h3></div>
                                </div>

                                <div className='manual-timesheet-filter mb-2'>

                                    <div className='row manual-filter'>
                                        <div className='col-sm-3 pe-1'>
                                            <div className="select-drop-down filter-drop-down">
                                                <span><i class="fa fa-angle-down"></i></span>
                                                <select
                                                    defaultValue={"option"}
                                                    className="form-control filter-control"
                                                    value={filterProject}
                                                    onChange={(e) => onFilterProjectHandler(e.target.value)}>
                                                    <option value="">select project</option>
                                                    {
                                                        props.projectList.map((item, index) => {
                                                            return <option key={index} value={item._id}>{item.title}</option>
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <div className='col-sm-3 pe-1 ps-1'>
                                            <div className="select-drop-down filter-drop-down">
                                                <span><i class="fa fa-angle-down"></i></span>
                                                <select
                                                    defaultValue={"option"}
                                                    className="form-control filter-control"
                                                    value={filterActivity}
                                                    onChange={(e) => onFilterActivityHander(e.target.value)}>
                                                    <option value="">select activity</option>
                                                    {
                                                        props.activitiesList.map((item, index) => {
                                                            return <option key={index} value={item._id}>{item.title}</option>
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <div className='col-sm-3 pe-1 ps-1'>
                                            <div className="select-drop-down filter-drop-down">
                                                <span><i class="fa fa-angle-down"></i></span>
                                                <select
                                                    defaultValue={"option"}
                                                    className="form-control filter-control"
                                                    value={filterTask}
                                                    onChange={(e) => onFilterTaskHandler(e.target.value)}>
                                                    <option value="">select task</option>
                                                    {
                                                        props.taskList.map((item, index) => {
                                                            return <option key={index} value={item._id}>{item.title}</option>
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <div className='col-sm-3 ps-1'>
                                            <div className="date-control-view filter-date-control me-0 width-auto">
                                                <DatePicker
                                                    ref={calendarRef}
                                                    placeholderText="Select date"
                                                    selected={filterStartDate}
                                                    onChange={onDateHandler}
                                                    startDate={filterStartDate}
                                                    endDate={filterEndtDate}
                                                    selectsRange
                                                />
                                                <button className="picker-btn"
                                                    onClick={() => calendarRef.current.setOpen(true)}
                                                >
                                                    <i class="fa-solid fa-calendar-days"></i>
                                                </button>

                                            </div>
                                        </div>

                                    </div>
                                    <div className="time-capture-filter-btn ps-2">
                                        <button
                                            type='button'
                                            className='time-capture-reset-btn me-0'
                                            disabled={filterStartDate || filterProject || filterActivity || filterTask ? false : true}
                                            onClick={() => onCleanHandler()}
                                        >Reset</button>

                                    </div>
                                </div>

                                {props.screenVisible ?
                                    <>
                                        {props.list.length > 0 ?
                                            <Table responsive>
                                                <thead>
                                                    <tr>
                                                        <th>
                                                            <strong>Project</strong>
                                                        </th>
                                                        <th>
                                                            <strong>Activity</strong>
                                                        </th>
                                                        <th>
                                                            <strong>Task</strong>
                                                        </th>
                                                        <th>
                                                            <strong>Description</strong>
                                                        </th>
                                                        <th>
                                                            <strong>Time</strong>
                                                        </th>
                                                        <th>
                                                            <strong>Duration</strong>
                                                        </th>
                                                        <th>
                                                            <strong>Billable</strong>
                                                        </th>
                                                        <th>
                                                            <strong>Actions</strong>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        props.list.map((item, index) => {
                                                            return (
                                                                <tr key={index}
                                                                //className={props.acknowledged && (timeData._id == item._id) ? "activeRow" : null}
                                                                >
                                                                    <td>{item.project_name ? item.project_name : "None"}</td>
                                                                    <td>{item.activity ? item.activity : "None"}</td>
                                                                    <td>{item.task ? item.task : "None"}</td>
                                                                    <td>{item.description}</td>
                                                                    <td>{moment(item.start_time).format('LT')} - {moment(item.end_time).format('LT')}</td>
                                                                    <td>{item.time_difference}</td>
                                                                    <td>
                                                                        <div className="form-check custom-checkbox checkbox-select mx-2">
                                                                            <input
                                                                                type="checkbox"
                                                                                className="form-check-input capture-check-input"
                                                                                id={`check${index}`}
                                                                                checked={item.billable === 0 ? false : true}
                                                                                disabled={true}
                                                                            //onClick={(e) => onBillableHandler(e, item)}
                                                                            />
                                                                            <label
                                                                                className="form-check-label"
                                                                                htmlFor={`check${index}`}
                                                                            ></label>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="d-flex">
                                                                            <Button
                                                                                onClick={() => onEditHandler(item)}
                                                                                className="btn btn-warning shadow btn-xs sharp me-1"
                                                                                disabled={props.acknowledged}
                                                                            >
                                                                                <i className="fas fa-pencil-alt"></i>
                                                                            </Button>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })
                                                    }
                                                </tbody>
                                            </Table>
                                            :
                                            <div className="nodata-found-view">
                                            <h3>No Transactions</h3>
                                        </div>
                                        }
                                    </>
                                    : null}

                                <div className="pagination-container">
                                    {parseInt(props.totalRecord) > 0 && props.showLoading == false ? (
                                        <div className="page-result alignResult">
                                            <p>Showing{" "}{(pageNo - 1) * perPage + 1 + " - " + ((pageNo - 1) * perPage + props.list.length) + " of " + "( " + props.totalRecord + " items )"}</p>
                                        </div>
                                    ) : (
                                        <div></div>
                                    )}

                                    {parseInt(props.totalRecord) > 10 ? (
                                        <ReactPaginate
                                            containerClassName="pagination-gutter false pagination-circle pagination"
                                            pageClassName="page-item"
                                            pageLinkClassName="page-link"
                                            breakLabel="..."
                                            nextLabel={<i class="fa fa-angle-right" aria-hidden="true"></i>}
                                            nextClassName="page-item"
                                            nextLinkClassName="page-link"
                                            previousLabel={<i class="fa fa-angle-left" aria-hidden="true"></i>}
                                            previousClassName="page-item"
                                            previousLinkClassName="page-link"
                                            breakClassName="page-item"
                                            breakLinkClassName="page-link"
                                            renderOnZeroPageCount={null}
                                            activeClassName="active"
                                            onPageChange={handlePageClick}
                                            pageRangeDisplayed={3}
                                            pageCount={props.totalPage}
                                            //initialPage={active - 1}
                                            forcePage={pageNo - 1}
                                        />
                                    ) : null}
                                </div>

                            </Card.Body>
                        </Card>
                    </Col>
                </div>

                <Modal
                    className="fade bd-example-modal-sm"
                    size="lg"
                    show={addModal}
                    onHide={() => setAddModal(false)}
                >
                    <div className="add-user-modal-form-view client-modal">
                        <button onClick={() => setAddModal(false)} className="modal-cross-btn"><i class="fa fa-times" aria-hidden="true"></i></button>
                        <div className="modal-title-view">
                            <h3>{action == 1 ? "Add" : "Update"} Timesheet</h3>
                        </div>
                        <div className="basic-form">
                            <form onSubmit={onUpdateData}>
                                <div className="row">
                                    <div className="form-group form-group-modal-custom mb-3 col-6">
                                        <label className="mb-1">
                                            <strong>Client</strong>
                                        </label>
                                        <div className="select-drop-down">
                                            <span><i class="fa fa-angle-down"></i></span>
                                            <select
                                                defaultValue={"option"}
                                                className="form-control select-control"
                                                value={client}
                                                onChange={(e) => onClientHandler(e.target.value)}>
                                                <option value=""></option>
                                                {
                                                    props.clientList.map((item, index) => {
                                                        return <option key={index} value={item._id}>{item.first_name}</option>
                                                    })
                                                }
                                            </select>
                                        </div>
                                        {errors.client && <div className="text-danger fs-12">{errors.client}</div>}
                                    </div>
                                    <div className="form-group form-group-modal-custom mb-3 col-6">
                                        <label className="mb-1">
                                            <strong>Project</strong>
                                        </label>
                                        <div className="select-drop-down">
                                            <span><i class="fa fa-angle-down"></i></span>
                                            <select
                                                defaultValue={"option"}
                                                className="form-control select-control"
                                                value={project}
                                                onChange={(e) => onProjectHandler(e.target.value)}>
                                                <option value=""></option>
                                                {
                                                    props.projectList.map((item, index) => {
                                                        return <option key={index} value={item._id}>{item.title}</option>
                                                    })
                                                }
                                            </select>
                                            {errors.project && <div className="text-danger fs-12">{errors.project}</div>}
                                        </div>
                                    </div>

                                    <div className="form-group form-group-modal-custom mb-3 col-6">
                                        <label className="mb-1">
                                            <strong>Activity</strong>
                                        </label>
                                        <div className="select-drop-down">
                                            <span><i class="fa fa-angle-down"></i></span>
                                            <select
                                                defaultValue={"option"}
                                                className="form-control select-control"
                                                value={activity}
                                                onChange={(e) => onActivityHander(e.target.value)}>
                                                <option value=""></option>
                                                {
                                                    props.activitiesList.map((item, index) => {
                                                        return <option key={index} value={item._id}>{item.title}</option>
                                                    })
                                                }
                                            </select>
                                            {errors.activity && <div className="text-danger fs-12">{errors.activity}</div>}
                                        </div>
                                    </div>

                                    <div className="form-group form-group-modal-custom mb-3 col-6">
                                        <label className="mb-1">
                                            <strong>Task</strong>
                                        </label>
                                        <div className="select-drop-down">
                                            <span><i class="fa fa-angle-down"></i></span>
                                            <select
                                                defaultValue={"option"}
                                                className="form-control select-control"
                                                value={task}
                                                onChange={(e) => setTask(e.target.value)}>
                                                <option value=""></option>
                                                {
                                                    props.taskList.map((item, index) => {
                                                        return <option key={index} value={item._id}>{item.title}</option>
                                                    })
                                                }
                                            </select>
                                            {errors.task && <div className="text-danger fs-12">{errors.task}</div>}
                                        </div>
                                    </div>

                                    <div className="form-group form-group-modal-custom mb-3 col-6">
                                        <label className="mb-1">
                                            <strong>Start time</strong>
                                        </label>
                                        <div className="form-calendar">
                                            <DatePicker
                                                ref={startDateRef}
                                                selected={startDate}
                                                timeInputLabel="Time:"
                                                className="form-control"
                                                placeholderText=""
                                                dateFormat="MM/dd/yyyy h:mm aa"
                                                showTimeInput
                                                onChange={(date) => setStartDate(date)}
                                            />
                                            <button type='button' className="form-calendar-btn"
                                                onClick={() => startDateRef.current.setOpen(true)}
                                            >
                                                <i class="fa-regular fa-calendar-days"></i>
                                            </button>
                                        </div>
                                        {errors.startDate && <div className="text-danger fs-12">{errors.startDate}</div>}
                                    </div>

                                    <div className="form-group form-group-modal-custom mb-3 col-6">
                                        <label className="mb-1">
                                            <strong>End time</strong>
                                        </label>
                                        <div className="form-calendar">
                                            <DatePicker
                                                ref={endDateRef}
                                                selected={endDate}
                                                timeInputLabel="Time:"
                                                className="form-control"
                                                placeholderText=""
                                                dateFormat="MM/dd/yyyy h:mm aa"
                                                showTimeInput
                                                onChange={(date) => setEndDate(date)}
                                            />
                                            <button type='button' className="form-calendar-btn"
                                                onClick={() => endDateRef.current.setOpen(true)}
                                            >
                                                <i class="fa-regular fa-calendar-days"></i>
                                            </button>
                                        </div>
                                        {errors.endDate && <div className="text-danger fs-12">{errors.endDate}</div>}
                                    </div>

                                    <div className="form-group mb-3 col-12">
                                        <label className="mb-1">
                                            <strong>Description</strong>
                                        </label>
                                        <textarea
                                            className="form-control"
                                            rows={2}
                                            //placeholder='Description'
                                            value={description}
                                            onChange={(e) => setDescription(e.target.value)} />
                                        {errors.description && <div className="text-danger fs-12">{errors.description}</div>}
                                    </div>

                                </div>

                                <div className="modal-button-group mt-4">
                                    <button type="button" onClick={() => setAddModal(false)} className="btn form-cencel-btn">
                                        Cancel
                                    </button>
                                    <button type="submit" className="btn btn-primary role-form-btn">
                                        {action == 1 ? "Add" : "Update"}
                                    </button>
                                </div>
                            </form>
                        </div>

                    </div>
                </Modal>
                <DateRangeModal
                    show={isDateRange}
                    onClose={() => setIsDateRange(false)}
                />
                <SheetReviewModal
                    title={"Are you soure ?"}
                    description={"you want to add time"}
                    show={reviewModal}
                    onClose={() => onReviewCloseHandler()}
                    onSubmit={onFinalSubmitHandler}
                />
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        screenVisible: state.timeCard.screenVisible,
        showLoading: state.timeCard.showLoading,
        clientList: state.clients.clientList,
        projectList: state.project.projectList,
        activitiesList: state.project.activitiesList,
        taskList: state.project.taskList,
        list: state.timeCard.list,
        totalPage: state.timeCard.totalPage,
        totalRecord: state.timeCard.totalRecord,
        systemAdmin: state.auth.systemAdmin,
        userId: state.auth.userId,
        projectDetails: state.project.projectDetails,
    };
};
export default connect(mapStateToProps)(ManualTimeEntry);