import { toast } from 'react-toastify'

export function showSuccess(message) {
    toast.success(message);
}
export function showError(message) {
    toast.error(message);
}
export function showWarning(message) {
    toast.warning(message);
}
export function validateMaks(text) {
    let pattern = /^[1-9]\d{0,2}$/.test(text);
    return pattern;
}
export function validateClass(text) {
    let pattern = /^[1-9][0]?$/.test(text);
    return pattern;
}
export function validateClassCode(text) {
    let pattern = /^(?=[MDCLXVI])M*(C[MD]|D?C{0,3})(X[CL]|L?X{0,3})(I[XV]|V?I{0,3})$/.test(text);
    return pattern;
}
export function validateText(text) {
    let pattern = /^[a-zA-Z0-9\s]{3,}$/i.test(text);
    return pattern;
}
export function validateShortCode(text) {
    let pattern = /^[A-Z0-9]{2,}$/.test(text);
    return pattern;
}
export function validateName(name) {
    let pattern = /^[a-zA-Z]{2,40}( [a-zA-Z]{2,40})+$/.test(name);
    return pattern;
}
export function validateTextOnly(text) {
    let pattern = /^[a-zA-Z]{3,}$/.test(text);
    return pattern;
}
export function validateSubheading(text) {
    let pattern = /^[^\s\.,-][a-zA-Z0-9\.,]*([\s]?[a-zA-Z0-9,\.])*$/.test(text);
    return pattern;
}
export function validateEmail(email) {
    var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    if (reg.test(email) === false) {
        return true;
    }
}
export function validatePhone(phone) {
    if (parseInt(phone) == 0) {
        return false;
    }
    // var validatePhone = /^(1\s|1|)?((\(\d{3}\))|\d{3})(\-|\s)?(\d{3})(\-|\s)?(\d{4})$/.test(phone);
    var validatePhone = /^\+?\d{6,15}$/.test(phone);
    return validatePhone;
}
export function validatePassword(password) {
    //eslint-disable-next-line
    var anUpperCase = /[A-Z]/;
    //eslint-disable-next-line
    var aLowerCase = /[a-z]/;
    //eslint-disable-next-line
    var aNumber = /[0-9]/;
    //eslint-disable-next-line
    var aSpecial = /[!|@|#|$|%|^|&|*|(|)|-|_]/;
    var obj = {};
    obj.result = true;

    if (password.length < 8) {
        obj.result = false;
        obj.error = "Password requires minimum of 8 characters"
        return obj;
    }

    var numUpper = 0;
    var numNums = 0;
    var numSpecials = 0;
    for (var i = 0; i < password.length; i++) {
        if (anUpperCase.test(password[i]))
            numUpper++;
        else if (aNumber.test(password[i]))
            numNums++;
        else if (aSpecial.test(password[i]))
            numSpecials++;
    }

    if (numUpper < 1 || numNums < 1 || numSpecials < 1) {
        obj.result = false;
        obj.error = "Password requires at least 1 Upper case, 1 numeric, 1 special character";
        return obj;
    }
    return obj;
}
