import React, { useContext, useEffect, useState, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import { Link, useHistory, useLocation } from 'react-router-dom';
import swal from "sweetalert";
import Select from 'react-select';
import DropdownMultiselect from "react-multiselect-dropdown-bootstrap";
import {
    Row,
    Col,
    Card,
    Table,
    Modal,
    Button,
    ProgressBar,
} from "react-bootstrap";
import ReactPaginate from "react-paginate";
import AddProjectTask from '../Components/AddProjectTask';
import { getAssignedTaskData } from '../../../store/actions/TimeCaptureActions';
import { globalLoadingAction } from '../../../store/actions/GlobalAction';

const TaskList = (props) => {

    const dispatch = useDispatch();
    const history = useHistory();
    const { state } = useLocation();

    let errorsObj = { title: '', desc: '', activity: '', status: '', timeKeeper: '' };
    const [errors, setErrors] = useState(errorsObj);

    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [activity, setActivity] = useState('');
    const [timeKeeper, setTimeKeeper] = useState([]);
    const [status, setStatus] = useState('');
    const [taskAction, setTaskAction] = useState(1);
    const [taskId, setTaskId] = useState('');
    const [optionsArray, setOptionsArray] = useState([]);
    const [taskModal, setTaskModal] = useState(false);
    const [viewProjectModal, setViewProjectModal] = useState(false);
    const [details, setDetails] = useState({});

    const [isDisabled, setIsDisabled] = useState(true);
    const [checkArray, setCheckArray] = useState([]);


    //filter
    const [searchText, setSearchText] = useState('');
    const [sordBy, setSordBy] = useState('');
    const [sort, setSort] = useState(null);
    const [pageNo, setPageNo] = useState(1);
    const [perPage, setPerPage] = useState(10);


    useEffect(() => {
        dispatch(globalLoadingAction(true));
        dispatch(getAssignedTaskData('', '', '', 1, perPage, props.history));
    }, []);

    useEffect(() => {
        let array = [];
        let obj = {};
        props.groupKeeperList.forEach((emp, index) => {
            obj = { value: emp._id, label: emp.first_name };
            array.push(obj);
        });
        setOptionsArray(array);
    }, [props.groupKeeperList]);


    const onViewProjectHandler = (item) => {
        setTitle(item.title);
        setDescription(item.description);
        setStatus(item.status);
        setActivity(item.activity);
        setViewProjectModal(true);
    }

    const onCleanHandler = () => {
        setSearchText('');
        setPageNo(1);
        setSort(null);
        setSordBy('');
        dispatch(globalLoadingAction(true));
        dispatch(getAssignedTaskData('', '', '', '', 1, perPage, props.history));
    }

    const onSearchHandler = (val) => {
        setSearchText(val);
        setSort(null);
        setSordBy('');
        setPageNo(1);
        const timerId = setTimeout(() => {
            dispatch(globalLoadingAction(true));
            dispatch(getAssignedTaskData(val, '', '', 1, perPage, props.history));
        }, 1000);
        return () => clearTimeout(timerId); // Cleanup on component unmount or re-render
    }

    const handlePageClick = (data) => {
        setPageNo(data.selected + 1);
        dispatch(globalLoadingAction(true));
        dispatch(getAssignedTaskData(searchText, '', '', data.selected + 1, perPage, props.history));
        setSort(null);
        setSordBy('');
    };

    const onSordByHandler = (val) => {
        setSordBy(val);
        setSort(null);
    }

    const onSordHandler = () => {
        if (sordBy) {
            setSort(!sort);
            dispatch(globalLoadingAction(true));
            dispatch(getAssignedTaskData(searchText, sordBy, !sort ? 'asc' : 'desc', 1, perPage, props.history));
            setPageNo(1);
        }
    }

    const onTimeCaptureHandler = (items) => {
        history.push({
            pathname: '/real-time-capture',
            state: items,
        });
    }

    const onBackHandler = () => {
        history.goBack();
    }

    return (
        <>
            <div className="row">
                <div className="breadcrumb-container">
                    {/* <ul>
                        <li><Link to={"#"}>Tasks</Link></li>
                    </ul> */}
                </div>
                <div className="title-body-container">
                    <div className="title-body">
                        <h3>Tasks</h3>
                        <p>Maintain user information - Add, edit, delete and invite users</p>
                    </div>
                    <div className="title-filter-container">
                        <div className="search-filter">
                            <input placeholder="Search" value={searchText} onChange={(e) => onSearchHandler(e.target.value)} />
                            <i class="fa fa-search" aria-hidden="true"></i>
                        </div>

                        <div className="sort-filter">
                            <select
                                defaultValue={"option"}
                                className=""
                                value={sordBy}
                                onChange={(e) => onSordByHandler(e.target.value)}>
                                <option>Sort by</option>
                                <option value="title">Title</option>
                                <option value="description">Description</option>
                                <option value="project_name">Project</option>
                                <option value="activity">Activity</option>
                            </select>
                            <button className={sort === null ? null : sort ? "sorting-asc" : "sorting-dec"} onClick={onSordHandler}>
                                <i class="fa fa-long-arrow-up" aria-hidden="true"></i>
                                <i class="fa fa-long-arrow-down" aria-hidden="true"></i>
                            </button>
                        </div>

                        <div className="button-filter">
                            {/* <button className="add-user-btn" onClick={() => onAddProjectHandler()}><i class="fa fa-plus" aria-hidden="true"></i></button> */}
                            <button className="add-filter-up-btn"><i class="fa fa-long-arrow-up" aria-hidden="true"></i></button>
                        </div>

                    </div>

                </div>
                <div className="account-setting-content">
                    <Col lg={12}>
                        <Card>
                            {/* <Card.Header>
                                <div className="add-role-button-view activities-list">
                                    <div><button onClick={onBackHandler} className="activities-back-btn"><i class="fa fa-arrow-left" aria-hidden="true"></i></button></div>
                                    <div className="add-btn-group">
                                        <button disabled={isDisabled}><i class="fa fa-external-link" aria-hidden="true"></i></button>
                                        <button
                                            //onClick={() => onMultipleRemoveHandler()}
                                            disabled={isDisabled}>X</button>
                                        <button disabled={isDisabled}>S</button>
                                        <button
                                            //onClick={() => onDownloadHandler()}
                                            disabled={isDisabled}>
                                            <i class="fa fa-download" aria-hidden="true"></i></button>
                                    </div>
                                </div>
                            </Card.Header> */}
                            <Card.Body>
                                {props.screenVisible ?
                                    <>
                                        {props.taskList.length > 0 ?
                                            <Table responsive>
                                                <thead>
                                                    <tr>
                                                        <th>
                                                            <strong>Title</strong>
                                                        </th>
                                                        <th>
                                                            <strong>Description</strong>
                                                        </th>
                                                        <th>
                                                            <strong>Project</strong>
                                                        </th>
                                                        <th>
                                                            <strong>Activity</strong>
                                                        </th>
                                                        <th>
                                                            <strong>View</strong>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        props.taskList.map((item, index) => {
                                                            return (
                                                                <tr key={index}>

                                                                    <td>
                                                                        <Link
                                                                            onClick={() => onTimeCaptureHandler(item)}
                                                                            className="project-title"
                                                                        >
                                                                            {item.title}
                                                                        </Link>

                                                                    </td>
                                                                    <td>{item.description}</td>
                                                                    <td>{item.project_name}</td>
                                                                    <td>{item.activity_name}</td>
                                                                    <td>
                                                                        <div className="d-flex">
                                                                            <Link
                                                                                onClick={() => onViewProjectHandler(item)}
                                                                                className="btn btn-info shadow btn-xs sharp"
                                                                            >
                                                                                <i className="fa fa-external-link-square"></i>
                                                                            </Link>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })
                                                    }
                                                </tbody>
                                            </Table>
                                            :
                                            <div className="nodata-found-view">
                                                <i class="fa fa-search" aria-hidden="true"></i>
                                                <h2>Data not found</h2>
                                                <p>Task data is empty or Try again search</p>
                                                <button type="button" onClick={onCleanHandler}>
                                                    Retry
                                                </button>
                                            </div>
                                        }
                                    </>
                                    : null}

                                <div className="pagination-container">
                                    {parseInt(props.totalTaskRecord) > 0 && props.showLoading == false ? (
                                        <div className="page-result alignResult">
                                            <p>Showing{" "}{(pageNo - 1) * perPage + 1 + " - " + ((pageNo - 1) * perPage + props.taskList.length) + " of " + "( " + props.totalTaskRecord + " items )"}</p>
                                        </div>
                                    ) : (
                                        <div></div>
                                    )}

                                    {parseInt(props.totalTaskRecord) > 10 ? (
                                        <ReactPaginate
                                            containerClassName="pagination-gutter false pagination-circle pagination"
                                            pageClassName="page-item"
                                            pageLinkClassName="page-link"
                                            breakLabel="..."
                                            nextLabel={<i class="fa fa-angle-right" aria-hidden="true"></i>}
                                            nextClassName="page-item"
                                            nextLinkClassName="page-link"
                                            previousLabel={<i class="fa fa-angle-left" aria-hidden="true"></i>}
                                            previousClassName="page-item"
                                            previousLinkClassName="page-link"
                                            breakClassName="page-item"
                                            breakLinkClassName="page-link"
                                            renderOnZeroPageCount={null}
                                            activeClassName="active"
                                            onPageChange={handlePageClick}
                                            pageRangeDisplayed={3}
                                            pageCount={props.totalTaskPage}
                                            //initialPage={active - 1}
                                            forcePage={pageNo - 1}
                                        />
                                    ) : null}
                                </div>

                            </Card.Body>
                        </Card>
                    </Col>
                </div>

                <Modal
                    className="fade bd-example-modal-sm"
                    size="lg"
                    show={viewProjectModal}
                >
                    <div className="add-user-modal-form-view">
                        <div className="modal-title-view">
                            <h3>View Task</h3>
                            <p>View Project Task Details</p>
                        </div>
                        <div className="basic-form">

                            <div className="row">

                                <div className="col-md-6 mb-3">
                                    <div className="form-view-info">
                                        <h3>Project : <span>{title}</span></h3>
                                    </div>
                                </div>

                                <div className="col-md-6 mb-3">
                                    <div className="form-view-info">
                                        <h3>Desc : <span>{description}</span></h3>
                                    </div>
                                </div>

                                <div className="col-md-6 mb-3">
                                    <div className="form-view-info">
                                        <h3>Activity : <span>{activity}</span></h3>
                                    </div>
                                </div>

                                <div className="col-md-6 mb-3">
                                    <div className="form-view-info">
                                        <h3>Status : <span>{status == 1 ? 'Active' : 'Inactive'}</span></h3>
                                    </div>
                                </div>

                            </div>

                            <div className="modal-button-group mt-4">
                                <button type="button" onClick={() => setViewProjectModal(false)} className="btn form-cencel-btn">
                                    Close
                                </button>
                            </div>

                        </div>

                    </div>
                </Modal>

            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        screenVisible: state.timeCapture.screenVisible,
        showLoading: state.timeCapture.showLoading,
        groupKeeperList: state.employee.groupKeeperList,
        taskList: state.timeCapture.taskList,
        totalTaskPage: state.timeCapture.taskPage,
        totalTaskRecord: state.timeCapture.taskRecord,
    };
};
export default connect(mapStateToProps)(TaskList);