import React, { Fragment, useContext, useEffect, useState, useRef } from "react";
import { connect, useDispatch } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import {
    Row,
    Col,
    Card,
    Table,
    Modal,
    Button,
    Accordion,
    Spinner
} from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { loadingTeamAction, getActiveTeamList } from '../../../store/actions/TeamActions';
import { getClientsData, loadingClientsAction } from '../../../store/actions/ClientsActions';

const AddActivitie = (props) => {

    const dispatch = useDispatch();
    const history = useHistory();
    const startDateRef = useRef();
    const endDateRef = useRef();

    let errorsObj = { pId: '', title: '', desc: '', startDate: '', endDate: '' };
    const [errors, setErrors] = useState(errorsObj);

    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [activitiesId, setActivitiesId] = useState('');
    const [status, setStatus] = useState('');

    useEffect(() => {
        resetValue();
    }, [props.show]);

    const resetValue = () => {
        const errorObj = { ...errorsObj };
        errorObj.title = '';
        errorObj.desc = '';
        errorObj.startDate = '';
        errorObj.endDate = '';
        setErrors(errorObj);
        if (props.action === 1) {
            setTitle('');
            setDescription('');
            setStartDate('');
            setEndDate('');
            setActivitiesId('');
            setStatus('');
        } else {
            let startDate = new Date(props.data.start_date);
            let endDate = new Date(props.data.end_date);
            setTitle(props.data.title);
            setDescription(props.data.description);
            setStartDate(startDate);
            setEndDate(endDate);
            setActivitiesId(props.data._id);
            setStatus(props.data.status);
        }
    }

    function onSubmit(e) {

        e.preventDefault();

        let error = false;
        const errorObj = { ...errorsObj };

        if (title === '') {
            errorObj.title = 'Title is Required';
            error = true;
        }
        if (description === '') {
            errorObj.desc = 'Description is Required';
            error = true;
        }
        if (startDate === '') {
            errorObj.startDate = 'Start Date is Required';
            error = true;
        }
        if (endDate === '') {
            errorObj.endDate = 'End Date is Required';
            error = true;
        }
        setErrors(errorObj);
        if (error) {
            return;
        }

        let obj = {
            'activitiesId': activitiesId,
            'title': title,
            'description': description,
            'startDate': startDate,
            'endDate': endDate,
            'status': status,
        };
        props.submitHandler(obj);

    }


    return (
        <Fragment>
            <Modal
                className="fade bd-example-modal-sm"
                size="lg"
                onHide={props.closeHandler}
                show={props.show}
            >
                <div className="add-user-modal-form-view">
                    <button onClick={props.closeHandler} className="modal-cross-btn"><i class="fa fa-times" aria-hidden="true"></i></button>
                    <div className="modal-title-view">
                            <h3>{props.action == 1 ? 'Create' : 'Edit'} Activities</h3>
                            <p>Enter Activities Details</p>
                        </div>
                    <div className="basic-form">

                        <div className="basic-form">
                            <form onSubmit={onSubmit}>

                                <div className="row">

                                    <div className="form-group form-group-modal-custom mb-3 col-6">
                                        <label className="mb-1">
                                            Title
                                        </label>
                                        <input className="form-control" value={title} onChange={(e) => setTitle(e.target.value)} />
                                        {errors.title && <div className="text-danger fs-12">{errors.title}</div>}
                                    </div>

                                    <div className="form-group form-group-modal-custom mb-3 col-6">
                                        <label className="mb-1">
                                            Description
                                        </label>
                                        <input className="form-control" value={description} onChange={(e) => setDescription(e.target.value)} />
                                        {errors.desc && <div className="text-danger fs-12">{errors.desc}</div>}
                                    </div>

                                    <div className="form-group form-group-modal-custom mb-3 col-6">
                                        <label className="mb-1">
                                            Start Date
                                        </label>
                                        <div className="form-calendar">
                                            <DatePicker
                                                ref={startDateRef}
                                                selected={startDate}
                                                className="form-control"
                                                placeholderText=""
                                                onChange={(date) => setStartDate(date)}
                                            />
                                            <button type='button' className="form-calendar-btn"
                                                onClick={() => startDateRef.current.setOpen(true)}
                                            >
                                                <i class="fa-regular fa-calendar-days"></i>
                                            </button>
                                        </div>
                                        {errors.startDate && <div className="text-danger fs-12">{errors.startDate}</div>}
                                    </div>

                                    <div className="form-group form-group-modal-custom mb-3 col-6">
                                        <label className="mb-1">
                                            End Date
                                        </label>
                                        <div className="form-calendar">
                                            <DatePicker
                                                ref={endDateRef}
                                                selected={endDate}
                                                className="form-control"
                                                placeholderText=""
                                                onChange={(date) => setEndDate(date)}
                                            />
                                            <button type='button' className="form-calendar-btn"
                                                onClick={() => endDateRef.current.setOpen(true)}
                                            >
                                                <i class="fa-regular fa-calendar-days"></i>
                                            </button>
                                        </div>
                                        {errors.endDate && <div className="text-danger fs-12">{errors.endDate}</div>}
                                    </div>

                                </div>

                                <div className="modal-button-group mt-4">
                                    <button type="button" onClick={props.closeHandler} className="btn form-cencel-btn">
                                        Cancel
                                    </button>
                                    <button type="submit" className="btn btn-primary role-form-btn">
                                        {props.action == 1 ? "Save" : "Update"}
                                    </button>
                                </div>
                            </form>
                        </div>

                    </div>
                </div>
            </Modal>
        </Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
        teamLoading: state.team.showLoading,
        activeTeamList: state.team.activeTeamList,
        clientLoading: state.clients.showLoading,
        clientList: state.clients.clientList
    };
};
export default connect(mapStateToProps)(AddActivitie);