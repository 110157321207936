import React from "react";
import {Link} from 'react-router-dom';

const Footer = () => {
	var d = new Date();
	return (
		<>
		<div className="footer">
			<div className="copyright">
				<p>© Copyright 2021 ClockMeNow. All Right Reserved.{" "}
					<a href="#" target="_blank">
						Privacy Policy
					</a>{" "} &amp; <a href="#">
						Terms and Condition
					</a>
				</p>
			</div>
		</div>
		</>
	);
};

export default Footer;
