import swal from "sweetalert";
import {
    addRole,
    getRole,
    updateRole,
    getActiveRole,
    getRolePermissions,
    updateRoleStatus,
    removeRole,
    getPrivilege,
    addPrivilege
} from '../../services/RoleService';

import {
    ADD_ROLE,
    LOADING_ROLE,
    GET_ROLE,
    GET_PERMISSION_LIST,
    UPDATE_ROLE_STATUS,
    REMOVE_ROLE,
    GET_ACTIVE_ROLE,
    GET_PREVILEDGE
} from '../constants';

import * as utility from '../../utility/Utility';
import { globalLoadingAction } from '../actions/GlobalAction';


export function getRolePermissionsData(history) {
    return (dispatch) => {
        getRolePermissions()
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(getPermissionAction(response.data.data));
                }
                dispatch(loadingRoleAction(false));
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                dispatch(loadingRoleAction(false));
                dispatch(globalLoadingAction(false));
            });
    };
}

export function addRoleData(roleName, description, permission, quickLink, status, history) {
    return (dispatch) => {
        addRole(roleName, description, permission, quickLink)
            .then((response) => {
                if (response.data.status == 200) {
                    utility.showSuccess(response.data.msg);
                } else {
                    utility.showError(response.data.msg);
                }
                if (status == 2) {
                    history.goBack();
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                //utility.showError(response.data.msg);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function updateRoleData(role_id, roleName, description, permission, quickLink, history) {
    return (dispatch) => {
        updateRole(role_id, roleName, description, permission, quickLink)
            .then((response) => {
                if (response.data.status == 200) {
                    utility.showSuccess(response.data.msg);
                    history.push('/role-management');
                } else {
                    utility.showError(response.data.msg);
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                //utility.showError(response.data.msg);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function getRoleList(company_id, history) {
    return (dispatch) => {
        getRole(company_id)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(getRoleAction(response.data.data));
                    //utility.showSuccess(response.data.msg);
                } else {
                    utility.showError(response.data.msg);
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                //utility.showError(response.data.msg);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function getActiveRoleList(company_id, history) {
    return (dispatch) => {
        getActiveRole(company_id)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(getActiveRoleAction(response.data.data));
                } else {
                    utility.showError(response.data.msg);
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                //utility.showError(response.data.msg);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function updateRoleStatusData(item, history) {
    return (dispatch) => {
        updateRoleStatus(item._id, item.status === 1 ? 0 : 1)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(updateRoleStatusAction(item));
                    utility.showSuccess(response.data.msg);
                } else {
                    utility.showError(response.data.msg);
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                //utility.showError(response.data.msg);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function removeRoleData(id, history) {
    return (dispatch) => {
        removeRole(id)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(removeRoleAction(id));
                    swal(response.data.msg, { icon: "success", });
                } else {
                    swal(response.data.msg, "error");
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                //utility.showError(response.data.msg);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function addPrivilegeData(user_id, permissions, history) {
    return (dispatch) => {
        addPrivilege(user_id, permissions)
            .then((response) => {
                if (response.data.status == 200) {
                    //utility.showSuccess(response.data.msg);
                } else {
                    utility.showError(response.data.msg);
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                dispatch(globalLoadingAction(false));
            });
    };
}

export function getPrivilegeData(user_id, item, callBack, history) {
    return (dispatch) => {
        getPrivilege(user_id)
            .then((response) => {
                console.log('response.data',response.data)
                if (response.data.status == 200) {
                    callBack(item, response.data.data.permissions);
                    dispatch(getPrivilegeAction(response.data.data.permissions));
                } else {
                    utility.showError(response.data.msg);
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                dispatch(globalLoadingAction(false));
            });
    };
}

export function getPermissionAction(data) {
    return {
        type: GET_PERMISSION_LIST,
        payload: data,
    };
}
export function addRoleAction(data) {
    return {
        type: ADD_ROLE,
        payload: data,
    };
}
export function getRoleAction(data) {
    return {
        type: GET_ROLE,
        payload: data,
    };
}
export function getActiveRoleAction(data) {
    return {
        type: GET_ACTIVE_ROLE,
        payload: data,
    };
}
export function updateRoleStatusAction(status) {
    return {
        type: UPDATE_ROLE_STATUS,
        payload: status,
    };
}
export function removeRoleAction(id) {
    return {
        type: REMOVE_ROLE,
        payload: id,
    };
}
export function getPrivilegeAction(data) {
    return {
        type: GET_PREVILEDGE,
        payload: data,
    };
}
export function loadingRoleAction(status) {
    return {
        type: LOADING_ROLE,
        payload: status,
    };
}
