import { lazy, Suspense, useEffect, useState } from 'react';

/// Components
import Index from "./jsx";
import { connect, useDispatch } from 'react-redux';
import { BrowserRouter, Route, Switch, withRouter } from 'react-router-dom';
import { Link, useHistory } from 'react-router-dom';
// action
import { checkAutoLogin } from './services/AuthService';
import { isAuthenticated } from './store/selectors/AuthSelectors';
/// Style
import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./css/style.css";
import "./css/custom.css";


const Congratulations = lazy(() => import('./jsx/pages/Congratulations'));
const SetupProfile = lazy(() => import('./jsx/pages/SetupProfile'));
const EmpLogin = lazy(() => import('./jsx/pages/EmpLogin'));
const AnnualSubscribtion = lazy(() => import('./jsx/pages/AnnualSubscribtion'));
const FreeSubscribtion = lazy(() => import('./jsx/pages/FreeSubscribtion'));
const CompanyInfo = lazy(() => import('./jsx/pages/CompanyInfo'));
const SetPassword = lazy(() => import('./jsx/pages/SetPassword'));
const Otp = lazy(() => import('./jsx/pages/Otp'));
const ResisterEmail = lazy(() => import('./jsx/pages/ResisterEmail'));
const InitialScreen = lazy(() => import('./jsx/pages/InitialScreen'));
const ResetPassword = lazy(() => import('./jsx/pages/ResetPassword'));
const ForgotPassword = lazy(() => import('./jsx/pages/ForgotPassword'));
const SignUp = lazy(() => import('./jsx/pages/Registration'));
const Login = lazy(() => import('./jsx/pages/Login'));
const Editable = lazy(() => import('./jsx/pages/Editable'));

function App(props) {

    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        checkAutoLogin(dispatch, props.history);
    }, [dispatch, props.history]);

    let routes = (

        <Switch>
            <Route path='/initial' component={InitialScreen} />
            <Route path='/login' component={Login} />
            <Route path='/page-register' component={SignUp} />
            <Route path='/forgot-password' component={ForgotPassword} />
            <Route path='/reset-password' component={ResetPassword} />
            <Route path='/resister-email' component={ResisterEmail} />
            <Route path='/otp' component={Otp} />
            <Route path='/set-password' component={SetPassword} />
            <Route path='/company-information' component={CompanyInfo} />
            <Route path='/free-subscribtion' component={FreeSubscribtion} />
            <Route path='/annual-subscribtion' component={AnnualSubscribtion} />
            <Route path='/emp-login' component={EmpLogin} />
            <Route path='/setup-profile' component={SetupProfile} />
            <Route path='/congratulations' component={Congratulations} />
        </Switch>
    );

    if (props.isAuthenticated) {
        return (
            <>
                <Suspense fallback={
                    <div id="preloader">
                        <div className="sk-three-bounce">
                            <div className="sk-child sk-bounce1"></div>
                            <div className="sk-child sk-bounce2"></div>
                            <div className="sk-child sk-bounce3"></div>
                        </div>
                    </div>
                }
                >
                    <Index />
                </Suspense>
            </>
        );
    } else {
        return (
            <div className="vh-100">
                <Suspense fallback={
                    <div id="preloader">
                        <div className="sk-three-bounce">
                            <div className="sk-child sk-bounce1"></div>
                            <div className="sk-child sk-bounce2"></div>
                            <div className="sk-child sk-bounce3"></div>
                        </div>
                    </div>
                }
                >
                    {routes}
                </Suspense>
            </div>
        );
    }


};

const mapStateToProps = (state) => {
    return {
        isAuthenticated: isAuthenticated(state),
    };
};

export default withRouter(connect(mapStateToProps)(App));

