import axios from 'axios';
import swal from "sweetalert";
import {
    checkTokenAction,
    logout,
} from '../store/actions/AuthActions';
import GlobalConfigs from "../configs/GlobalConfigs";
import * as Apis from '../apis/Apis';

export function emailVerify(email) {

    const postData = {
        email
    };

    return axios({
        url: GlobalConfigs.API_URL + Apis.SET_EMAIL_OTP_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
        },
        data: postData,
    });

}

export function verifyOtp(email, otp) {

    const postData = {
        email,
        otp
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.VERIFY_OTP_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
        },
        data: postData,
    });
}

export function setPassword(email, country, country_code, password) {

    const postData = {
        email,
        country,
        country_code,
        password
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.USER_REGISTRATION_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
        },
        data: postData,
    });
}

export function setupAccount(state, first_name, last_name, phone_no, designation) {

    const postData = {
        user_id: state._id,
        first_name,
        last_name,
        phone_no,
        designation
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.USER_SETUP_ACCOUNT_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${state.token}`
        },
        data: postData,
    });
}

export function setupCompanyInformation(user_id, company_name, company_phone, designation, country, country_code, state, city, pincode, industry, website_url, address1, address2, data) {

    const postData = {
        user_id,
        company_name,
        company_phone,
        designation,
        country,
        country_code,
        state,
        city,
        pincode,
        industry,
        website_url,
        address1,
        address2
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.COMPANY_SETUP_DETAILS_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${data.token}`
        },
        data: postData,
    });
}

export function getProfile() {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;
    let user_id = JSON.parse(getData)._id;

    const postData = {
        user_id
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.GET_USER_PROFILE_DETAILS_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}

export function signUp(email, password) {
    //axios call
    const postData = {
        email,
        password,
        returnSecureToken: true,
    };
    return axios.post(
        `https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=AIzaSyD3RPAp3nuETDn9OQimqn_YF6zdzqWITII`,
        postData,
    );
}

export function getGoogleUserInfo(accessToken) {
    return axios({
        url: "https://www.googleapis.com/oauth2/v3/userinfo",
        method: "GET",
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    });
}

export function googleLogin(email, password) {

    const postData = {
        email,
        password,
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.GOOGLE_LOGIN_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
        },
        data: postData,
    });
}

export function login(email, password) {

    const postData = {
        email,
        password,
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.USER_LOGIN_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
        },
        data: postData,
    });

}

export function forgotPassword(email) {

    const postData = {
        email
    };

    return axios({
        url: GlobalConfigs.API_URL + Apis.SEND_FORGOT_PASSWORD_OTP_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
        },
        data: postData,
    });

}

export function verifyForgotPasswordOtp(email, otp) {

    const postData = {
        email,
        otp
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.VERIFY_FORGOT_PASSWORD_OTP_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
        },
        data: postData,
    });
}

export function resetPassword(email, password) {

    const postData = {
        email,
        password
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.RESET_PASSWORD_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
        },
        data: postData,
    });
}

export function changePassword(old_password, new_password) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;
    let user_id = JSON.parse(getData)._id;

    const postData = {
        user_id,
        old_password,
        new_password
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.CHANGE_PASSWORD_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}

export function updateCompany(company_id, company_name, address1, address2, country, country_code, state, city, company_phone, pincode, industry, website_url) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        company_id,
        company_name,
        address1,
        address2,
        country,
        country_code,
        state,
        city,
        company_phone,
        pincode,
        industry,
        website_url
    };

    return axios({
        url: GlobalConfigs.API_URL + Apis.UPDATE_CONPANY_DETAILS_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function getPermission(user_id, token) {

    const postData = {
        user_id,
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.GET_PREVILEDGE_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}

export function checkToken() {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    return axios({
        url: GlobalConfigs.API_URL + Apis.CHECK_TOKEN_EXIST_API,
        method: "GET",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
    });

}

export function formatError(errorResponse) {
    switch (errorResponse.error.message) {
        case 'EMAIL_EXISTS':
            //return 'Email already exists';
            swal("Oops", "Email already exists", "error");
            break;
        case 'EMAIL_NOT_FOUND':
            //return 'Email not found';
            swal("Oops", "Email not found", "error", { button: "Try Again!", });
            break;
        case 'INVALID_PASSWORD':
            //return 'Invalid Password';
            swal("Oops", "Invalid Password", "error", { button: "Try Again!", });
            break;
        case 'USER_DISABLED':
            return 'User Disabled';

        default:
            return '';
    }
}

export function saveTokenInLocalStorage(tokenDetails) {
    localStorage.setItem('userDetails', JSON.stringify(tokenDetails));
}

export function runLogoutTimer(dispatch, timer, history) {
    setTimeout(() => {
        dispatch(logout(history));
    }, timer);
}

export function checkAutoLogin(dispatch, history) {

    const tokenDetailsString = localStorage.getItem('userDetails');
    const tokenDetails = JSON.parse(tokenDetailsString);
    if (tokenDetailsString) {
        dispatch(checkTokenAction(tokenDetails, history));
    } else {
        dispatch(logout(history));
        return;
    }

}