import React, { useContext, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import { validatePassword } from '../../../utility/Utility';

import { changePasswordAction } from '../../../store/actions/AuthActions';
import { globalLoadingAction } from '../../../store/actions/GlobalAction';

const ManagePassword = (props) => {

    const dispatch = useDispatch();
    const history = useHistory();

    let errorsObj = { oldPws: '', newPws: '' };
    const [errors, setErrors] = useState(errorsObj);
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [oldPws, setOldPws] = useState(false);
    const [newPws, setNewPws] = useState(false);

    useEffect(() => {

    }, []);

    function onSubmit(e) {

        e.preventDefault();

        let error = false;
        const errorObj = { ...errorsObj };

        if (oldPassword === '') {
            errorObj.oldPws = 'Old Password is Required';
            error = true;
        }
        if (newPassword === '') {
            errorObj.newPws = 'New Password is Required';
            error = true;
        } else if (validatePassword(newPassword).result == false) {
            errorObj.newPws = validatePassword(newPassword).error;
            error = true;
        }

        setErrors(errorObj);
        if (error) {
            return;
        }

        dispatch(globalLoadingAction(true));
        dispatch(changePasswordAction(oldPassword, newPassword, props.history));
    }

    const onGoBackHandler = () => {
        history.goBack();
    }

    return (
        <>
            <div className="row">
                <div className="breadcrumb-container">
                    <ul>
                        <li>
                            <Link to={"#"}>Account</Link>
                        </li>
                        <li><i className="fa fa-angle-right"></i></li>
                        <li>
                            <Link to={"#"}>Manage Password</Link>
                        </li>
                    </ul>
                </div>
                <div className="title-body-container">
                    <div className="title-body mb-3">
                        <h3>Manage Password</h3>
                        <p>Manage Company/Organization Information such as name, type, location address and contacts.</p>
                    </div>
                </div>
                <div className="col-xl-6 col-xxl-12">
                    <div className="row">

                        <div className="col-xl-12">
                            <div className="card pt-5 pb-5">
                                <div className="row">
                                    <div className="col-xl-3"></div>
                                    <div className="col-xl-6">

                                        <div className="card-body">
                                            <div className="basic-form">

                                                <form onSubmit={onSubmit}>

                                                    <div className="form-group form-group-modal-custom mb-3">
                                                        <label className="mb-1">
                                                            Old Password
                                                        </label>
                                                        <div className="form-group-pws">
                                                            <input type={oldPws ? 'text' : 'password'} className="form-control" value={oldPassword} onChange={(e) => setOldPassword(e.target.value)} />
                                                            <div className="pws-icon-view" onClick={() => setOldPws(!oldPws)}>
                                                                {oldPws ?
                                                                    <i class="bi bi-eye"></i>
                                                                    :
                                                                    <i class="bi bi-eye-slash"></i>
                                                                }
                                                            </div>
                                                        </div>
                                                        {errors.oldPws && <div className="text-danger fs-12">{errors.oldPws}</div>}
                                                    </div>

                                                    <div className="form-group form-group-modal-custom mb-3">
                                                        <label className="mb-1">
                                                            New Password
                                                        </label>
                                                        <div className="form-group-pws">
                                                            <input type={newPws ? 'text' : 'password'} className="form-control" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
                                                            <div className="pws-icon-view" onClick={() => setNewPws(!newPws)}>
                                                                {newPws ?
                                                                    <i class="bi bi-eye"></i>
                                                                    :
                                                                    <i class="bi bi-eye-slash"></i>
                                                                }
                                                            </div>
                                                        </div>
                                                        {errors.newPws && <div className="text-danger fs-12">{errors.newPws}</div>}
                                                    </div>

                                                    <div className="modal-button-group mt-5">
                                                        <button onClick={onGoBackHandler} type="button" className="btn form-cencel-btn">
                                                            Cancel
                                                        </button>
                                                        <button type="submit" className="btn btn-primary role-form-btn" disabled={props.showLoading}>
                                                            Save
                                                        </button>
                                                    </div>

                                                </form>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="col-xl-3"></div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>


            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        showLoading: state.role.showLoading,
        permissionsList: state.role.permissionsList,
        countryList: state.country.countryList,
    };
};
export default connect(mapStateToProps)(ManagePassword);